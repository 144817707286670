import React from 'react';
import a44 from '../../assets/images/a44.png'; // Import the image for the section
import './YearlyReportSection.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const YearlyReportSection = () => {
  const navigate = useNavigate(); // Hook to navigate between pages

  const handleRegisterNowClick = () => {
    navigate('/enquire-form');
  };
  return (
    <div className="yearlyreportsection-section">
      <div className="yearlyreportsection-content-wrapper">
        <div className="yearlyreportsection-description">
          <h2 className="yearlyreportsection-heading">Keep Your Business Compliant & Going
          </h2>
          <p className="yearlyreportsection-subheading">Keep the business running while we handle your compliance like a pro.
          </p>
          <button onClick={handleRegisterNowClick} className="yearlyreportsection-start-button">File My Annual Report Now</button>
          <p className="yearlyreportsection-footer-text">
          Annual Reports starting from<strong className='muslish-numbers'> $100.</strong> 
          </p>
        </div>
        <img src={a44} alt="Yearly Report Illustration" className="yearlyreportsection-image" />
      </div>
    </div>
  );
};

export default YearlyReportSection;
