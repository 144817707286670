import React, { useState } from 'react';
import './faqsPage.css';
import questionIcon from '../../assets/images/question.png'; // Import the question.png image

const frequentlyAskedQuestions = [
  {
    query: "Can an LLC have multiple members?",
    response: "Yes, an LLC can have multiple members. In business terms, this is called a multi-member LLC, where the ownership of the company is divided among multiple individuals or entities. It allows flexibility in management and profit-sharing arrangements, making it a versatile option for various business ventures."
},
{
  query: "What is an EIN?",
  response: "The EIN, or Employer Identification Number, is a unique number provided by the IRS for tax identification purposes. It is primarily required to hire employees, create a business bank account, and file federal tax returns."
},
{
  query: "How do I dissolve an LLC if I no longer need it?",
  response: "Dissolving an LLC involves filing an Article of Dissolution with your state and settling any outstanding debts, obligations, and taxes. After liquidation, the LLC must distribute remaining assets among members and notify creditors. Requirements may vary by state, so it is recommended to consult a legal advisor to assist with the process."
},
{
  query: "What is the difference between an LLC, corporation, and other business structures?",
  response: "An LLC, or Limited Liability Company, combines elements of both partnerships and corporations. It offers liability protection similar to a corporation, shielding owners' assets, while allowing for pass-through taxation, where profits and losses are reported on individual tax returns, avoiding double taxation. Unlike corporations, LLCs provide flexible management structures, making them appealing to small businesses and startups seeking simplicity, operational flexibility, and liability protection."
},
{
  query: "What is the difference between an LLC and a corporation?",
  response: "LLCs are often suited for entrepreneurs and small businesses due to their flexible options in management and tax structure. In contrast, a corporation has a formal structure with officers, shareholders, and directors, and is subject to more rigorous regulatory requirements, such as annual meetings and record-keeping. Corporations may face double taxation, while LLCs generally have pass-through taxation and fewer formalities, making them easier to manage."
},

{
  query: "What is a Professional Corporation?",
  response: "A professional corporation is an entity structure created for licensed professionals, such as lawyers and doctors. It provides shareholders with liability protection and operates similarly to a corporation. However, the owner must be licensed in the specific services they provide."
},
{
  query: "What are corporate bylaws, and do I need them?",
  response: "Corporate bylaws are the internal rules governing the management of a corporation, covering duties such as officer roles, board meetings, and decision-making processes. While not always legally required, having corporate bylaws supports effective governance and accountability within the corporation."
},
{
  query: "What is the process for dissolving a corporation?",
  response: "To dissolve a corporation, file Articles of Dissolution with the state, settle any debts, liquidate assets, notify creditors, and distribute remaining assets to shareholders. You must also inform tax authorities and file final tax returns. Since laws vary by state, consulting with a legal expert is recommended."
},
{
  query: "What types of documents does a registered agent receive?",
  response: "A registered agent receives legal documents, such as service of process for lawsuits, on behalf of a business. The agent ensures that these critical documents are promptly delivered to the business owner, allowing the company to respond within the required legal timeframe."
},
{
  query: "What is the difference between a professional registered agent and an individual?",
  response: "A professional registered agent is a service that handles legal documents reliably and with compliance expertise. An individual can serve as a registered agent, but they may lack the reliability and privacy a professional service provides. Professional agents are consistently available during business hours to receive documents."
},
{
  query: "Who qualifies as a beneficial owner?",
  response: "A beneficial owner is an individual who directly or indirectly owns or controls at least 25% of a company or has significant influence over its decisions. Individuals with less than 25% control may also need to be reported when filing a Beneficial Ownership Information report."
},
{
  query: "What is the deadline for filing a Beneficial Ownership Report?",
  response: "Under the Corporate Transparency Act, newly formed entities must file a Beneficial Ownership Report within 90 days of incorporation. Existing entities incorporated before January 1, 2024, have until December 31, 2024, to file their reports."
},
{
  query: "What types of entities must file a Beneficial Ownership Report?",
  response: "Entities such as Limited Liability Companies (LLCs), corporations, partnerships, limited partnerships, and other similar state-created entities must file a Beneficial Ownership Information report under the Corporate Transparency Act to disclose the individuals who control or benefit from the company."
},
{
  query: "What is the Corporate Transparency Act?",
  response: "The Corporate Transparency Act requires certain companies to report their beneficial owners to the Financial Crimes Enforcement Network (FinCEN) to prevent illegal activities such as money laundering and enhance corporate transparency."
},
{
  query: "Why is having an operating agreement essential for an LLC?",
  response: "An operating agreement outlines the decision-making process, structure, and management of an LLC. It helps protect the LLC’s limited liability status, prevent conflicts among members, and provide legal clarity in the event of disputes."
},
{
  query: "What is the difference between an LLC Operating Agreement and LLC Articles of Organization?",
  response: "An LLC Operating Agreement defines the operational aspects of an LLC, such as profit distribution and management structure. In contrast, the Articles of Organization is the legal document filed with the state to formally establish the LLC."
},
{
  query: "How often do I need to file an annual report?",
  response: "Most U.S. states require businesses to file annual reports to maintain good standing, though some states may require biennial reports. Check your specific state regulations to ensure compliance."
},
{
  query: "Is an annual report the same as a tax return?",
  response: "No, an annual report is not the same as a tax return. An annual report updates state authorities on the business's current information, while a tax return is a financial statement submitted to the IRS for tax purposes."
}


];

const FaqsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQuestions = frequentlyAskedQuestions.filter(faq =>
    faq.query.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faqs-section-wrapperr">
      <h2 className="faqs-title">Frequently Asked Question
      </h2>
      <div className="faqs-search-wrapper">
        <input
          type="text"
          className="faqs-input-field"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search FAQs..."
        />
        <img src={questionIcon} alt="Search Icon" className="faqs-image-icon" /> {/* Use the question.png image */}
      </div>

      <div className="faqs-list-container">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((faq, index) => (
            <div className="faqs-item-wrapper" key={index}>
              <h3 className="faqs-query">{faq.query}</h3>
              <p className="faqs-response">{faq.response}</p>
            </div>
          ))
        ) : (
          <p>No FAQs match your search.</p>
        )}
      </div>
    </div>
  );
};

export default FaqsPage;
