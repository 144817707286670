import React from 'react';
import a44 from '../../assets/images/a44.png'; // Import the image for the section
import './YearlyReportSectionSA.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const YearlyReportSection = () => {
  const navigate = useNavigate(); // Hook to navigate between pages

  const handleRegisterNowClick = () => {
    navigate('/business-name'); // Navigates to the Business Name page or any other page
  };

  return (
    <div className="yearlyreportsection-section">
      <div className="yearlyreportsection-content-wrapper">
        <div className="yearlyreportsection-description">
          <h2 className="yearlyreportsection-heading">DISPLAY A YEARLY REPORT</h2>
          <p className="yearlyreportsection-subheading">Log in to file quickly and accurately</p>
          <button onClick={handleRegisterNowClick} className="yearlyreportsection-start-button">START NOW</button>
          <p className="yearlyreportsection-footer-text">
            Let us submit your yearly report for an additional <strong className='yearlyreportsection-muslish-numbers'>$100</strong> in state costs.
          </p>
        </div>
        <img src={a44} alt="Yearly Report Illustration" className="yearlyreportsection-image" />
      </div>
    </div>
  );
};

export default YearlyReportSection;
