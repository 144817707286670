import React from 'react';
import './HowServiceWorks.css'; // Import the CSS file for styling

const HowServiceWorks = () => {
  return (
    <div className="howserviceworkssection">
      <h2 className="howserviceworksheadinwg">Why Do Companies Choose BusinessOmni for Registered Agents Services?</h2>
      <p className="howserviceworksdescriptionn">
      Choosing a trustworthy partner to handle your legal documents and government notification keeps you compliant and helps you channel your time in the right direction. We are here at your service and limiting the legal burden that you have to carry all the way. Keep your focus on running your business ideally, and we will act as a protector of your compliance.
      </p>
      <div className="howserviceworkscontent">
        <div className="howserviceworksboxq">
          <h2>Privacy Protection
          </h2>
          <p>Stay protected from any legal notice that may harm your reputation. Most companies use registered agents to feel secure, as their addresses are not publicly available. </p>
        </div>
        
        <div className="howserviceworksboxq">
          <h2>Flexibility and Reliability </h2>
          <p>Have peace of mind and work from anywhere using our address as your business front. Create your schedule and let our registered agent receive any physical notices.</p>
        </div>
        
        <div className="howserviceworksboxq">
          <h2>Timely Alerts</h2>
          <p>Stay informed with our instant alerts as soon as we receive any official mail for you. Plus, get timely reminders for your annual report deadlines.</p>
        </div>
      </div>
    </div>
  );
};

export default HowServiceWorks;
