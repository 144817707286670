import React from 'react';
import './ProgressBar.css';

const ProgressBubbles = ({ step }) => {
  // Each step represents 20% (1 bubble)
  const steps = [""];

  return (
    <div className="progress-bubbles-container">
      {steps.map((percent, index) => (
        <React.Fragment key={index}>
          {/* Each bubble */}
          <div className={`bubble ${step >= percent ? 'active' : ''}`}>
            <span>{percent}</span>

            {/* Water effect inside the active bubble */}
            {step >= percent && (
              <div className="water-effect">
                <div className="water" style={{ height: `${step >= percent ? 100 : 0}%` }}>
                  <div className="wave wave1"></div>
                  <div className="wave wave2"></div>
                </div>
              </div>
            )}
          </div>

          {/* Connector line between bubbles */}
          {index < steps.length - 1 && (
            <div className={`connector ${step > percent ? 'active' : ''}`}></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressBubbles;
