import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./PaymentFormPage.css";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
console.log("Stripe Public Key:", process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = ({ onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePayment = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);

    if (!stripe || !elements) {
      setErrorMessage("Stripe has not loaded yet. Please try again.");
      setLoading(false);
      return;
    }

    try {
      // Step 1: Create PaymentIntent
      const response = await fetch(
        "https://api.stripe.com/v1/payment_intents",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            amount: "5000",
            currency: "usd",
            "payment_method_types[]": "card",
          }),
        }
      );

      if (!response.ok) {
        const errorDetails = await response.json();
        console.error("Error creating PaymentIntent:", errorDetails);
        setErrorMessage(
          `Failed to create PaymentIntent: ${errorDetails.error.message}`
        );
        setLoading(false);
        return;
      }

      const { client_secret: clientSecret } = await response.json();

      // Step 2: Confirm the Payment
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (paymentResult.error) {
        setErrorMessage("Payment failed: " + paymentResult.error.message);
      } else if (paymentResult.paymentIntent.status === "succeeded") {
        setIsPaymentComplete(true);
        alert("Payment successful!");
        if (onPaymentSuccess) onPaymentSuccess();
      }
    } catch (error) {
      console.error("Error during payment:", error);
      setErrorMessage("An unexpected error occurred: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePrevious = () => {
    window.history.back();
  };

  const handleProceed = () => {
    alert("Payment Complete! Redirecting to next step.");
    // Redirect or handle next-step actions
  };

  return (
    <div className="payment-container">
      <div className="payment-form">
        <h1>Payment Form</h1>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handlePayment}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          <button type="submit" disabled={!stripe || loading}>
            {loading ? "Processing..." : "Pay Now"}
          </button>
        </form>

        {/* Navigation Buttons */}
        <div className="navigation-buttons">
          <button className="previous-step" onClick={handlePrevious}>
            ← Previous
          </button>

          {isPaymentComplete ? (
            <button className="next-step" onClick={handleProceed}>
              Next →
            </button>
          ) : (
            <button className="next-step" disabled>
              Next →
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const PaymentPage = () => {
  const navigate = useNavigate();
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        onPaymentSuccess={() => {
          navigate("/thankyou");
        }}
      />
    </Elements>
  );
};

export default PaymentPage;
