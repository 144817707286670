import React, { useState } from 'react';
import './FAQComponent.css'; // Updated CSS file reference
import questionIcon from '../../assets/images/question.png'; // Import the question.png image

const frequentlyAskedQuestions = [
  {
    query: "WHAT IS A REGISTERED AGENT?",
    response: "A registered agent is a service or a person responsible for receiving government notices and legal documents on behalf of the business. Moreover, the agent ensures timely communication with the state.",
  },
  {
    query: "WHY DO I NEED A REGISTERED AGENT?",
    response: "A registered agent is responsible for keeping you compliant with the state requirements to avoid any kind of penalties. The duties include receiving government documents, legal notifications, and notifying you regarding new laws. ",
  },
  {
    query: "CAN I BE MY REGISTERED AGENT?",
    response: "Yes, you can be your registered agent, but you must maintain a physical address in the state and be available during business hours in the state where your business is registered. However, the best and standard practice is hiring a maestro registered agent so that he keeps your business compliant at all costs. ",
  },
  {
    query: "DO REGISTERED AGENTS NEED TO BE LOCATED IN THE SAME STATE AS MY BUSINESS?",
    response: "Yes, it is mandatory that registered agents have a physical address in the state where the business is registered because the physical address will be used to receive official notices and documents.",
  },
];

const FAQComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQuestions = frequentlyAskedQuestions.filter(faq =>
    faq.query.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faq-section-wrapper">
      <h2 className="faq-title">Frequently Asked Questions About Registered Agents
      </h2>
      <div className="faq-search-wrapper">
        <input
          type="text"
          className="faq-input-field"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search FAQs..."
        />
        <img src={questionIcon} alt="Search Icon" className="faq-image-icon" /> {/* Use the question.png image */}
      </div>

      <div className="faq-list-container">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((faq, index) => (
            <div className="faq-item-wrapper" key={index}>
              <h3 className="faq-query">{faq.query}</h3>
              <p className="faq-response">{faq.response}</p>
            </div>
          ))
        ) : (
          <p>No FAQs match your search.</p>
        )}
      </div>
    </div>
  );
};

export default FAQComponent;
