import React from 'react';
import { useNavigate } from 'react-router-dom'; // Make sure this is imported
import './MainSection.css';
import illustration from '../../assets/images/illustration.png'; 
import logo2 from '../../assets/images/logo2.png'; 

const MainSection = () => {
  const navigate = useNavigate(); // Hook to navigate between pages

  const handleLoginClick = () => {
    navigate('/business-name'); // Navigates to the Business Name page
  };

  return (
    <div className="main-section">
      <div className="main-inner-outline">
        <div className="main-content-wrapper">
          <img src={logo2} alt="Omni logo" className="logo2-above-heading" />
          <h1>FROM IDEAS TO INFINITY</h1>
          <p class="hero-description">
          Start and enlighten your professional dreams with ease! Create an LLC or start a corporation through our all-in-one platform with the best rates, quick turnaround times, and exceptional customer care service, ensuring a smooth and hassle-free business formation process from start to finish. With Business Omni, set up a business proficiently. We simplify every step so that entrepreneurs focus on successful business growth. We glorify ourselves by delivering out-of-the-box custom company formation services to meet your unique needs. 
          </p>
          <button onClick={handleLoginClick} className="main-start-button">
          LET'S BUILD SOMETHING ICONIC NOW!
          </button>
          <div className="social-connect">
            <p>Let’s Connect!</p>
            <div className="social-icons">
              <a href="https://www.linkedin.com">
                <img src="https://cdn3.iconfinder.com/data/icons/picons-social/57/11-linkedin-1024.png" alt="LinkedIn" />
              </a>
              <a href="https://www.facebook.com">
                <img src="https://cdn3.iconfinder.com/data/icons/wpzoom-developer-icon-set/500/01-1024.png" alt="Facebook" />
              </a>
              <a href="https://www.instagram.com">
                <img src="https://cdn3.iconfinder.com/data/icons/font-awesome-brands/512/instagram-square-512.png" alt="Instagram" />
              </a>
              <a href="https://www.whatsapp.com">
                <img src="https://cdn0.iconfinder.com/data/icons/font-awesome-brands-vol-2/512/whatsapp-square-512.png" alt="WhatsApp" />
              </a>
            </div>
          </div>
        </div>
        <img src={illustration} alt="Business Omni Illustration" className="illustration-image" />
      </div>
    </div>
  );
};

export default MainSection;
