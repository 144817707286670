import React from 'react';

import './HowServiceWorks.css'; // Import the CSS file for styling

const HowServiceWorks = () => {
  return (
    <div className="how-service-section">
      <h2 className="how-service-heading">HOW OUR YEARLY REPORT SERVICE OPERATES</h2>
      <p className="how-service-description">
        We make sure everything is completed accurately and on time by staying on top of your state's regulations and deadlines and filing your first, biennial, or annual reports with the state.
      </p>
      
      <div className="how-service-content">
        <div className="how-service-box">
        <h2>Heading One</h2>
        <p>Include our service for filing yearly reports for an established company in your dashboard.</p>
        </div>
        
        <div className="how-service-box">
          <h2>Heading Two</h2>
          <p>Get updates from us as soon as the report is filed, and we'll let you know when it's finished.</p>
        </div>
        
        <div className="how-service-box">
        <h2>Heading Three</h2>
        <p>Be aware that in certain states, you could need to pay an extra filing cost.</p>
        </div>
      </div>
    </div>
  );
};

export default HowServiceWorks;
