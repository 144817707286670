import React from 'react';
import './HowServiceWorks.css'; // Import the CSS file for styling

const HowServiceWorks = () => {
  return (
    <div className="howserviceworkssection">
    <h2 className="howserviceworksheadingg">Why Choose BusinessOmni for BOI Reporting?</h2>
    <p className="m-5 text-center cust-margin" style={{fontSize:"20px"}}>BusinessOmni has been in the market for a long time and understands all the legal compliances well. We guarantee smooth and seamless reporting and make sure that you stay stress-free. 
    Filing a BOI report may seem complex, but that’s where we simplify the process and empower you to flip your entrepreneurial dream into reality.</p>
    <div className="howserviceworkscontent">
      <div className="howserviceworksboxx">
        <h2>Expert Guidance</h2>
        <p>BusinessOmni’s experienced team navigates and assesses the complexities of a Beneficial Ownership Information report. We ensure that a unique business like yours is handled by providing tailored solutions and timely filing. </p>
      </div>
      
      <div className="howserviceworksboxx">
        <h2>Compliance Assurance
        </h2>
        <p>Partnering with BusinessOmni helps you rest while our team of professionals takes care of your BOI reporting. Your beneficial ownership filing will be done precisely, minimizing any legal complications or compliance issues. </p>
      </div>
      
      <div className="howserviceworksboxx">
        <h2>One Stop Solution</h2>
        <p>We handle all your stress by simplifying your BOI for LLC. This way, you can focus on what you do best, which is growing your business to a whole new level.</p>
      </div>
    </div>
  </div>
  );
};

export default HowServiceWorks;
