import React from 'react';
import './InstructionStepsSection.css'; // Ensure you have the relevant CSS
import GoalDeclarationSection from './GoalDeclarationSection'; // Import Goal Declaration section
import StatPurpose from './StatPurpose'; // Import Stat Purpose section
import StoryBPO from './StoryBPO'; // Import Story BPO section
// import FAQSection from './FAQSection';
import FAQSection from '../Hompage/FAQSection';
import icon1 from '../../assets/images/icon1.png'; // Replace with actual image paths
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import icon4 from '../../assets/images/icon4.png';

const InstructionStepsSection = () => {
  return (
    // <div className="instruction-steps-section">
    <div className="">
      {/* <div className="ins-content-wrapper">
        <div className="text-content">
          <h2 className="ins-heading">INSTRUCTIONS IN STEPS</h2>
          <p className="ins-description">
            WE ARE AWARE OF THE DAUNTING NATURE OF BEGINNING A BUSINESS. 
            YOU'RE NOT ON YOUR OWN WHEN DOING THIS! WE OFFER VITAL KNOWLEDGE 
            AND STRATEGIES IN OUR EDUCATIONAL RESOURCES. WE OFFER EXPERT-LED 
            WEBINARS TO WALK YOU THROUGH THE TOP FIVE BEHAVIORS, SUCH AS 
            HANDLING FUNDS AND STAYING OUT OF TROUBLE WITH THE LAW, THAT YOU 
            NEED TO ESTABLISH A SUCCESSFUL BUSINESS. BEING BUSINESS OWNERS 
            OURSELVES, WE ARE HERE TO ASSIST YOU IN REALIZING YOUR ASPIRATIONS.
          </p>
        </div>
      </div> */}

      {/* Adding the additional sections below the Instruction Steps */}
      {/* <GoalDeclarationSection />
      <StatPurpose />
      <StoryBPO /> */}

      <div className="what-sets-us-apart-section" style={{backgroundColor:"#fff"}}>
      <h2>Simplifying Business Formation & Compliance</h2>
      <p className="description">
      Business Omni was created with a vision to offer tailored business formation services. We are a team of professionals who understand all the standard operating procedures for business formation and operations. We will be a beacon of light for those suffering from forming their LLC, corporation, annual report, beneficial ownership information, or registered agent. We always strive to take a step ahead to guide you throughout the process. Our key point is keeping our clients in the loop during the whole process. Let’s join hands together and create something iconic together!
      </p>
      <div className="features">
        <div className="feature-box">
          <h3>What’s Omni</h3>
          <p className="mb-0">Business Omni is a one-stop solution for seamless business formation and compliance services in the United States. Whether you are forming an LLC or corporation or need any assistance with beneficial ownership information reports (BOI), registered agent services, or annual reports, we are here to make it serene. With transparent pricing, quick turnaround time, and expert guidance, we minimize the hassle of paperwork so that you can continue to focus on what matters the most—business growth. Our platform ensures that your business stays compliant, protected, and organized.</p>
        </div>
        <div className="feature-box">
          {/* <img src={icon2} alt="Expert Help & Ongoing Support" className="feature-icon"/> */}
          <h3>How Omni Works</h3>
          <p className="mb-0"> Business Omni operates through an intuitive platform designed to streamline the process of forming and maintaining a business. Our team of experts assists clients in navigating the complex details of legal filings, compliance, and reporting requirements. From registering your business in any state to managing all the necessary documents, we handle all the paperwork and deadlines. Our team ensures real-time updates are provided to the client so that they stay in the loop. </p>
        </div>
        <div className="feature-box">
          <h3>Why Choose Omni </h3>
          <p className="mb-0"> Business Omni was founded with one goal: to eliminate the frustrations and complexities that entrepreneurs and business owners face during compliance and LLC or Corporation formation. Our operations are lucid; we provide upfront pricing with no hidden fees so that you always know what to expect. Business Omni is where we provide creative solutions accurately. We are offering reliable, professional services that help you, our clients, to leave their legal intricacies on us, and we do it happily.</p>
        </div>
      </div>
    </div>


    <div className="what-sets-us-apart-section">
      <h2>WHAT SETS US APART</h2>
      <p className="description">
      At Business Omni, we pride ourselves on delivering exceptional company formation services tailored to your needs. Our expertise ensures a seamless registration process, guiding you through every step clearly and professionally. We prioritize personalized support, expert advice, and timely assistance to smooth your business journey. With a commitment to transparency and integrity, we provide clear information without hidden fees. Our dedicated team stays updated on regulations, ensuring our client’s compliance and peace of mind. Choose us for reliable service, unparalleled knowledge, and a passion for helping you succeed in your entrepreneurial endeavors.
      </p>
      <div className="features">
        <div className="feature-box">
          <img src={icon1} alt="Quick Business Setup" className="feature-icon"/>
          <h3>Hassle-Free Service</h3>
          <p>We take your stress away with business formation and compliance, managing all your paperwork and filings. We handle all the complex legal details so you can focus on growing your business. We understand that our clients have a strategy on which they are going to build their platform. Our experts will take all your pain, so you can work on your brand building.</p>
          {/* <button className="learn-more-button mt-4">Learn More</button> */}
        </div>
        <div className="feature-box">
          <img src={icon2} alt="Expert Help & Ongoing Support" className="feature-icon"/>
          <h3>Efficient and Reliable Filing
          </h3>
          <p>We ensure timely and accurate filings for LLCs, Corporations, annual reports, BOIs, and more. Business Omni strives to meet all the deadlines, keep your business compliant, and avoid unwanted interruptions and penalties. Time is the biggest challenge during this process, the longer time causes delayed operations. Our team of experts will quickly file all the necessary documents accurately so that you can get going. </p>
          {/* <button className="learn-more-button">Learn More</button> */}
        </div>
        <div className="feature-box">
          <img src={icon3} alt="Complete Services Packages" className="feature-icon"/>
          <h3>Dedicated Customer Support
          </h3>
          <p>Business Omni prides itself on providing the finest customer support tailored to our client business needs. Our dedicated team of professionals is available to guide you through every step of the way. We offer clear, professional, and prompt assistance to our clients. Ensuring the resolution of your question and seamless communication is our top priority. This gives the client a sigh of relief, as this process is complex and hectic already. 
          </p>
          {/* <button className="learn-more-button">Learn More</button> */}
        </div>
        <div className="feature-box">
          <img src={icon4} alt="Clear Prices, No Surprices" className="feature-icon"/>
          <h3>Expert Guidance
          </h3>
          <p>With Business Omni, you will get expert advice at every step. We do everything from business formation to compliance reporting with flying colors. We make sure that your business vertical is legally sound and aligns with your professional goals. That can only be achieved by having an expert on your side. We will provide you with expert guidance so that you do the paperwork ideally. 
          </p>
          {/* <button className="learn-more-button mt-5">Learn More</button> */}
        </div>
        <div className="feature-box">
          <img src={icon3} alt="Complete Services Packages" className="feature-icon"/>
          <h3>Clear Price, No Surprise

          </h3>
          <p>Business Omni is known for offering transparent pricing with no hidden fees. Our direct pricing model means you’ll witness what you’re paying for, offering you peace of mind from start to finish. We don’t believe in overcharging our clients by giving them unnecessary surprises. Our mission is to empower entrepreneurs to join us and create an iconic business.  
          </p>
          {/* <button className="learn-more-button mt-5">Learn More</button> */}
        </div>
        <div className="feature-box">
          <img src={icon3} alt="Complete Services Packages" className="feature-icon"/>
          <h3>Timely Updates

          </h3>
          <p>We always strive to provide the best services possible. Moreover, we understand that entrepreneurs and business owners are super busy managing their operations, this is where we step in and let go of their business formation burden. We always inform our clients about the process, how long it will take, and when to do what. Most of our competitors left the client out in the dry and did not offer timely updates. But worry no more, we have put an end to that so you always feel connected with what’s going on. 
 
          </p>
          {/* <button className="learn-more-button">Learn More</button> */}
        </div>
      </div>
    </div>

    <FAQSection/>
    </div>    
  );
};

export default InstructionStepsSection;
