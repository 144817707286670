import React from 'react';
import A11 from '../../assets/images/resolutions.png'; // Import icons
import A33 from '../../assets/images/Legal Compliance.png';
import A22 from '../../assets/images/transparency.png';
import './UniquePeacefulMindSection.css'; // Updated CSS file with unique names

const UniquePeacefulMindSection = () => {
  return (
    <div className="uniquepeacefulsection">
      <h2 className="uniquepeacefulheading">Benefits of Annual Report
      </h2>
      <br></br>
      <br></br>
      <div className="uniquepeacefulcontent">
        <div className="uniquepeacefulbox">
          <img src={A11} alt="Asset Protection Icon" className="uniquepeacefulicon" />
          <h3>Business Continuity
          </h3>
          <p>
          A regular annual report for LLC, Corporation, and other legal entities shows that your business is active and compliant, helping you eliminate the risk of administrative dissolution or any other legal consequences. Legal consequences could also disrupt business operations, so filing a complete annual report eliminates these risks.
          </p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A33} alt="Tax Benefits Icon" className="uniquepeacefulicon rightalignedicon" />
          <h3>Legal Compliance

          </h3>
          <p>
          The annual report states that your business is in good standing with the state authorities. Compliance shows that the company is serious about its operations and future growth. Get on board with BusinessOmni so you do not miss any deadlines and can easily avoid unwanted fines or penalties. 
          </p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A22} alt="Credibility Icon" className="uniquepeacefulicon" />
          <h3>Transparency

          </h3>
          <p>
          File annual report for LLC, corporations, and other legal entities and provide information about your business. This promotes transparency for stakeholders and builds trust with clients, investors, and partners. Please do what you are best at, and let us manage your paperwork authentically and efficiently. Some states require annual reports, but nine require biannual reports. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default UniquePeacefulMindSection;
