import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import "./BusinessAddressPage.css";
import icon from "../../assets/images/icon5.png"; // Correct path to icon5.png
import ProgressBar from "./ProgressBar"; // Import the ProgressBar component

const BusinessAddressPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [animate, setAnimation] = useState(false); //to track the click on submit button

  const { state } = location;
  const selectedState = state?.selectedState || "CA"; // Fallback to California if not passed
  const { businessName } = location.state || {};
  const { businessActivity } = location.state || {};
  const { fullName } = location.state || {};
  const { phone } = location.state || {};
  const { email } = location.state || {};
  const { businessStructure } = location.state || {};

  console.log(":::::: busniess Address Page ::::::::", businessStructure);

  // Modal state to control visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Form state for address fields
  const [businessAddress, setBusinessAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateField, setStateField] = useState("");
  const [zipCode, setZipCode] = useState("");

  // State to handle the dropdown visibility for the state input field
  const [stateSuggestions, setStateSuggestions] = useState([]);

  // Initialize 'submittedAddress' before use
  const [submittedAddress, setSubmittedAddress] = useState(null);
  const [isAddressEntered, setIsEntered] = useState(false);

  // List of US states
  const usStates = [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" },
  ];

  const llcstateFees = {
    Alaska: { fee: 250, name: "Alaska" },
    Alabama: { fee: 236, name: "Alabama" },
    Arkansas: { fee: 45, name: "Arkansas" },
    Arizona: { fee: 85, name: "Arizona" },
    California: { fee: 75, name: "California" },
    Colorado: { fee: 50, name: "Colorado" },
    Connecticut: { fee: 120, name: "Connecticut" },
    DistrictOfColumbia: { fee: 99, name: "District of Columbia" },
    Delaware: { fee: 140, name: "Delaware" },
    Florida: { fee: 155, name: "Florida" },
    Georgia: { fee: 100, name: "Georgia" },
    Hawaii: { fee: 51, name: "Hawaii" },
    Iowa: { fee: 50, name: "Iowa" },
    Idaho: { fee: 100, name: "Idaho" },
    Illinois: { fee: 154, name: "Illinois" },
    Indiana: { fee: 97, name: "Indiana" },
    Kansas: { fee: 166, name: "Kansas" },
    Kentucky: { fee: 40, name: "Kentucky" },
    Louisiana: { fee: 105, name: "Louisiana" },
    Massachusetts: { fee: 520, name: "Massachusetts" },
    Maryland: { fee: 156, name: "Maryland" },
    Maine: { fee: 175, name: "Maine" },
    Michigan: { fee: 50, name: "Michigan" },
    Minnesota: { fee: 155, name: "Minnesota" },
    Missouri: { fee: 50, name: "Missouri" },
    Mississippi: { fee: 53, name: "Mississippi" },
    Montana: { fee: 35, name: "Montana" },
    NorthCarolina: { fee: 128, name: "North Carolina" },
    NorthDakota: { fee: 135, name: "North Dakota" },
    Nebraska: { fee: 105, name: "Nebraska" },
    NewHampshire: { fee: 102, name: "New Hampshire" },
    NewJersey: { fee: 129, name: "New Jersey" },
    NewMexico: { fee: 50, name: "New Mexico" },
    Nevada: { fee: 436, name: "Nevada" },
    NewYork: { fee: 210, name: "New York" },
    Ohio: { fee: 99, name: "Ohio" },
    Oklahoma: { fee: 104, name: "Oklahoma" },
    Oregon: { fee: 100, name: "Oregon" },
    Pennsylvania: { fee: 125, name: "Pennsylvania" },
    RhodeIsland: { fee: 156, name: "Rhode Island" },
    SouthCarolina: { fee: 131, name: "South Carolina" },
    SouthDakota: { fee: 150, name: "South Dakota" },
    Tennessee: { fee: 307, name: "Tennessee" },
    Texas: { fee: 308, name: "Texas" },
    Utah: { fee: 76, name: "Utah" },
    Virginia: { fee: 100, name: "Virginia" },
    Vermont: { fee: 125, name: "Vermont" },
    Washington: { fee: 230, name: "Washington" },
    Wisconsin: { fee: 130, name: "Wisconsin" },
    WestVirginia: { fee: 130, name: "West Virginia" },
    Wyoming: { fee: 104, name: "Wyoming" },
  };

  const CorpstateFees = {
    Alaska: { fee: 250, name: "Alaska" },
    Alabama: { fee: 236, name: "Alabama" },
    Arkansas: { fee: 45, name: "Arkansas" },
    Arizona: { fee: 95, name: "Arizona" },
    California: { fee: 105, name: "California" },
    Colorado: { fee: 50, name: "Colorado" },
    Connecticut: { fee: 250, name: "Connecticut" },
    DistrictOfColumbia: { fee: 270, name: "District of Columbia" },
    Delaware: { fee: 139, name: "Delaware" },
    Florida: { fee: 79, name: "Florida" },
    Georgia: { fee: 140, name: "Georgia" },
    Hawaii: { fee: 50, name: "Hawaii" },
    Iowa: { fee: 50, name: "Iowa" },
    Idaho: { fee: 100, name: "Idaho" },
    Illinois: { fee: 281, name: "Illinois" },
    Indiana: { fee: 97, name: "Indiana" },
    Kansas: { fee: 90, name: "Kansas" },
    Kentucky: { fee: 50, name: "Kentucky" },
    Louisiana: { fee: 75, name: "Louisiana" },
    Massachusetts: { fee: 265, name: "Massachusetts" },
    Maryland: { fee: 217, name: "Maryland" },
    Maine: { fee: 145, name: "Maine" },
    Michigan: { fee: 60, name: "Michigan" },
    Minnesota: { fee: 180, name: "Minnesota" },
    Missouri: { fee: 58, name: "Missouri" },
    Mississippi: { fee: 50, name: "Mississippi" },
    Montana: { fee: 70, name: "Montana" },
    NorthCarolina: { fee: 125, name: "North Carolina" },
    NorthDakota: { fee: 100, name: "North Dakota" },
    Nebraska: { fee: 65, name: "Nebraska" },
    NewHampshire: { fee: 105, name: "New Hampshire" },
    NewJersey: { fee: 129, name: "New Jersey" },
    NewMexico: { fee: 100, name: "New Mexico" },
    Nevada: { fee: 744, name: "Nevada" },
    NewYork: { fee: 135, name: "New York" },
    Ohio: { fee: 100, name: "Ohio" },
    Oklahoma: { fee: 104, name: "Oklahoma" },
    Oregon: { fee: 100, name: "Oregon" },
    Pennsylvania: { fee: 125, name: "Pennsylvania" },
    RhodeIsland: { fee: 238, name: "Rhode Island" },
    SouthCarolina: { fee: 535, name: "South Carolina" },
    SouthDakota: { fee: 150, name: "South Dakota" },
    Tennessee: { fee: 100, name: "Tennessee" },
    Texas: { fee: 308, name: "Texas" },
    Utah: { fee: 76, name: "Utah" },
    Virginia: { fee: 75, name: "Virginia" },
    Vermont: { fee: 75, name: "Vermont" },
    Washington: { fee: 230, name: "Washington" },
    Wisconsin: { fee: 100, name: "Wisconsin" },
    WestVirginia: { fee: 65, name: "West Virginia" },
    Wyoming: { fee: 100, name: "Wyoming" },
  };

  const selectedStateInfo =
    businessStructure === "LLC"
      ? llcstateFees[selectedState]
      : CorpstateFees[selectedState];

  // Function to handle input change and filter the state suggestions
  const handleStateInputChange = (e) => {
    const inputValue = e.target.value;
    setStateField(inputValue);

    if (inputValue) {
      const filteredStates = usStates.filter((state) =>
        state.name.toLowerCase().startsWith(inputValue.toLowerCase())
      );
      setStateSuggestions(filteredStates);
    } else {
      setStateSuggestions([]);
    }
  };

  const handleStateSelect = (stateName) => {
    setStateField(stateName);
    setStateSuggestions([]);
  };

  const handlePrevious = () => {
    navigate("/order-summary"); // Navigate to the previous page (Order Summary)
  };

  const handleSubmit = () => {
    if (!businessAddress || !city || !stateField || !zipCode) {
      alert("Please fill out all fields");

      return;
    }

    // Store the submitted address to show on the page
    setSubmittedAddress({
      businessAddress,
      city,
      stateField,
      zipCode,
    });
    setAnimation(true);
    if (businessAddress.trim() !== "") {
      setIsEntered(true);
    }

    // Close the modal
    // setIsModalOpen(false);
  };
  const handleNext = () => {
    if (isModalOpen) {
      setIsModalOpen(true); // Open modal only if the condition is met
    } else {
      // Navigate to the next page if the address has been submitted
      navigate("/ownership-page", {
        state: {
          fullName,
          phone,
          email,
          businessName,
          selectedState,
          businessActivity,
          businessAddress,
          businessStructure,
        },
      }); // Replace this with your actual next step route
    }
  };

  return (
    <div className="order-summary-container">
      {/* Progress Bar at 40% */}
      <ProgressBar step={40} />

      <div className="business-heading-wrapper">
        {/* Icon next to the heading */}
        <img src={icon} alt="Icon" className="heading-icon" />
        <h1 className="business-heading">Business Address.</h1>
      </div>

      <div className="summary-activity-wrapper">
        {/* Order Summary */}
        <div class="order-summary">
          <h2>Order Summary</h2>
          <div class="item">
            <span>{businessStructure} Service Fee</span>
            <span></span>
          </div>
          <div class="item">
            <span>Electronic Delivery</span>
            <span class="free">Free</span>
          </div>
          {/* <div class="item">
                  <span>Essentials Package</span>
                  <span className='amulish-numbers'></span>
              </div> */}
          <br />
          <div class="item">
            <span>Government Filing Fee</span>
            <span></span>
          </div>
          <div class="item">
            <span>{selectedState}</span>
            <span></span>
          </div>
          <div class="item">
            <span>Government Fee</span>
            <span className="amulish-numbers">
              ${selectedStateInfo?.fee || 0}
            </span>
          </div>
          <div class="note">Fees collected by the State of {selectedState}</div>
          <div class="total">
            <div>
              Total Order:{" "}
              <span class="amount amulish-numbers">
                ${selectedStateInfo?.fee || 0}
              </span>
            </div>
            <div class="savings amulish-numbers">(You Saved $0)</div>
          </div>
        </div>

        {/* Line Separator */}
        <div className="vertical-line"></div>

        {/* Business Address Section */}
        <div className="business-address-section">
          <h3 className="business-address-heading">My Own Address</h3>
          {/* <p className="business-address-description">
            {selectedState} allows using an out-of-state address for company creation.<br />
            Any address provided to the state will be listed publicly.
          </p> */}
          {submittedAddress ? (
            <div className="submitted-address">
              <p>{submittedAddress.businessAddress}</p>
              <p className="amulish-numbers">
                {submittedAddress.city}, {submittedAddress.stateField},{" "}
                {submittedAddress.zipCode}
              </p>{" "}
              {/* Mulish font for numbers */}
            </div>
          ) : (
            <button
              className="business-address-select-button"
              onClick={() => setIsModalOpen(true)}
            >
              SELECT
            </button>
          )}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="anavigation-buttons">
        <button className="dprevious-step" onClick={handlePrevious}>
          ← Previous Step
        </button>
        {isAddressEntered ? (
          <button className="Anext-button" onClick={handleNext}>
            Next
          </button>
        ) : (
          <button onClick={handleNext} className="skip-step">
            Skip this Step →
          </button>
        )}
      </div>

      {/* Modal for address form */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-container">
            {" "}
            {/* New White Container with Rounded Borders */}
            <h2 className="modal-heading">Enter Business Address</h2>
            <div className="modal-form">
              <div className="modal-field">
                <label>Business Address</label>
                <input
                  type="text"
                  value={businessAddress}
                  onChange={(e) => setBusinessAddress(e.target.value)}
                  placeholder="Enter your business address"
                />
              </div>

              <div className="modal-field">
                <label>City</label>
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Enter city"
                  className="amulish-numbers" // Mulish font for form input numbers
                />
              </div>

              <div className="modal-inline">
                <div className="modal-field">
                  <label>State</label>
                  <input
                    type="text"
                    value={stateField}
                    onChange={handleStateInputChange}
                    placeholder="Enter state"
                    className="amulish-numbers" // Mulish font for form input numbers
                  />
                  {stateSuggestions.length > 0 && (
                    <ul className="state-suggestions">
                      {stateSuggestions.map((state) => (
                        <li
                          key={state.code}
                          onClick={() => handleStateSelect(state.name)}
                        >
                          {state.name} ({state.code})
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="modal-field">
                  <label>Zip Code</label>
                  <input
                    type="text"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    placeholder="Enter zip code"
                    className="amulish-numbers" // Mulish font for form input numbers
                  />
                </div>
              </div>

              <div className="modal-buttons">
                <button
                  className="modal-close"
                  onClick={() => setIsModalOpen(false)}
                >
                  Close
                </button>
                <button
                  className={`modal-submit ${animate ? "move-down" : ""}`}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessAddressPage;
