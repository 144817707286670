import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./OrderSummaryPage.css";
import icon from "../../assets/images/icon5.png"; // Correct path to icon5.png
import ProgressBar from "./ProgressBar"; // Import the ProgressBar component

const OrderSummaryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;
  const selectedState = state?.selectedState || "CA"; // Fallback to California if not passed
  const { businessName } = location.state || {};

  const { fullName } = location.state || {};
  const { phone } = location.state || {};
  const { email } = location.state || {};
  const { businessStructure } = location.state || {};

  console.log(":::::: Order Summary Page ::::::::", businessStructure);

  const llcstateFees = {
    Alaska: { fee: 250, name: "Alaska" },
    Alabama: { fee: 236, name: "Alabama" },
    Arkansas: { fee: 45, name: "Arkansas" },
    Arizona: { fee: 85, name: "Arizona" },
    California: { fee: 75, name: "California" },
    Colorado: { fee: 50, name: "Colorado" },
    Connecticut: { fee: 120, name: "Connecticut" },
    DistrictOfColumbia: { fee: 99, name: "District of Columbia" },
    Delaware: { fee: 140, name: "Delaware" },
    Florida: { fee: 155, name: "Florida" },
    Georgia: { fee: 100, name: "Georgia" },
    Hawaii: { fee: 51, name: "Hawaii" },
    Iowa: { fee: 50, name: "Iowa" },
    Idaho: { fee: 100, name: "Idaho" },
    Illinois: { fee: 154, name: "Illinois" },
    Indiana: { fee: 97, name: "Indiana" },
    Kansas: { fee: 166, name: "Kansas" },
    Kentucky: { fee: 40, name: "Kentucky" },
    Louisiana: { fee: 105, name: "Louisiana" },
    Massachusetts: { fee: 520, name: "Massachusetts" },
    Maryland: { fee: 156, name: "Maryland" },
    Maine: { fee: 175, name: "Maine" },
    Michigan: { fee: 50, name: "Michigan" },
    Minnesota: { fee: 155, name: "Minnesota" },
    Missouri: { fee: 50, name: "Missouri" },
    Mississippi: { fee: 53, name: "Mississippi" },
    Montana: { fee: 35, name: "Montana" },
    NorthCarolina: { fee: 128, name: "North Carolina" },
    NorthDakota: { fee: 135, name: "North Dakota" },
    Nebraska: { fee: 105, name: "Nebraska" },
    NewHampshire: { fee: 102, name: "New Hampshire" },
    NewJersey: { fee: 129, name: "New Jersey" },
    NewMexico: { fee: 50, name: "New Mexico" },
    Nevada: { fee: 436, name: "Nevada" },
    NewYork: { fee: 210, name: "New York" },
    Ohio: { fee: 99, name: "Ohio" },
    Oklahoma: { fee: 104, name: "Oklahoma" },
    Oregon: { fee: 100, name: "Oregon" },
    Pennsylvania: { fee: 125, name: "Pennsylvania" },
    RhodeIsland: { fee: 156, name: "Rhode Island" },
    SouthCarolina: { fee: 131, name: "South Carolina" },
    SouthDakota: { fee: 150, name: "South Dakota" },
    Tennessee: { fee: 307, name: "Tennessee" },
    Texas: { fee: 308, name: "Texas" },
    Utah: { fee: 76, name: "Utah" },
    Virginia: { fee: 100, name: "Virginia" },
    Vermont: { fee: 125, name: "Vermont" },
    Washington: { fee: 230, name: "Washington" },
    Wisconsin: { fee: 130, name: "Wisconsin" },
    WestVirginia: { fee: 130, name: "West Virginia" },
    Wyoming: { fee: 104, name: "Wyoming" },
  };

  const CorpstateFees = {
    Alaska: { fee: 250, name: "Alaska" },
    Alabama: { fee: 236, name: "Alabama" },
    Arkansas: { fee: 45, name: "Arkansas" },
    Arizona: { fee: 95, name: "Arizona" },
    California: { fee: 105, name: "California" },
    Colorado: { fee: 50, name: "Colorado" },
    Connecticut: { fee: 250, name: "Connecticut" },
    DistrictOfColumbia: { fee: 270, name: "District of Columbia" },
    Delaware: { fee: 139, name: "Delaware" },
    Florida: { fee: 79, name: "Florida" },
    Georgia: { fee: 140, name: "Georgia" },
    Hawaii: { fee: 50, name: "Hawaii" },
    Iowa: { fee: 50, name: "Iowa" },
    Idaho: { fee: 100, name: "Idaho" },
    Illinois: { fee: 281, name: "Illinois" },
    Indiana: { fee: 97, name: "Indiana" },
    Kansas: { fee: 90, name: "Kansas" },
    Kentucky: { fee: 50, name: "Kentucky" },
    Louisiana: { fee: 75, name: "Louisiana" },
    Massachusetts: { fee: 265, name: "Massachusetts" },
    Maryland: { fee: 217, name: "Maryland" },
    Maine: { fee: 145, name: "Maine" },
    Michigan: { fee: 60, name: "Michigan" },
    Minnesota: { fee: 180, name: "Minnesota" },
    Missouri: { fee: 58, name: "Missouri" },
    Mississippi: { fee: 50, name: "Mississippi" },
    Montana: { fee: 70, name: "Montana" },
    NorthCarolina: { fee: 125, name: "North Carolina" },
    NorthDakota: { fee: 100, name: "North Dakota" },
    Nebraska: { fee: 65, name: "Nebraska" },
    NewHampshire: { fee: 105, name: "New Hampshire" },
    NewJersey: { fee: 129, name: "New Jersey" },
    NewMexico: { fee: 100, name: "New Mexico" },
    Nevada: { fee: 744, name: "Nevada" },
    NewYork: { fee: 135, name: "New York" },
    Ohio: { fee: 100, name: "Ohio" },
    Oklahoma: { fee: 104, name: "Oklahoma" },
    Oregon: { fee: 100, name: "Oregon" },
    Pennsylvania: { fee: 125, name: "Pennsylvania" },
    RhodeIsland: { fee: 238, name: "Rhode Island" },
    SouthCarolina: { fee: 535, name: "South Carolina" },
    SouthDakota: { fee: 150, name: "South Dakota" },
    Tennessee: { fee: 100, name: "Tennessee" },
    Texas: { fee: 308, name: "Texas" },
    Utah: { fee: 76, name: "Utah" },
    Virginia: { fee: 75, name: "Virginia" },
    Vermont: { fee: 75, name: "Vermont" },
    Washington: { fee: 230, name: "Washington" },
    Wisconsin: { fee: 100, name: "Wisconsin" },
    WestVirginia: { fee: 65, name: "West Virginia" },
    Wyoming: { fee: 100, name: "Wyoming" },
  };

  const selectedStateInfo =
    businessStructure === "LLC"
      ? llcstateFees[selectedState]
      : CorpstateFees[selectedState];

  console.log(":::::: 132 line ", selectedStateInfo);

  const [businessActivity, setBusinessActivity] = useState("");
  const [error, setError] = useState("");

  const handlePrevious = () => {
    navigate("/primary-contact");
  };

  const handleNext = () => {
    // Validation for business activity field
    if (businessActivity.trim() === "") {
      setError("Please enter your primary business activity.");
      return;
    }
    // Navigate to the Business Address page if validation passes
    navigate("/business-address", {
      state: {
        fullName,
        phone,
        email,
        businessName,
        selectedState,
        businessActivity,
        businessStructure,
      },
    });
  };

  return (
    <div className="order-summary-container">
      {/* Progress Bar at 30% */}
      <ProgressBar step={30} /> {/* Add this line for the 60% progress bar */}
      <div className="business-heading-wrapper">
        {/* Icon next to the heading */}
        <img src={icon} alt="Icon" className="heading-icon" />
        <h1 className="business-heading">Business Info.</h1>
      </div>
      <div className="summary-activity-wrapper">
        {/* Order Summary */}

        <div class="order-summary">
          <h2>Order Summary</h2>
          <div class="item">
            <span>{businessStructure} Service Fee</span>
            <span></span>
          </div>
          <div class="item">
            <span>Electronic Delivery</span>
            <span class="free">Free</span>
          </div>
          {/* <div class="item">
                  <span>Essentials Package</span>
                  <span className='amulish-numbers'>$147</span>
              </div> */}
          <br />
          <div class="item">
            <span>Government Filing Fee</span>
            <span></span>
          </div>
          <div class="item">
            <span>{selectedState}</span>
            <span></span>
          </div>
          <div class="item">
            <span>Government Fee</span>
            <span className="amulish-numbers">
              ${selectedStateInfo?.fee || 0}
            </span>
          </div>
          <div class="note">Fees collected by the State of {selectedState}</div>
          <div class="total">
            <div>
              Total Order:{" "}
              <span class="amount amulish-numbers">
                ${selectedStateInfo?.fee || 0}
              </span>
            </div>
            <div class="savings amulish-numbers">(You Saved $0)</div>
          </div>
        </div>

        {/* Line Separator */}
        <div className="vertical-line"></div>

        {/* Primary Business Activity */}
        <div className="business-activity">
          <h3>What is your primary business activity?</h3>
          <input
            type="text"
            placeholder="My Business will...."
            className="activity-input"
            value={businessActivity}
            onChange={(e) => setBusinessActivity(e.target.value)}
          />
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
      {/* Navigation Buttons */}
      <div className="anavigation-buttons">
        <span className="aprevious-step" onClick={handlePrevious}>
          ← Previous Step
        </span>
        <button className="anext-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default OrderSummaryPage;
