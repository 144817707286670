import React from 'react';
import A11 from '../../assets/images/Compliance Management.png'; // Import icons
import A33 from '../../assets/images/privacy protection.png';
import A22 from '../../assets/images/Enhance Peace of Mind.png';
import './UniquePeacefulMindSection.css'; // Updated CSS file with unique names

const UniquePeacefulMindSection = () => {
  return (
    <div className="uniquepeacefulsection">
      <h2 className="uniquepeacefulheading">Witness the Benefits of a Registered Agent
      </h2>
      <br></br>
      <br></br>
      <div className="uniquepeacefulcontent">
        <div className="uniquepeacefulbox">
          <img src={A11} alt="Asset Protection Icon" className="uniquepeacefulicon" />
          <h3>Compliance Management</h3>
          <p>
          A professional registered agent ensures that all the documents, notifications, and deadlines are met timely. They also make sure that you stay compliant with state regulations to avoid any kind of penalties and fines. 
          </p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A33} alt="Tax Benefits Icon" className="uniquepeacefulicon rightalignedicon" />
          <h3>Privacy Protection
          </h3>
          <p>
          Find a registered agent for an LLC or Corporation that protects your privacy, and reduces unwanted risks. We also shield your address from going public by providing our address to the public. Stay protected by outsourcing your legal hassle and work more efficiently to grow your business.</p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A22} alt="Credibility Icon" className="uniquepeacefulicon" />
          <h3>Enhance Peace of Mind
          </h3>
          <p>
          While we handle bustling government documents, you can finally focus on more important things to soar your business to new heights. The registered agent keeps you updated about everything timely.</p>
        </div>
      </div>
    </div>
  );
};

export default UniquePeacefulMindSection;
