import React, { useState } from 'react';
import './StateSelectionPage.css';

const stateData = [
  { name: 'California', value: 'CA', price: '$76' },
  { name: 'Nevada', value: 'NV', price: '$95' },
  { name: 'Texas', value: 'TX', price: '$85' },
  { name: 'Florida', value: 'FL', price: '$80' },
  // Add more states as needed...
];

const StateSelectionPage = ({ onStateSelect }) => {
  const [selectedState, setSelectedState] = useState('');

  const handleStateChange = (e) => {
    const selected = e.target.value;
    setSelectedState(selected);
    const stateInfo = stateData.find(state => state.value === selected);
    onStateSelect(stateInfo); // Pass state info to parent component if required
  };

  return (
    <div className="state-selection-container">
      <h2>Select the State for LLC Formation</h2>
      <select
        className="state-dropdown"
        value={selectedState}
        onChange={handleStateChange}
      >
        <option value="">-- Select State --</option>
        {stateData.map((state) => (
          <option key={state.value} value={state.value}>
            {state.name} - {state.price}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StateSelectionPage;
