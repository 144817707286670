import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../../assets/images/logo3.png'; // Ensure the path is correct

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo-section">
        <img src={logo} alt="Business Omni Logo" className="footer-logo" />
        <p className="footer-tagline">From Ideas to Infinity</p>
        <p className="footer-copyright">© Business Omni 2024. All Rights Reserved.</p>
      </div>

      <div className="footer-divider"></div> {/* Divider Line */}

      <div className="footer-links">
        <div className="footer-column">
          <h3>Services</h3>
          <ul>
            <li><Link to="/llc">LLC</Link></li> {/* Link to LLC page */}
            <li><Link to="/cooperation">Corporation</Link></li> {/* Link to Corporation page */}
            <li><Link to="/registered-agent">Registered Agent</Link></li> {/* Link to Registered Agent page */}
            <li><Link to="/boi">Beneficial Ownership Filing</Link></li>
            {/* <li><Link to="/stay">Worry-Free Compliance</Link></li> */}
            <li><Link to="/opera">Operating Agreement</Link></li>
            <li><Link to="/stay">Annual Reports</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Support</h3>
          <ul>
            <li><Link to={"/login"}>Log In</Link></li>
            <li><Link to={"/instruction-steps"}> About</Link></li>
            <li><Link to={"/faqs-page"}>F.A.Q. </Link></li>
            {/* <li>Help Center</li> */}
            <li> <Link to={"/support"}>Contact</Link></li>
            <li>LLC Costs</li>
          </ul>
        </div>
      </div>

      <div className="footer-contact">
        <button className="footer-login-button">LOGIN</button>
        <h3>Contact us</h3>
        <div className="footer-social-icons">
          <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
            <img src="https://img.icons8.com/?size=100&id=8808&format=png&color=40C057" alt="LinkedIn" />
          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
            <img src="https://img.icons8.com/?size=100&id=8818&format=png&color=40C057" alt="Facebook" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
            <img src="https://img.icons8.com/?size=100&id=RhYNENh5cxlS&format=png&color=40C057" alt="Instagram" />
          </a>
          <a href="https://www.whatsapp.com" target="_blank" rel="noreferrer">
            <img src="https://img.icons8.com/?size=100&id=ZeQPTbzIF4jw&format=png&color=40C057" alt="WhatsApp" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
