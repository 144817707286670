import React, { useState } from 'react';
import './FAQComponent.css'; // Updated CSS file reference
import questionIcon from '../../assets/images/question.png'; // Import the question.png image

const frequentlyAskedQuestions = [
  {
    query: "WHAT IS A BENEFICIARY OWNERSHIP REPORT?",
    response: "The beneficial ownership information report identifies the individual direct or indirect control over a company. This ensures regulatory compliance and prevents financial crimes. ",
  },
  {
    query: "WHO IS REQUIRED TO FILE A BENEFICIARY OWNERSHIP REPORT?",
    response: "Any limited liability company, corporation, or any similar entities that are formed in the United States are required to file beneficial ownership information. ",
  },
  {
    query: "WHAT INFORMATION IS TYPICALLY INCLUDED IN A BENEFICIARY OWNERSHIP REPORT?",
    response: "The beneficial ownership information includes names, birthdates, addresses, and identification numbers of individuals with significant control or ownership over the company. Moreover, it includes their level of involvement in the company.  ",
  },
  {
    query: "WHAT HAPPENS IF I FAIL TO FILE A BENEFICIARY OWNERSHIP REPORT?",
    response: "If you fail to file the beneficial owner information reporting, you may face legal actions, fines, and penalties. This can potentially damage your business reputation and may incur regulatory risks. ",
  },
];

const FAQComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQuestions = frequentlyAskedQuestions.filter(faq =>
    faq.query.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faq-section-wrapper">
      <h2 className="faq-title">Frequently Asked Questions About Beneficial Ownership Information Report
      </h2>
      <div className="faq-search-wrapper">
        <input
          type="text"
          className="faq-input-field"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search FAQs..."
        />
        <img src={questionIcon} alt="Search Icon" className="faq-image-icon" /> {/* Use the question.png image */}
      </div>

      <div className="faq-list-container">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((faq, index) => (
            <div className="faq-item-wrapper" key={index}>
              <h3 className="faq-query">{faq.query}</h3>
              <p className="faq-response">{faq.response}</p>
            </div>
          ))
        ) : (
          <p>No FAQs match your search.</p>
        )}
      </div>
    </div>
  );
};

export default FAQComponent;
