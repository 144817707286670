import React, { useState } from 'react';
import './FAQComponent.css'; // Updated CSS file reference
import questionIcon from '../../assets/images/question.png'; // Import the question.png image

const frequentlyAskedQuestions = [
  {
    query: "WHAT IS AN ANNUAL REPORT?",
    response: "The annual report is a state compliance filing mandatory for all businesses. The annual report consists of the company’s current information, such as registered agents, addresses, and directors, to ensure a healthy standing in the state.",
  },
  {
    query: "WHO IS REQUIRED TO FILE AN ANNUAL REPORT?",
    response: "LLCs, corporations, and other registered entities must file annual business reports. The state requires this compliance to maintain the business's legal status.",
  },
  {
    query: "WHAT HAPPENS IF I DON'T FILE MY ANNUAL REPORT?",
    response: "Those businesses that fail to file an annual report are entitled to fines, penalties, administrative dissolution, and loss of their business licenses. This could also disrupt your business operations and incur legal complications.  ",
  },
  {
    query: "WHAT INFORMATION IS TYPICALLY INCLUDED IN AN ANNUAL REPORT?",
    response: "The information in the annual report is based on the company’s state of registration and operations. However, in most cases, the report includes information about the company’s name, address, registered agent, directors, officers, and other relevant company information.  ",
  },
];

const FAQComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQuestions = frequentlyAskedQuestions.filter(faq =>
    faq.query.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faq-section-wrapper">
      <h2 className="faq-title">Frequently Asked Questions About Beneficial Ownership Information Report
      </h2>
      <div className="faq-search-wrapper">
        <input
          type="text"
          className="faq-input-field"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search FAQs..."
        />
        <img src={questionIcon} alt="Search Icon" className="faq-image-icon" /> {/* Use the question.png image */}
      </div>

      <div className="faq-list-container">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((faq, index) => (
            <div className="faq-item-wrapper" key={index}>
              <h3 className="faq-query">{faq.query}</h3>
              <p className="faq-response">{faq.response}</p>
            </div>
          ))
        ) : (
          <p>No FAQs match your search.</p>
        )}
      </div>
    </div>
  );
};

export default FAQComponent;
