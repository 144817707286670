import React, { useState } from 'react';
import './Cooperation.css'; // Use a different CSS for Corporation
import serviceImage from '../../assets/images/Corporate.png'; 
import PeacefulMindSection from './PeacefulMindSection';  // Correct the import path
import YearlyReportSection from './YearlyReportSection';  // Correct the import path
import HowServiceWorks from './HowServiceWorks';  // Correct the import path
import RegisterLLC from './RegisterLLC';  // Correct the import path
import FAQSection from './FAQSection';  // Correct the import path
import AnnualReportSection from './AnnualReportSection';


import { useNavigate } from 'react-router-dom';

const Cooperation = () => {
  const [businessStructure, setBusinessStructure] = useState("");
  const navigate = useNavigate();

  const handleRegisterNowClick = () => {
    navigate('/business-name', { state: { businessStructure: "Corporation" } });
  };
  const handleReportNowClick = () => {
    navigate('/enquire-form');
  };

  return (
    <div className="services-pageq">
      <section className="services-sectionq">
        <div className="breadcrumbsq">
          <span className="white-text">Business OMNI</span>
          <span>&nbsp;&gt;&nbsp;</span>
          <span className="black-text">Corporation</span>
        </div>

        <div className="services-contentq">
          <h1>SET UP A CORPORATION FOR YOUR BUSINESS WITH US</h1>
          <p className="subheadingq text-center">Unlock growth opportunities by attracting investors & raising capital with the help of a Corporation.</p>
          <div className="services-content-wrapperq">
            <div className="services-descriptionq">
              <p className="description-textq">
              Start a Corporation, whether you plan a legally established business that can own assets or incur debt or incorporate your business's operational, accounting, tax, and legal requirements. Raise funds with ease to build your dream empire. Setting up a corporation with BusinessOmni at a minimal cost is your way to grow. 
              </p>
              <button onClick={handleRegisterNowClick} className="service-button primary">START YOUR NEW BUSINESS NOW</button>
              {/* <button onClick={handleReportNowClick} className="service-button secondary">CLICK HERE FOR COORPORATION REPORT</button> */}
              <p className="services-footer-textq">
                Form your Corporation for <strong>$139</strong> plus state fees
              </p>
            </div>
            <img src={serviceImage} alt="Service Illustration" className="service-imageq" />
          </div>
        </div>
        <div>
        <PeacefulMindSection />
          <YearlyReportSection />
          <HowServiceWorks />
          <RegisterLLC />
          <FAQSection />
          {/* <AnnualReportSection/> */}
        </div>
      </section>
    </div>
  );
};

export default Cooperation;
