import React from 'react';
import './ComparePlans.css'; // Ensure you're importing the correct CSS file

const ComparePlans = () => {
  const checkIcon = 'https://cdn0.iconfinder.com/data/icons/font-awesome-solid-vol-1/512/check-1024.png';
  const crossIcon = 'https://cdn1.iconfinder.com/data/icons/andriod-app/36/close-1024.png';

  return (
    <div className="compareplanssection-section">
      <div className="compareplanssection-header">
        <div className="compareplanssection-text">
          <h2 className="compareplanssection-heading">COMPARE PLANS</h2>
          <p className="compareplanssection-description">
            Choose the plans and services that best meet your needs, and then get started with Business Omni.
          </p>
        </div>
        <div className="compareplanssection-plans">
          <div className="compareplanssection-plan">
            <h3>BASIC</h3>
            <button className="compareplanssection-start-now-button basic">
              <span className="compareplanssection-button-text">START NOW</span>
              <div className="compareplanssection-button-price-container">
                <span className="compareplanssection-button-price">$0</span>
                <span className="compareplanssection-button-subtext">PLUS STATE FEE</span>
              </div>
            </button>
          </div>

          <div className="compareplanssection-plan">
            <h3>ESSENTIAL</h3>
            <button className="compareplanssection-start-now-button essential">
              <span className="compareplanssection-button-text">START NOW</span>
              <div className="compareplanssection-button-price-container">
                <span className="compareplanssection-button-price">$139</span>
                <span className="compareplanssection-button-subtext">PLUS STATE FEE</span>
              </div>
            </button>
          </div>

          <div className="compareplanssection-plan">
            <h3>PREMIUM</h3>
            <button className="compareplanssection-start-now-button premium">
              <span className="compareplanssection-button-text">START NOW</span>
              <div className="compareplanssection-button-price-container">
                <span className="compareplanssection-button-price">$229</span>
                <span className="compareplanssection-button-subtext">PLUS STATE FEE</span>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="compareplanssection-comparison-table">
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">Preparation of Articles</div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">File and initiate with the state</div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">Extensive name search</div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">Lifetime help desk</div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">Complete Accuracy Assurance</div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">Business Document</div>
          <div className="compareplanssection-plan-feature"><img src={crossIcon} alt="cross" /></div>
          <div className="compareplanssection-plan-feature"><img src={crossIcon} alt="cross" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">Employer Identification Number (EIN)</div>
          <div className="compareplanssection-plan-feature"><img src={crossIcon} alt="cross" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">Same day Filing</div>
          <div className="compareplanssection-plan-feature"><img src={crossIcon} alt="cross" /></div>
          <div className="compareplanssection-plan-feature"><img src={crossIcon} alt="cross" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">Hassle-Free Lifetime Compliance</div>
          <div className="compareplanssection-plan-feature"><img src={crossIcon} alt="cross" /></div>
          <div className="compareplanssection-plan-feature"><img src={crossIcon} alt="cross" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
        <div className="compareplanssection-table-row">
          <div className="compareplanssection-feature">Beneficial Ownership Report Filing</div>
          <div className="compareplanssection-plan-feature"><img src={crossIcon} alt="cross" /></div>
          <div className="compareplanssection-plan-feature"><img src={crossIcon} alt="cross" /></div>
          <div className="compareplanssection-plan-feature"><img src={checkIcon} alt="check" /></div>
        </div>
      </div>
    </div>
  );
};

export default ComparePlans;
