import React from 'react';
import './ThankYou.css'; // Import CSS for styling

const ThankYou = () => {
    return (
        <div className='thankbody'>
            <div className="thank-you-container">
                <h1 className="thank-you-header">Thank You!</h1>
                <p className="thank-you-message">Your submission has been received successfully.</p>
                <p className="next-steps">We will get back to you shortly. In the meantime, feel free to explore more on our website.</p>
                <a href="/" className="cta-button">Return to Homepage</a>
            </div>
        </div>
    );
};

export default ThankYou;