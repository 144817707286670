import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./InformationReviewPage.css";
import icon from "../../assets/images/icon7.png"; // Correct path to icon
import ProgressBar from "./ProgressBar"; // Import the ProgressBar component

import emailjs from "emailjs-com";
const InformationReview = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const llcstateFees = {
    Alaska: { fee: 250, name: "Alaska" },
    Alabama: { fee: 236, name: "Alabama" },
    Arkansas: { fee: 45, name: "Arkansas" },
    Arizona: { fee: 85, name: "Arizona" },
    California: { fee: 75, name: "California" },
    Colorado: { fee: 50, name: "Colorado" },
    Connecticut: { fee: 120, name: "Connecticut" },
    DistrictOfColumbia: { fee: 99, name: "District of Columbia" },
    Delaware: { fee: 140, name: "Delaware" },
    Florida: { fee: 155, name: "Florida" },
    Georgia: { fee: 100, name: "Georgia" },
    Hawaii: { fee: 51, name: "Hawaii" },
    Iowa: { fee: 50, name: "Iowa" },
    Idaho: { fee: 100, name: "Idaho" },
    Illinois: { fee: 154, name: "Illinois" },
    Indiana: { fee: 97, name: "Indiana" },
    Kansas: { fee: 166, name: "Kansas" },
    Kentucky: { fee: 40, name: "Kentucky" },
    Louisiana: { fee: 105, name: "Louisiana" },
    Massachusetts: { fee: 520, name: "Massachusetts" },
    Maryland: { fee: 156, name: "Maryland" },
    Maine: { fee: 175, name: "Maine" },
    Michigan: { fee: 50, name: "Michigan" },
    Minnesota: { fee: 155, name: "Minnesota" },
    Missouri: { fee: 50, name: "Missouri" },
    Mississippi: { fee: 53, name: "Mississippi" },
    Montana: { fee: 35, name: "Montana" },
    NorthCarolina: { fee: 128, name: "North Carolina" },
    NorthDakota: { fee: 135, name: "North Dakota" },
    Nebraska: { fee: 105, name: "Nebraska" },
    NewHampshire: { fee: 102, name: "New Hampshire" },
    NewJersey: { fee: 129, name: "New Jersey" },
    NewMexico: { fee: 50, name: "New Mexico" },
    Nevada: { fee: 436, name: "Nevada" },
    NewYork: { fee: 210, name: "New York" },
    Ohio: { fee: 99, name: "Ohio" },
    Oklahoma: { fee: 104, name: "Oklahoma" },
    Oregon: { fee: 100, name: "Oregon" },
    Pennsylvania: { fee: 125, name: "Pennsylvania" },
    RhodeIsland: { fee: 156, name: "Rhode Island" },
    SouthCarolina: { fee: 131, name: "South Carolina" },
    SouthDakota: { fee: 150, name: "South Dakota" },
    Tennessee: { fee: 307, name: "Tennessee" },
    Texas: { fee: 308, name: "Texas" },
    Utah: { fee: 76, name: "Utah" },
    Virginia: { fee: 100, name: "Virginia" },
    Vermont: { fee: 125, name: "Vermont" },
    Washington: { fee: 230, name: "Washington" },
    Wisconsin: { fee: 130, name: "Wisconsin" },
    WestVirginia: { fee: 130, name: "West Virginia" },
    Wyoming: { fee: 104, name: "Wyoming" },
  };

  const CorpstateFees = {
    Alaska: { fee: 250, name: "Alaska" },
    Alabama: { fee: 236, name: "Alabama" },
    Arkansas: { fee: 45, name: "Arkansas" },
    Arizona: { fee: 95, name: "Arizona" },
    California: { fee: 105, name: "California" },
    Colorado: { fee: 50, name: "Colorado" },
    Connecticut: { fee: 250, name: "Connecticut" },
    DistrictOfColumbia: { fee: 270, name: "District of Columbia" },
    Delaware: { fee: 139, name: "Delaware" },
    Florida: { fee: 79, name: "Florida" },
    Georgia: { fee: 140, name: "Georgia" },
    Hawaii: { fee: 50, name: "Hawaii" },
    Iowa: { fee: 50, name: "Iowa" },
    Idaho: { fee: 100, name: "Idaho" },
    Illinois: { fee: 281, name: "Illinois" },
    Indiana: { fee: 97, name: "Indiana" },
    Kansas: { fee: 90, name: "Kansas" },
    Kentucky: { fee: 50, name: "Kentucky" },
    Louisiana: { fee: 75, name: "Louisiana" },
    Massachusetts: { fee: 265, name: "Massachusetts" },
    Maryland: { fee: 217, name: "Maryland" },
    Maine: { fee: 145, name: "Maine" },
    Michigan: { fee: 60, name: "Michigan" },
    Minnesota: { fee: 180, name: "Minnesota" },
    Missouri: { fee: 58, name: "Missouri" },
    Mississippi: { fee: 50, name: "Mississippi" },
    Montana: { fee: 70, name: "Montana" },
    NorthCarolina: { fee: 125, name: "North Carolina" },
    NorthDakota: { fee: 100, name: "North Dakota" },
    Nebraska: { fee: 65, name: "Nebraska" },
    NewHampshire: { fee: 105, name: "New Hampshire" },
    NewJersey: { fee: 129, name: "New Jersey" },
    NewMexico: { fee: 100, name: "New Mexico" },
    Nevada: { fee: 744, name: "Nevada" },
    NewYork: { fee: 135, name: "New York" },
    Ohio: { fee: 100, name: "Ohio" },
    Oklahoma: { fee: 104, name: "Oklahoma" },
    Oregon: { fee: 100, name: "Oregon" },
    Pennsylvania: { fee: 125, name: "Pennsylvania" },
    RhodeIsland: { fee: 238, name: "Rhode Island" },
    SouthCarolina: { fee: 535, name: "South Carolina" },
    SouthDakota: { fee: 150, name: "South Dakota" },
    Tennessee: { fee: 100, name: "Tennessee" },
    Texas: { fee: 308, name: "Texas" },
    Utah: { fee: 76, name: "Utah" },
    Virginia: { fee: 75, name: "Virginia" },
    Vermont: { fee: 75, name: "Vermont" },
    Washington: { fee: 230, name: "Washington" },
    Wisconsin: { fee: 100, name: "Wisconsin" },
    WestVirginia: { fee: 65, name: "West Virginia" },
    Wyoming: { fee: 100, name: "Wyoming" },
  };

  const { state } = location;
  const selectedState = state?.selectedState || "CA"; // Fallback to California if not passed
  const selectedPackage =
    location.state?.selectedPackage || "No Package Selected";
  const packageValue = location.state?.packageValue ?? "Not Available";
  const { fullName } = location.state || {};
  const { phone } = location.state || {};
  const { email } = location.state || {};
  const { totalOrderValue } = location.state || {};
  const { businessName } = location.state || {};
  const { businessActivity } = location?.state || {};
  const { businessAddress } = location.state || {};
  const { selectedOwners } = location.state || {};
  const { rush } = location.state || {};
  const ownersValue = selectedOwners?.match(/\d+/)?.[0];

  const { businessStructure } = location.state || {};

  // const ownersValues = parseInt(ownersValue, 10);

  console.log("select Owners", businessActivity);

  const selectedStateInfo =
    businessStructure === "LLC"
      ? llcstateFees[selectedState]
      : CorpstateFees[selectedState];

  const sendEmail = () => {
    // Destructure values directly from location.state
    const {
      businessName = "Not Provided",
      businessActivity = "Not Provided",
      businessAddress = "Not Provided",
      selectedOwners = "Not Provided",
      fullName = "Not Provided",
      phone = "Not Provided",
      email = "Not Provided",
      businessStructure = "Not Provided",
      selectedState = "Not Provided",
      selectedPackage = "Not Provided",
      packageValue = "Not Provided",
    } = location.state || {};
    const cleanSelectedOwners = selectedOwners.replace(/<[^>]*>?/gm, "").trim();

    const message = `
    Business Name: ${businessName}
    Business Activity: ${businessActivity}
    Business Address: ${businessAddress}
    Owners: ${cleanSelectedOwners}
    Phone: ${phone}
    Email: ${email}
    Business Structure: ${businessStructure}
    Selected State: ${selectedState}
    Selected Package: ${selectedPackage}
    Package Value: ${packageValue}
  `.trim(); // Remove extra whitespace around the message
    // Prepare template parameters to match the placeholders in the template
    const templateParams = {
      to_name: "Admin", // Recipient name (or business name, adjust as needed)
      from_name: fullName, // Sender's name (fullName in your case)
      message,
    };

    // Log templateParams for debugging
    console.log("Sending these template params to EmailJS:", templateParams);

    // Using emailjs to send the email
    emailjs
      .send(
        "Business Omni", // Service ID
        "template_7dtajvh", // Template ID
        templateParams, // Data being passed to the template
        "M5j0butrNTjwp4HeX" // Public API key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Your information has been successfully sent!");
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Something went wrong, please try again!");
        }
      );
  };

  const handlePrevious = () => {
    navigate("/rush-page", {
      state: {
        selectedState,
        selectedPackage,
        packageValue,
        fullName,
        phone,
        email,
        totalOrderValue,
        businessName,
        businessActivity,
        businessAddress,
        selectedOwners,
        rush,
        businessStructure,
      },
    });
  };

  const handleProceed = () => {
    navigate("/payment");
  };

  const handleSubmit = () => {
    handleProceed();
    sendEmail();
  };
  /*     const handleNext = () => {
    navigate("/thank-you"); // Navigate to the next step for Thank You
  }; 
 */
  return (
    <div className="order-summary-container">
      {/* Progress Bar at 80% */}
      <ProgressBar step={80} />

      <div className="business-heading-wrapper">
        {/* Icon next to the heading */}
        <img src={icon} alt="Icon" className="heading-icon" />
        <h1 className="business-heading">Information Review</h1>
      </div>

      <div className="summary-activity-wrapper">
        <div class="rush-order-summary order-summary">
          <h2>Order Summary</h2>
          <div class="item">
            <span>{businessStructure} Service Fee</span>
            <span></span>
          </div>
          <div class="item">
            <span>Electronic Delivery</span>
            <span class="free">Free</span>
          </div>
          <div class="item">
            <span>{selectedPackage} Package</span>
            <span className="amulish-numbers">
              {packageValue === 0 ? "Free" : `$${packageValue}`}
            </span>
          </div>
          <div class="item">
            <span>Rush Package</span>
            <span className="amulish-numbers">{rush ? `$${rush}` : "0"}</span>
          </div>
          <div class="item">
            <span>Government Filing Fee</span>
            <span></span>
          </div>
          <div class="item">
            <span>{selectedState}</span>
            <span></span>
          </div>
          <div class="item">
            <span>Government Fee</span>
            <span className="amulish-numbers">
              ${selectedStateInfo?.fee || 0}
            </span>
          </div>
          <div class="note">Fees collected by the State of {selectedState}</div>
          <div class="total">
            <div>
              Total Order:{" "}
              <span class="amount amulish-numbers">${totalOrderValue}</span>
            </div>
            <div class="savings amulish-numbers">(You Saved $0)</div>
          </div>
        </div>

        {/* Order Summary */}
        {/* <div className="order-summary">
          <h3>Order Summary</h3>

          <div className="summary-details">
            <div className="summary-title-grouped">
              <p className="summary-title">LLC Service Fee</p>
              <p className="summary-title">Electronic Delivery</p>
              <p className="summary-title">{selectedPackage} Package</p>
            </div>
            <p className="summary-value free-text mulish-numbers">{packageValue === 0 ? "Free" : `$${packageValue}`}</p>
          </div>

          <div className="summary-details">
            <div className="summary-title-grouped-price">
              <p className="summary-title">Government Filing Fee</p>
              <p className="summary-title">{selectedState}</p>
              <p className="summary-title">Government Fee*</p>
            </div>
            <p className="summary-value price-text mulish-numbers">${selectedStateInfo?.fee || 0}</p>
          </div>

          <p className="disclaimer">* Fees collected by the State of {selectedState} to form your LLC</p>

          <div className="total-order">
            <p className="total-order-text">Total Order: <span className="total-order-price">${totalOrderValue}</span></p>
            <p className="saved-text">(You Saved $0)</p>
          </div>
        </div> */}

        {/* Line Separator */}
        <div className="vertical-line"></div>

        {/* Rush Section */}
        <div className="ownership-section mb-4">
          <div style={{ fontSize: "14px", color: "#333" }}>
            Please confirm the information below:
          </div>
          <br />

          <div
            className="mb-3"
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #00c853", // Green border
              borderRadius: "20px",
              padding: "10px 20px",
              maxWidth: "500px",
              backgroundColor: "white",
            }}
          >
            <div style={{ fontSize: "14px", color: "#333" }}>
              <strong>Business Name</strong>
              <br />
              Company Name: {businessName}
              <br />
              State of Formation: {selectedState}
            </div>
          </div>
          <div
            className="mb-3"
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #00c853", // Green border
              borderRadius: "20px",
              padding: "10px 20px",
              maxWidth: "500px",
              backgroundColor: "white",
            }}
          >
            <div style={{ fontSize: "14px", color: "#333" }}>
              <strong>Primary Contact.</strong>
              <br />
              Name: {fullName}
              <br />
              Phone Number: {phone}
              <br />
              Email: {email}
            </div>
          </div>
          <div
            className="mb-3"
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #00c853", // Green border
              borderRadius: "20px",
              padding: "10px 20px",
              maxWidth: "500px",
              backgroundColor: "white",
            }}
          >
            <div style={{ fontSize: "14px", color: "#333" }}>
              <strong>Business Information.</strong>
              <br />
              Activity: {businessActivity}
            </div>
          </div>
          <div
            className="mb-3"
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #00c853", // Green border
              borderRadius: "20px",
              padding: "10px 20px",
              maxWidth: "500px",
              backgroundColor: "white",
            }}
          >
            <div style={{ fontSize: "14px", color: "#333" }}>
              <strong>Ownership.</strong>
              <br />
              Number of Owners: {ownersValue}
            </div>
          </div>

          <div style={{ fontSize: "14px", color: "#333" }}>
            Upon the completion of this form, you will enter the details
            regarding the ownership structure of this company. Our Team is
            available if you have any questions.
          </div>

          <div
            className=" mt-3 mb-3"
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #00c853", // Green border
              borderRadius: "20px",
              padding: "10px 20px",
              maxWidth: "500px",
              backgroundColor: "white",
            }}
          >
            <div style={{ fontSize: "14px", color: "#333" }}>
              <strong>Business Address</strong>
              <br />
              {businessAddress}
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="anavigation-buttons">
        <button className="aprevious-step" onClick={handlePrevious}>
          ← Previous Step
        </button>
        <button className="anext-button" onClick={handleSubmit}>
          Next →
        </button>
      </div>
    </div>
  );
};

export default InformationReview;
