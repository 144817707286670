import React from 'react';
import './HowServiceWorks.css'; // Import the CSS file for styling

const HowServiceWorks = () => {
  return (

    <div className="howserviceworkssection">
    <h2 className="howserviceworksheadingg">Why Choose BusinessOmni to Handle Your Annual <br/> Report Compliance</h2>
    <p className="m-5 text-center cust-margin" style={{fontSize:"20px"}}>BusinessOmni is known for offering seamless and timely annual report filing services. Our expert team ensures that all your compliance requirements are met.<br/> As a business, we understand how complex it is to manage these things, but worry no more—we are at your service with the best prices possible. </p>
    <div className="howserviceworkscontent">
      <div className="howserviceworksboxx">
      <h2>Expert Assistance
          </h2>
          <p>Our team always stays up to date with every legal compliance by the state. According to our information, we inform our clients of the best way to eliminate this additional paperwork. Annual reports may seem petty but can cause much damage if not pursued ideally. At BusinessOmni, it is our mission to provide tailored solutions. </p>
      </div>
      
      <div className="howserviceworksboxx">
      <h2>Timely Filing</h2>
      <p>We do not wait for the deadline to run us over, but we take a step ahead and file the annual report as soon as we receive adequate information from our client. This prompt filing helps our client eliminate any unwanted penalties or fines.  </p>
      </div>
      
      <div className="howserviceworksboxx">
      <h2>Hassle-free Process</h2>
      <p>We understand that running a business is not easy, so we take unnecessary burdens off your shoulders so you can work efficiently and skyrocket your business. BusinessOmni will handle all the complex documents and details while keeping you compliant. </p>
      </div>
    </div>
  </div>
  );
};

export default HowServiceWorks;
