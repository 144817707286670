import React from 'react';
import './HowServiceWorks.css'; // Import the CSS file for styling

const HowServiceWorks = () => {
  return (

    <div className="howserviceworkssection">
    <h2 className="howserviceworksheadingg">Why Choose Business Omni for Operating Agreement for LLC</h2>
    <p className="m-5 text-center cust-margin" style={{fontSize:"20px"}}>Business Omni is a professional in this field, we are known to provide custom made operating agreements tailored to your unique business models. Strive for success and protect your business with an operating agreement for your LLC</p>
    <div className="howserviceworkscontent">
      <div className="howserviceworksboxx">
      <h2>Legal Expertise</h2>
      <p>Our expert team is known to provide operating agreements that meet all the compliance of your business. You can protect your business while meeting all your compliance requirements with ease. </p>
      </div>
      
      <div className="howserviceworksboxx">
      <h2>Custom Solutions 
          </h2>
          <p>We understand that every business has a unique structure that is why we create a tailored operating agreement that ensures all the key aspects, like roles, and ownership rights are ideally addressed.</p>
      </div>
      
      <div className="howserviceworksboxx">
      <h2>Conflict Prevention</h2>
      <p>Business Omni operating agreement minimize the risk of unwanted disputes, allowing seamless business management. We create operating agreement that outlines the operation procedures, and the ownership terms.</p>
      </div>
    </div>
  </div>

  );
};

export default HowServiceWorks;
