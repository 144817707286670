import React from 'react';
import './aboutUs.css';
import icon1 from '../../assets/images/icon1.png'; // Replace with actual image paths
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import icon4 from '../../assets/images/icon4.png';

const AboutUs = () => {
  return (
    <div className="what-sets-us-apart-section">
      <h2>About</h2>
      <p className="description">
      At Business Omni, we pride ourselves on delivering exceptional company formation services tailored to your needs. Our expertise ensures a seamless registration process, guiding you through every step clearly and professionally. We prioritize personalized support, expert advice, and timely assistance to smooth your business journey. With a commitment to transparency and integrity, we provide clear information without hidden fees. Our dedicated team stays updated on regulations, ensuring our client’s compliance and peace of mind. Choose us for reliable service, unparalleled knowledge, and a passion for helping you succeed in your entrepreneurial endeavors.
      </p>
      <div className="features">
        <div className="feature-box">
          <img src={icon1} alt="Quick Business Setup" className="feature-icon"/>
          <h3>Hassle-Free Service</h3>
          <p>We take your stress away with business formation and compliance, managing all your paperwork and filings. We handle all the complex legal details so you can focus on growing your business. We understand that our clients have a strategy on which they are going to build their platform. Our experts will take all your pain, so you can work on your brand building.</p>
          <button className="learn-more-button">Learn More</button>
        </div>
        <div className="feature-box">
          <img src={icon2} alt="Expert Help & Ongoing Support" className="feature-icon"/>
          <h3>Efficient and Reliable Filing
          </h3>
          <p>We ensure timely and accurate filings for LLCs, Corporations, annual reports, BOIs, and more. Business Omni strives to meet all the deadlines, keep your business compliant, and avoid unwanted interruptions and penalties. Time is the biggest challenge during this process, the longer time causes delayed operations. Our team of experts will quickly file all the necessary documents accurately so that you can get going. </p>
          <button className="learn-more-button">Learn More</button>
        </div>
        <div className="feature-box">
          <img src={icon3} alt="Complete Services Packages" className="feature-icon"/>
          <h3>Dedicated Customer Support
          </h3>
          <p>Business Omni prides itself on providing the finest customer support tailored to our client business needs. Our dedicated team of professionals is available to guide you through every step of the way. We offer clear, professional, and prompt assistance to our clients. Ensuring the resolution of your question and seamless communication is our top priority. This gives the client a sigh of relief, as this process is complex and hectic already. 
          </p>
          <button className="learn-more-button">Learn More</button>
        </div>
        <div className="feature-box">
          <img src={icon4} alt="Clear Prices, No Surprices" className="feature-icon"/>
          <h3>Expert Guidance
          </h3>
          <p>With Business Omni, you will get expert advice at every step. We do everything from business formation to compliance reporting with flying colors. We make sure that your business vertical is legally sound and aligns with your professional goals. That can only be achieved by having an expert on your side. We will provide you with expert guidance so that you do the paperwork ideally. 
          </p>
          <button className="learn-more-button">Learn More</button>
        </div>
        <div className="feature-box">
          <img src={icon3} alt="Complete Services Packages" className="feature-icon"/>
          <h3>Clear Price, No Surprise

          </h3>
          <p>Business Omni is known for offering transparent pricing with no hidden fees. Our direct pricing model means you’ll witness what you’re paying for, offering you peace of mind from start to finish. We don’t believe in overcharging our clients by giving them unnecessary surprises. Our mission is to empower entrepreneurs to join us and create an iconic business.  
          </p>
          <button className="learn-more-button">Learn More</button>
        </div>
        <div className="feature-box">
          <img src={icon3} alt="Complete Services Packages" className="feature-icon"/>
          <h3>Timely Updates

          </h3>
          <p>We always strive to provide the best services possible. Moreover, we understand that entrepreneurs and business owners are super busy managing their operations, this is where we step in and let go of their business formation burden. We always inform our clients about the process, how long it will take, and when to do what. Most of our competitors left the client out in the dry and did not offer timely updates. But worry no more, we have put an end to that so you always feel connected with what’s going on. 
 
          </p>
          <button className="learn-more-button">Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
