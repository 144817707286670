import React from 'react';
import { Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../../assets/images/logo.png'; // Path to the main logo
import './Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';


  const Header = () => {
    const navigate = useNavigate(); // Hook to navigate between pages
  
    const handleLoginClick = () => {
      navigate('/login'); // Navigates to the Business Name page
    };
    const handleSignUpClick = () => {
      navigate('/sign-up'); // Navigates to the Business Name page
    };
  
    return (
      <Navbar expand="lg" className="header-navbar" collapseOnSelect>
        <Navbar.Brand as={Link} to="/" className="navbar-brand-custom">
          <img src={logo} className="logo" alt="Business Omni logo" />
        </Navbar.Brand>
        {/* Navbar.Toggle will act as a hamburger icon in mobile view */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="nav-links">
            <Nav.Link as={Link} to="/">HOME</Nav.Link>
            {/* <Nav.Link as={Link} to="/services">SERVICES</Nav.Link> */}
    {/* Dropdown with link on SERVICES */}
    <NavDropdown
              title={<Link to="#" style={{ textDecoration: 'none', color: 'inherit' }}>SERVICES</Link>}
              id="services-dropdown"
            >
              <NavDropdown.Item as={Link} to="/llc">LLC</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/cooperation">Corporation</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/registered-agent">Registered Agent</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/boi">Beneficial Ownership Filing</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/stay">Worry-Free Compliance</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/opera">Operating Agreement</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/stay">Annual Reports</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={Link} to="/instruction-steps">WHY OMNI</Nav.Link>
            <Nav.Link as={Link} to="/support">SUPPORT</Nav.Link>
          </Nav>
          <div className="button-container">
            <Button onClick={handleLoginClick} variant="outline-success" className="login-button">LOGIN</Button>
            <Button onClick={handleSignUpClick} variant="primary" className="signup-button">SIGNUP</Button>
          </div>
        </Navbar.Collapse>
      </Navbar>
    );
};

export default Header;
