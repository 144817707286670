import React from 'react';
import './Contactmeee.css';
import a10 from '../../assets/images/A10.png'; // Import the image for the section

const Contactme = () => {
  
  return (
    <div className="contact-container">
      {/* Contact Us Section */}
      <div className="contact-section">
        <h1 className="contact-heading">CONTACT US</h1>
        <p className="contact-info"><strong>(+1) 307-222-1006</strong></p>
        <p>Email - <strong>help@businessomni.com</strong></p>
        {/* Talk to Us Section */}
      <div className="talk-section">
        <h1 className="talk-heading">TALK TO US</h1>
        <p>Message our team and review helpful articles using chat.</p>
        <p>Monday – Friday <strong>09 am - 05 pm</strong> PST</p>
      </div>
      </div>
      
      <div className="contact-icon" >
    <img src={a10} alt="Contact Illustration" className='contact-image' /> {/* Updated class name */}
</div>

   </div>
  );
};

export default Contactme;
