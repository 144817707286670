import React, { useState } from 'react';
import './SupportPage.css';
import Contactme from './Contactme';
import FAw from './FAw'
import FAQSection from '../Hompage/FAQSection';

const SupportPage = () => {
  const [selectedOption, setSelectedOption] = useState('');

  return (
    <div className="support-section-container">
      {/* Send Us An Email Section */}
      <div className="support-section">
        <h1 className="support-heading">Send Us An Email</h1>
        <p className="support-subheading">
          Our team strives to respond within one business day but response times may vary.
        </p>

        <form className="support-form">
          <div className="form-row">
            <input type="text" placeholder="First Name" className="form-input" />
            <input type="text" placeholder="Last Name" className="form-input" />
          </div>

          <div className="form-row">
            <input type="email" placeholder="Email" className="form-input" />
            <input type="tel" placeholder="Phone" className="form-input" />
          </div>

          <div className="form-row">
            <label className="form-label">Preferred Method of Contact</label>
            <select className="form-select">
              <option value="">Please Select...</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
          <div className="form-row purchase-option">
            <div
              className={`purchase-option-box ${selectedOption === 'purchased' ? 'selected' : ''}`}
              onClick={() => setSelectedOption('purchased')}
            >
              <input type="checkbox" checked={selectedOption === 'purchased'} readOnly />
              <label>I have made a purchase</label>
            </div>
            <div
              className={`purchase-option-box ${selectedOption === 'not-purchased' ? 'selected' : ''}`}
              onClick={() => setSelectedOption('not-purchased')}
            >
              <input type="checkbox" checked={selectedOption === 'not-purchased'} readOnly />
              <label>I have not made a purchase</label>
            </div>
          </div>
          </div>


          <div className="form-row">
            <input type="text" placeholder="Subject" className=" subject-input" />
          </div>

          <div className="form-row">
            <textarea placeholder="Description" className="form-textarea"></textarea>
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>

      {/* Additional Section */}
      <Contactme />
      {/* <FAw /> */}
      <FAQSection/>
    </div>
  );
};

export default SupportPage;
