import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './BusinessNamePage.css';
import icon from '../../assets/images/a88.png'; // Correct icon path
import ProgressBar from './ProgressBar'; // Import the progress bar

const BusinessNamePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Destructure location state
  const { businessStructure, formationState, fullName, homeselectedState, phone, email } = location.state || {};

  console.log(":::::::::: businessStructure ::::::::::::", businessStructure);
  console.log(":::::::::: formationState ::::::::::::", formationState);
  console.log(":::::::::: homeselectedState ::::::::::::", homeselectedState);

  // Set the initial state values (updated inside useEffect)
  const [businessName, setBusinessName] = useState('');
  const [selectedState, setSelectedState] = useState('');

  // Update selectedState when homeselectedState or formationState is available
  useEffect(() => {
    if (homeselectedState) {
      setSelectedState(homeselectedState);
    } else if (formationState) {
      setSelectedState(formationState);
    }
  }, [homeselectedState, formationState]);

  const handleNext = () => {
    if (!businessName || !selectedState) {
      alert('Please enter a business name and select a state.');
      return;
    }
    navigate('/primary-contact', {
      state: { fullName, phone, email, businessName, selectedState, businessStructure }
    });
  };

  return (
    <div className="businessnamecontainer">
      <ProgressBar step={10} /> {/* Add Progress Bar -- 10% progress for this step */}
      <div className="formcontent">
        <div className="businessheadingwrapper">
          <img src={icon} alt="Icon" className="businessicon" />
          <h1 className="businessheading">Business Name.</h1>
        </div>

        <div className="inputwrapper">
          <input
            type="text"
            className="businessinput"
            placeholder="Business Name"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </div>

        <p className="subheading">In which state would you like to form your business?</p>
        <div className="inputwrapper">
          <select
            className="businessinput"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            <option value="">-- Select State or Territory --</option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
            <option value="District of Columbia">District of Columbia (D.C.)</option>

          </select>
        </div>
      </div>

      <button className="nextbutton" onClick={handleNext}>
        NEXT
      </button>
    </div>
  );
};

export default BusinessNamePage;
