import React from 'react';
import './ServicesSectionssA.css';
import serviceImage from '../../assets/images/ser11.png';
import PeacefulMindSection from './PeacefulMindSection';
import YearlyReportSection from './YearlyReportSection';
import HowServiceWorks from './HowServiceWorks';  
import RegisterLLC from './RegisterLLC';
import AnnualReportSection from './AnnualReportSection';
// import FAQSection from './FAQSection';
import FAQSection from '../Hompage/FAQSection';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const navigate = useNavigate(); 

  const handleRegisterNowClick = () => {
    navigate('/business-name'); 
  };

  return (
    <div className="services-page">
      <section className="services-section">
      <div className="breadcrumbs">
  <span className="white-text">Business OMNI</span>
  <span>&nbsp;&gt;&nbsp;</span>
  <span className="black-text">Annual Report</span>
</div>
        <div className="services-content">
          <h1>REPORT ANNUALLY AS SOON AS POSSIBLE AND ACCURATELY</h1>
          <p className="subheading">With our annual report filing service, you can avoid errors and save time.</p>
          <div className="services-content-wrapper">
            <div className="services-description">
              <p className="description-text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod 
                tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. 
              </p>
              <button onClick={handleRegisterNowClick} className="service-button primary">START YOUR NEW BUSINESS FIRST</button>
              <button onClick={handleRegisterNowClick} className="service-button secondary">LOGIN TO FILE AN ANNUAL REPORT</button>
              <p className="services-footer-text">
                File your annual report for <strong>$120</strong> plus state fees
              </p>
            </div>
            <img src={serviceImage} alt="Service Illustration" className="service-image" />
          </div>
        </div>
        <div>
          <PeacefulMindSection />
          <YearlyReportSection />
          <HowServiceWorks />
          <RegisterLLC />
          <FAQSection />
          <AnnualReportSection />
        </div>
      </section>
    </div>
  );
};

export default Services;
