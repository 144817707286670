import React, { useState } from "react";
import "./forms.css";
import emailjs from "emailjs-com";

const EnquireForm = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessName: "",
    reportType: "",
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form refresh

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phone ||
      !formData.businessName ||
      !formData.reportType
    ) {
      setMessage("Please fill in all fields.");
      console.error("Form validation failed: Missing fields");
      return;
    }

    setLoading(true);

    // Adjust templateParams to match the EmailJS template
    const templateParams = {
      to_name: "Admin",
      from_name: `${formData.firstName} ${formData.lastName}`,
      message: `You have received a message from ${formData.firstName} ${formData.lastName} (${formData.email}).
      
      Phone: ${formData.phone}
      Business Name: ${formData.businessName}
      Report Type: ${formData.reportType}`,
    };

    console.log("Template Params: ", templateParams);

    emailjs
      .send(
        "Business Omni", // Replace with your service ID
        "template_7dtajvh", // Replace with your template ID
        templateParams,
        "M5j0butrNTjwp4HeX" // Replace with your API key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Your information has been successfully sent!");
          setMessage("Message sent successfully!");
        },
        (err) => {
          console.error("FAILED...", err);
          alert("Something went wrong, please try again!");
          setMessage("Failed to send message. Please try again later.");
        }
      )
      .finally(() => {
        setLoading(false);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          businessName: "",
          reportType: "",
        });
        console.log("Form reset after submission.");
      });
  };

  return (
    <div className="support-section-container">
      <div className="support-section">
        <h1 className="support-heading">Fill The Fields Below</h1>
        <p className="support-subheading">
          Our team strives to respond within one business day but response times
          may vary.
        </p>

        <form className="support-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="form-input"
              value={formData.firstName}
              onChange={handleChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="form-input"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>

          <div className="form-row">
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="form-input"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              className="form-input"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>

          <div className="form-row">
            <input
              type="text"
              name="businessName"
              placeholder="Business Name"
              className="form-input"
              value={formData.businessName}
              onChange={handleChange}
            />
            <input
              type="text"
              name="reportType"
              placeholder="Which type of report you want"
              className="form-input"
              value={formData.reportType}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? "Sending..." : "Submit"}
          </button>
        </form>
        {message && <p className="form-message">{message}</p>}
      </div>
    </div>
  );
};

export default EnquireForm;
