import React, { useState } from 'react';
import './FAQComponent.css'; // Updated CSS file reference
import questionIcon from '../../assets/images/question.png'; // Import the question.png image

const frequentlyAskedQuestions = [
  {
    query: "WHAT IS A CORPORATION?",
    response: "A corporation is a legal entity that is separate from its owners. Moreover, it offers a limited liability to the shareholders. It can enter contracts and own assets. Furthermore, profit is also distributed as dividends.",
  },
  {
    query: "HOW DO I FORM A CORPORATION?",
    response: "You must choose a unique name, file an article of incorporation with your state, appoint directors, issue shares to shareholders, create corporate bylaws, acquire required permits/licenses, and apply for an employer identification number. ",
  },
  {
    query: "WHAT IS THE DIFFERENCE BETWEEN DIRECTOR AND SHAREHOLDER?",
    response: "A director is responsible for overseeing and managing the operations and making decisions of the corporation. On the toss of the coin, shareholders own shares in the company but do not manage the daily activities of the business. Shareholders are the company's investors and may vote on major decisions like directors.",
  },
  {
    query: "WHAT IS THE DIFFERENCE BETWEEN S-CORP AND C-CORP?",
    response: (
      <>
        S-Corp is a tax status election, not an entity structure. It permits business owners to pay self-employment tax like social security and Medicare on their salary income but not on the remaining earnings distributed to them as a portion of their firm.
        <br />
        C-Corp is a formal business structure in which you pay corporate taxes separately. Also, the shareholders are taxed on dividends.
      </>
    ),
  },
];

const FAQComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQuestions = frequentlyAskedQuestions.filter(faq =>
    faq.query.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faq-section-wrapper">
      <h2 className="faq-title">Frequently Asked Questions About Corporation
      </h2>
      <div className="faq-search-wrapper">
        <input
          type="text"
          className="faq-input-field"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search FAQs..."
        />
        <img src={questionIcon} alt="Search Icon" className="faq-image-icon" /> {/* Use the question.png image */}
      </div>

      <div className="faq-list-container">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((faq, index) => (
            <div className="faq-item-wrapper" key={index}>
              <h3 className="faq-query">{faq.query}</h3>
              <p className="faq-response">{faq.response}</p>
            </div>
          ))
        ) : (
          <p>No FAQs match your search.</p>
        )}
      </div>
    </div>
  );
};

export default FAQComponent;
