import React, { useState} from "react";
import { useNavigate } from "react-router-dom"; 

import mapImage from "../../assets/images/updated-map.png"; 
import "./RegisterAllOverUS.css";

const USMapHover = () => {
  const [hoveredState, setHoveredState] = useState(null); // Track the hovered state
  const [selectedState, setSelectedState] = useState(null); // Track the clicked (selected) state
  const [mouePos, setMousePos] = useState({ x: 0, y: 0 });
  const [lockTimeout, setLockTimeout] = useState(null); // To store the timeout ID for clearing the selection
  const navigate = useNavigate(); // Use useNavigate for navigation

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setMousePos({ x, y });

  

    // Continue detecting hover zones even if a state is selected
    if (x > 12 && x < 61 && y > 125 && y < 295) {
      setHoveredState("California");
    } else if (x > 59 && x < 130 && y > 133 && y < 240) {
      setHoveredState("Nevada");
    } else if (x > 10 && x < 111 && y > 58 && y < 128) {
      setHoveredState("Oregon");
    } else if (x > 37 && x < 124 && y > 7 && y < 51) {
      setHoveredState("Washington");
    } else if (x > 113 && x < 182 && y > 15 && y < 135) {
      setHoveredState("Idaho");
    } else if (x > 133 && x < 196 && y > 144 && y < 227) {
      setHoveredState("Utah");
    } else if (x > 102 && x < 194 && y > 230 && y < 316) {
      // Example coordinates for Arizona (AZ)
      setHoveredState("Arizona");
    } else if (x > 139 && x < 279 && y > 27 && y < 95) {
      // Example coordinates for Montana (MT)
      setHoveredState("Montana");
    } else if (x > 177 && x < 260 && y > 98 && y < 166) {
      // Example coordinates for Wyoming (WY)
      setHoveredState("Wyoming");
    } else if (x > 198 && x < 292 && y > 170 && y < 232) {
      // Example coordinates for Colorado (CO)
      setHoveredState("Colorado");
    } else if (x > 186 && x < 273 && y > 239 && y < 326) {
      // Example coordinates for New Mexico (NM)
      setHoveredState("New Mexico");
    } else if (x > 10 && x < 87 && y > 348 && y < 415) {
      setHoveredState("Alaska");
    } else if (x > 88 && x < 216 && y > 355 && y < 443) {
      setHoveredState("Hawaii");
    } else if (x > 277 && x < 362 && y > 37 && y < 87) {
      // Example coordinates for North Dakota (ND)
      setHoveredState("North Dakota");
    } else if (x > 272 && x < 373 && y > 88 && y < 150) {
      // Example coordinates for South Dakota (SD)
      setHoveredState("South Dakota");
    } else if (x > 270 && x < 382 && y > 140 && y < 192) {
      // Example coordinates for Nebraska (NE)
      setHoveredState("Nebraska");
    } else if (x > 292 && x < 395 && y > 192 && y < 243) {
      // Example coordinates for Kansas (KS)
      setHoveredState("Kansas");
    } else if (x > 320 && x < 397 && y > 244 && y < 300) {
      // Example coordinates for Oklahoma (OK)
      setHoveredState("Oklahoma");
    } else if (x > 217 && x < 410 && y > 252 && y < 433) {
      // Example coordinates for Texas (TX)
      setHoveredState("Texas");
    } else if (x > 369 && x < 435 && y > 38 && y < 133) {
      // Example coordinates for Minnesota (MN)
      setHoveredState("Minnesota");
    } else if (x > 371 && x < 450 && y > 134 && y < 183) {
      // Example coordinates for Iowa (IA)
      setHoveredState("Iowa");
    } else if (x > 384 && x < 438 && y > 185 && y < 251) {
      // Example coordinates for Missouri (MO)
      setHoveredState("Missouri");
    } else if (x > 397 && x < 462 && y > 254 && y < 313) {
      // Example coordinates for Arkansas (AR)
      setHoveredState("Arkansas");
    } else if (x > 405 && x < 470 && y > 315 && y < 378) {
      // Example coordinates for Louisiana (LA)
      setHoveredState("Louisiana");
    } else if (x > 423 && x < 485 && y > 54 && y < 145) {
      // Example coordinates for Wisconsin (WI)
      setHoveredState("Wisconsin");
    } else if (x > 440 && x < 489 && y > 150 && y < 234) {
      // Example coordinates for Illinois (IL)
      setHoveredState("Illinois");
    } else if (x > 498 && x < 547 && y > 90 && y < 154) {
      // Example coordinates for Michigan (MI)
      setHoveredState("Michigan");
    } else if (x > 499 && x < 525 && y > 157 && y < 217) {
      // Example coordinates for Indiana (IN)
      setHoveredState("Indiana");
    } // Kentucky (KY)
    else if (x > 447 && x < 565 && y > 199 && y < 240) {
      setHoveredState("Kentucky");
    }

    // Tennessee (TN)
    else if (x > 465 && x < 570 && y > 244 && y < 270) {
      setHoveredState("Tennessee");
    }

    // Mississippi (MS)
    else if (x > 449 && x < 487 && y > 277 && y < 350) {
      setHoveredState("Mississippi");
    }

    // Alabama (AL)
    else if (x > 490 && x < 533 && y > 278 && y < 349) {
      setHoveredState("Alabama");
    }

    // Ohio (OH)
    else if (x > 527 && x < 579 && y > 157 && y < 205) {
      setHoveredState("Ohio");
    }
    // New York (NY)
    else if (x > 595 && x < 665 && y > 80 && y < 132) {
      setHoveredState("New York");
    }

    // Pennsylvania (PA)
    else if (x > 583 && x < 651 && y > 139 && y < 171) {
      setHoveredState("Pennsylvania");
    }

    // West Virginia (WV)
    else if (x > 563 && x < 608 && y > 177 && y < 220) {
      setHoveredState("West Virginia");
    }

    // Virginia (VA)
    else if (x > 565 && x < 646 && y > 186 && y < 229) {
      setHoveredState("Virginia");
    }

    // North Carolina (NC)
    else if (x > 565 && x < 662 && y > 233 && y < 272) {
      setHoveredState("North Carolina");
    } // South Carolina (SC)
    else if (x > 562 && x < 623 && y > 262 && y < 309) {
      setHoveredState("South Carolina");
    }

    // Georgia (GA)
    else if (x > 536 && x < 599 && y > 272 && y < 340) {
      setHoveredState("Georgia");
    }

    // Florida (FL)
    else if (x > 509 && x < 615 && y > 342 && y < 440) {
      setHoveredState("Florida");
    } // Maine (ME)
    else if (x > 686 && x < 735 && y > 27 && y < 83) {
      setHoveredState("Maine");
    }

    // Vermont (VT)
    else if (x > 662 && x < 676 && y > 74 && y < 110) {
      setHoveredState("Vermont");
    }

    // New Hampshire (NH)
    else if (x > 678 && x < 690 && y > 69 && y < 107) {
      setHoveredState("New Hampshire");
    }

    // Massachusetts (MA)
    else if (x > 669 && x < 711 && y > 111 && y < 120) {
      setHoveredState("Massachusetts");
    }

    // Connecticut (CT)
    else if (x > 670 && x < 689 && y > 124 && y < 133) {
      setHoveredState("Connecticut");
    } // Rhode Island (RI)
    else if (x > 692 && x < 701 && y > 122 && y < 130) {
      setHoveredState("Rhode Island");
    }

    // New Jersey (NJ)
    else if (x > 654 && x < 669 && y > 143 && y < 179) {
      setHoveredState("New Jersey");
    }

    // Delaware (DE)
    else if (x > 652 && x < 657 && y > 170 && y < 190) {
      setHoveredState("Delaware");
    }

    // Maryland (MD)
    else if (x > 622 && x < 645 && y > 174 && y < 197) {
      setHoveredState("Maryland");
    } else {
      setHoveredState(null);
    }
  };

  const handleStateClick = () => {
    if (hoveredState) {
      setSelectedState(hoveredState); // Set the clicked state as selected
      clearTimeout(lockTimeout); // Clear any previous timeout if there was one

      // Reset the selected state after 5 seconds if Register Now isn't clicked
      const timeout = setTimeout(() => {
        setSelectedState(null); // Allow hover info to show again
      }, 5000);
      setLockTimeout(timeout);
    }
  };

  // Clear the timeout if Register Now is clicked
  const handleRegisterClick = () => {
    clearTimeout(lockTimeout); // Clear the reset timer
    if (selectedState) {
      const homeselectedState = selectedState;
      // Navigate to /business-name page and pass the selected state
      navigate("/business-name", { state: { homeselectedState: selectedState } });
      console.log(":::: selected Home State ::::::", homeselectedState)
      // console.log(":::: selected Home State ::::::", selectedState)
    } else {
      alert("Please select a state to register!");
    }
  };

  return (
    <div className="register-us-section">
      <h2 className="us-heading">Now You Can Register All Over U.S</h2>
      <div className="content-container">
        {/* State Info Section with Button */}
        <div className="state-info-container">
          <div className="state-info">
            {hoveredState && !selectedState ? ( // Show hover info if no state is selected
              <div className="state-card">
                <h3>{hoveredState}</h3>
                {hoveredState === "California" && (
                  <>
                    <p>
                      LLC Fee: <strong>$75</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$105</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Nevada" && (
                  <>
                    <p>
                      LLC Fee: <strong>$436</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$744</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-7 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Oregon" && (
                  <>
                    <p>
                      LLC Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Idaho" && (
                  <>
                    <p>
                      LLC Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-9 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Washington" && (
                  <>
                    <p>
                      LLC Fee: <strong>$230</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$230</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Utah" && (
                  <>
                    <p>
                      LLC Fee: <strong>$76</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$76</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Arizona" && (
                  <>
                    <p>
                      LLC Fee: <strong>$85</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$95</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Montana" && (
                  <>
                    <p>
                      LLC Fee: <strong>$35</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$70</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Wyoming" && (
                  <>
                    <p>
                      LLC Fee: <strong>$104</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Colorado" && (
                  <>
                    <p>
                      LLC Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "New Mexico" && (
                  <>
                    <p>
                      LLC Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Alaska" && (
                  <>
                    <p>
                      LLC Fee: <strong>$250</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-10 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$250</strong>
                    </p>
                    <p>
                      Processing Time: <strong>8-12 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Hawaii" && (
                  <>
                    <p>
                      LLC Fee: <strong>$51</strong>
                    </p>
                    <p>
                      Processing Time: <strong>8-10 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>9-12 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "North Dakota" && (
                  <>
                    <p>
                      LLC Fee: <strong>$135</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-9 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "South Dakota" && (
                  <>
                    <p>
                      LLC Fee: <strong>$150</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$150</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Nebraska" && (
                  <>
                    <p>
                      LLC Fee: <strong>$105</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$65</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

{hoveredState === "Kansas" && (
  <>
    <p>
      LLC Fee: <strong>$166</strong>
    </p>
    <p>
      Processing Time: <strong>5-8 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$90</strong>
    </p>
    <p>
      Processing Time: <strong>6-9 Business days</strong>
    </p>
  </>
)}

                {hoveredState === "Oklahoma" && (
                  <>
                    <p>
                      LLC Fee: <strong>$104</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$104</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Texas" && (
                  <>
                    <p>
                      LLC Fee: <strong>$308</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$308</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-10 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Minnesota" && (
                  <>
                    <p>
                      LLC Fee: <strong>$155</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$180</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Iowa" && (
                  <>
                    <p>
                      LLC Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Missouri" && (
                  <>
                    <p>
                      LLC Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$58</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Arkansas" && (
                  <>
                    <p>
                      LLC Fee: <strong>$45</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$45</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Louisiana" && (
                  <>
                    <p>
                      LLC Fee: <strong>$105</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$75</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Wisconsin" && (
                  <>
                    <p>
                      LLC Fee: <strong>$130</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Illinois" && (
                  <>
                    <p>
                      LLC Fee: <strong>$154</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$181</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Michigan" && (
                  <>
                    <p>
                      LLC Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$60</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Indiana" && (  
                  <>
                    <p>
                      LLC Fee: <strong>$97</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$97</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Kentucky" && (
                  <>
                    <p>
                      LLC Fee: <strong>$40</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Tennessee" && (
                  <>
                    <p>
                      LLC Fee: <strong>$307</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Mississippi" && (
                  <>
                    <p>
                      LLC Fee: <strong>$53</strong>
                    </p>
                    <p>
                      Processing Time: <strong>2-4 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Alabama" && (
                  <>
                    <p>
                      LLC Fee: <strong>$236</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$236</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Ohio" && (
                  <>
                    <p>
                      LLC Fee: <strong>$99</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "New York" && (
                  <>
                    <p>
                      LLC Fee: <strong>$210</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$135</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Pennsylvania" && (
                  <>
                    <p>
                      LLC Fee: <strong>$125</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$125</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "West Virginia" && (
                  <>
                    <p>
                      LLC Fee: <strong>$130</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$65</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Virginia" && (
                  <>
                    <p>
                      LLC Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$75</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "North Carolina" && (
                  <>
                    <p>
                      LLC Fee: <strong>$128</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$125</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "South Carolina" && (
                  <>
                    <p>
                      LLC Fee: <strong>$131</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$535</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Georgia" && (
                  <>
                    <p>
                      LLC Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$140</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Florida" && (
                  <>
                    <p>
                      LLC Fee: <strong>$155</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$79</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Maine" && (
                  <>
                    <p>
                      LLC Fee: <strong>$175</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$145</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-9 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Vermont" && (
                  <>
                    <p>
                      LLC Fee: <strong>$125</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$75</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "New Hampshire" && (
                  <>
                    <p>
                      LLC Fee: <strong>$102</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$105</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Massachusetts" && (
                  <>
                    <p>
                      LLC Fee: <strong>$520</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-9 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$265</strong>
                    </p>
                    <p>
                      Processing Time: <strong>8-10 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Connecticut" && (
                  <>
                    <p>
                      LLC Fee: <strong>$120</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$250</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}
                {hoveredState === "Rhode Island" && (
                  <>
                    <p>
                      LLC Fee: <strong>$156</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$238</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "New Jersey" && (
                  <>
                    <p>
                      LLC Fee: <strong>$129</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$129</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-9 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Delaware" && (
                  <>
                    <p>
                      LLC Fee: <strong>$140</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$139</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-7 Business days</strong>
                    </p>
                  </>
                )}

                {hoveredState === "Maryland" && (
                  <>
                    <p>
                      LLC Fee: <strong>$156</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$217</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}
              </div>
            ) : selectedState ? ( // Show selected state info
              <div className="state-card">
                <h3>{selectedState}</h3>
                {selectedState === "California" && (
  <>
    <p>
      LLC Fee: <strong>$75</strong>
    </p>
    <p>
      Processing Time: <strong>5-7 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$105</strong>
    </p>
    <p>
      Processing Time: <strong>6-8 Business days</strong>
    </p>
  </>
)}
{selectedState === "Nevada" && (
  <>
    <p>
      LLC Fee: <strong>$436</strong>
    </p>
    <p>
      Processing Time: <strong>3-5 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$744</strong>
    </p>
    <p>
      Processing Time: <strong>4-7 Business days</strong>
    </p>
  </>
)}
{selectedState === "Oregon" && (
  <>
    <p>
      LLC Fee: <strong>$100</strong>
    </p>
    <p>
      Processing Time: <strong>4-6 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$100</strong>
    </p>
    <p>
      Processing Time: <strong>5-7 Business days</strong>
    </p>
  </>
)}
{selectedState === "Idaho" && (
  <>
    <p>
      LLC Fee: <strong>$100</strong>
    </p>
    <p>
      Processing Time: <strong>6-8 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$100</strong>
    </p>
    <p>
      Processing Time: <strong>7-9 Business days</strong>
    </p>
  </>
)}
{selectedState === "Washington" && (
  <>
    <p>
      LLC Fee: <strong>$230</strong>
    </p>
    <p>
      Processing Time: <strong>4-6 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$230</strong>
    </p>
    <p>
      Processing Time: <strong>5-7 Business days</strong>
    </p>
  </>
)}
{selectedState === "Utah" && (
  <>
    <p>
      LLC Fee: <strong>$76</strong>
    </p>
    <p>
      Processing Time: <strong>4-6 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$76</strong>
    </p>
    <p>
      Processing Time: <strong>5-7 Business days</strong>
    </p>
  </>
)}
{selectedState === "Arizona" && (
  <>
    <p>
      LLC Fee: <strong>$85</strong>
    </p>
    <p>
      Processing Time: <strong>5-6 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$95</strong>
    </p>
    <p>
      Processing Time: <strong>6-8 Business days</strong>
    </p>
  </>
)}
{selectedState === "Montana" && (
  <>
    <p>
      LLC Fee: <strong>$35</strong>
    </p>
    <p>
      Processing Time: <strong>4-6 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$70</strong>
    </p>
    <p>
      Processing Time: <strong>5-7 Business days</strong>
    </p>
  </>
)}
{selectedState === "Wyoming" && (
  <>
    <p>
      LLC Fee: <strong>$104</strong>
    </p>
    <p>
      Processing Time: <strong>3-5 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$100</strong>
    </p>
    <p>
      Processing Time: <strong>4-6 Business days</strong>
    </p>
  </>
)}
{selectedState === "Colorado" && (
  <>
    <p>
      LLC Fee: <strong>$50</strong>
    </p>
    <p>
      Processing Time: <strong>5-7 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$50</strong>
    </p>
    <p>
      Processing Time: <strong>6-8 Business days</strong>
    </p>
  </>
)}
{selectedState === "New Mexico" && (
  <>
    <p>
      LLC Fee: <strong>$50</strong>
    </p>
    <p>
      Processing Time: <strong>3-5 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$100</strong>
    </p>
    <p>
      Processing Time: <strong>4-6 Business days</strong>
    </p>
  </>
)}
{selectedState === "Alaska" && (
  <>
    <p>
      LLC Fee: <strong>$250</strong>
    </p>
    <p>
      Processing Time: <strong>7-10 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$250</strong>
    </p>
    <p>
      Processing Time: <strong>8-12 Business days</strong>
    </p>
  </>
)}
{selectedState === "Hawaii" && (
  <>
    <p>
      LLC Fee: <strong>$51</strong>
    </p>
    <p>
      Processing Time: <strong>8-10 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$50</strong>
    </p>
    <p>
      Processing Time: <strong>9-12 Business days</strong>
    </p>
  </>
)}
{selectedState === "North Dakota" && (
  <>
    <p>
      LLC Fee: <strong>$135</strong>
    </p>
    <p>
      Processing Time: <strong>6-8 Business days</strong>
    </p>
    <p>
      Corp. Fee: <strong>$100</strong>
    </p>
    <p>
      Processing Time: <strong>7-9 Business days</strong>
    </p>
  </>
)}


{selectedState === "South Dakota" && (
                  <>
                    <p>
                      LLC Fee: <strong>$150</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$150</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Nebraska" && (
                  <>
                    <p>
                      LLC Fee: <strong>$105</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$65</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Kansas" && (
                  <>
                    <p>
                      LLC Fee: <strong>$166</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-8 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$90</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-9 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Oklahoma" && (
                  <>
                    <p>
                      LLC Fee: <strong>$104</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$104</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Texas" && (
                  <>
                    <p>
                      LLC Fee: <strong>$308</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$308</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-10 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Minnesota" && (
                  <>
                    <p>
                      LLC Fee: <strong>$155</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$180</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Iowa" && (
                  <>
                    <p>
                      LLC Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Missouri" && (
                  <>
                    <p>
                      LLC Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$58</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Arkansas" && (
                  <>
                    <p>
                      LLC Fee: <strong>$45</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$45</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Louisiana" && (
                  <>
                    <p>
                      LLC Fee: <strong>$105</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$75</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Wisconsin" && (
                  <>
                    <p>
                      LLC Fee: <strong>$130</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Illinois" && (
                  <>
                    <p>
                      LLC Fee: <strong>$154</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$181</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Michigan" && (
                  <>
                    <p>
                      LLC Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$60</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Indiana" && (
                  <>
                    <p>
                      LLC Fee: <strong>$97</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$97</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Kentucky" && (
                  <>
                    <p>
                      LLC Fee: <strong>$40</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}


{selectedState === "Tennessee" && (
                  <>
                    <p>
                      LLC Fee: <strong>$307</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Mississippi" && (
                  <>
                    <p>
                      LLC Fee: <strong>$53</strong>
                    </p>
                    <p>
                      Processing Time: <strong>2-4 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$50</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Alabama" && (
                  <>
                    <p>
                      LLC Fee: <strong>$236</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$236</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Ohio" && (
                  <>
                    <p>
                      LLC Fee: <strong>$99</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "New York" && (
                  <>
                    <p>
                      LLC Fee: <strong>$210</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$135</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Pennsylvania" && (
                  <>
                    <p>
                      LLC Fee: <strong>$125</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$125</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "West Virginia" && (
                  <>
                    <p>
                      LLC Fee: <strong>$130</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$65</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Virginia" && (
                  <>
                    <p>
                      LLC Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$75</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "North Carolina" && (
                  <>
                    <p>
                      LLC Fee: <strong>$128</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$125</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}
                {selectedState === "South Carolina" && (
                  <>
                    <p>
                      LLC Fee: <strong>$131</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$535</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Georgia" && (
                  <>
                    <p>
                      LLC Fee: <strong>$100</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$140</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Florida" && (
                  <>
                    <p>
                      LLC Fee: <strong>$155</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$79</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Maine" && (
                  <>
                    <p>
                      LLC Fee: <strong>$175</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$145</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-9 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Vermont" && (
                  <>
                    <p>
                      LLC Fee: <strong>$125</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$75</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "New Hampshire" && (
                  <>
                    <p>
                      LLC Fee: <strong>$102</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$105</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Massachusetts" && (
                  <>
                    <p>
                      LLC Fee: <strong>$520</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-9 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$265</strong>
                    </p>
                    <p>
                      Processing Time: <strong>8-10 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Connecticut" && (
                  <>
                    <p>
                      LLC Fee: <strong>$120</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$250</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}
                {selectedState === "Rhode Island" && (
                  <>
                    <p>
                      LLC Fee: <strong>$156</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-6 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$238</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "New Jersey" && (
                  <>
                    <p>
                      LLC Fee: <strong>$129</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$129</strong>
                    </p>
                    <p>
                      Processing Time: <strong>7-9 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Delaware" && (
                  <>
                    <p>
                      LLC Fee: <strong>$140</strong>
                    </p>
                    <p>
                      Processing Time: <strong>3-5 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$139</strong>
                    </p>
                    <p>
                      Processing Time: <strong>4-7 Business days</strong>
                    </p>
                  </>
                )}

                {selectedState === "Maryland" && (
                  <>
                    <p>
                      LLC Fee: <strong>$156</strong>
                    </p>
                    <p>
                      Processing Time: <strong>5-7 Business days</strong>
                    </p>
                    <p>
                      Corp. Fee: <strong>$217</strong>
                    </p>
                    <p>
                      Processing Time: <strong>6-8 Business days</strong>
                    </p>
                  </>
                )}

              </div>
            ) : (
              <div className="state-card">
                <h3>Hover over a state to see details</h3>
              </div>
            )}
          </div>

          {/* Register Now Button - Always Visible */}
          <div className="register-button-container">
            <button className="register-button" onClick={handleRegisterClick}>
              {selectedState
                ? `REGISTER NOW for ${selectedState}`
                : "REGISTER NOW"}
            </button>
          </div>
        </div>

        {/* Map Section */}
        <div className="us-map">
          <img
            src={mapImage}
            alt="US Map"
            className="state-image"
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setHoveredState(null)}
            onClick={handleStateClick} // Add click event to select a state
          />
        </div>
      </div>
    </div>
  );
};

export default USMapHover;
