import React from 'react';
import a44 from '../../assets/images/a44.png'; // Import the image for the section
import './YearlyReportSection.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const YearlyReportSection = () => {
  const navigate = useNavigate(); // Hook to navigate between pages

  const handleRegisterNowClick = () => {
    navigate('/business-name'); // Navigates to the Business Name page or any other page
  };
  return (
    <div className="yearlyreportsection-section"> {/* Updated class name */}
      <div className="yearlyreportsection-content-wrapper"> {/* Updated class name */}
        <div className="yearlyreportsection-description"> {/* Updated class name */}
          <h2 className="yearlyreportsection-heading">Incorporating a Business Made Easy</h2> {/* Updated class name */}
          <p className="yearlyreportsection-subheading">Launch a corporation on the go. Let’s Build Something Iconic Together!</p> {/* Updated class name */}
          <button onClick={handleRegisterNowClick} className="yearlyreportsection-start-button"> {/* Updated class name */}
            Start My Corporation Now
          </button>
        </div>
        <img src={a44} alt="Yearly Report Illustration" className="yearlyreportsection-image" /> {/* Updated class name */}
      </div>
    </div>
  );
};

export default YearlyReportSection;
