import React, { useState } from 'react';
import './RegisterLLC.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const RegisterLLC = () => {
  const navigate = useNavigate(); // Hook to navigate between pages

  // State to store input values
  const [businessStructure, setBusinessStructure] = useState('');
  const [formationState, setFormationState] = useState('');

 const handleApplyNowClick = () => {
   navigate('/business-name', {
     state: { businessStructure, formationState }
   });
 };

  return (
    <div className="register-llc-section">
      <h2 className="awregisterheading">Avoid settling for less expensive options. Get onboard with our registered agent service online now.
      </h2>
      <p className="aaregister-subheading">Concentrate on your business while we focus on keeping compliant.

      </p>
      <div className="form-group">
      <select
          className="form-control"
          type="text"
          placeholder="Select a Business Structure"
          value={businessStructure}
          onChange={(e) => setBusinessStructure(e.target.value)}
        >
          <option value="" disabled hidden>-- Select Business Structure --</option>
          <option value="LLC">LLC</option>
          <option value="Corporation">Corporation</option>
        </select>
        <select
            className="form-control"
            value={formationState}
          onChange={(e) => setFormationState(e.target.value)}
          >
          <option value="" disabled hidden>-- Select State or Territory --</option>
          <option value="Alabama">Alabama</option>
          <option value="Alaska">Alaska</option>
          <option value="Arizona">Arizona</option>
          <option value="Arkansas">Arkansas</option>
          <option value="California">California</option>
          <option value="Colorado">Colorado</option>
          <option value="Connecticut">Connecticut</option>
          <option value="Delaware">Delaware</option>
          <option value="Florida">Florida</option>
          <option value="Georgia">Georgia</option>
          <option value="Hawaii">Hawaii</option>
          <option value="Idaho">Idaho</option>
          <option value="Illinois">Illinois</option>
          <option value="Indiana">Indiana</option>
          <option value="Iowa">Iowa</option>
          <option value="Kansas">Kansas</option>
          <option value="Kentucky">Kentucky</option>
          <option value="Louisiana">Louisiana</option>
          <option value="Maine">Maine</option>
          <option value="Maryland">Maryland</option>
          <option value="Massachusetts">Massachusetts</option>
          <option value="Michigan">Michigan</option>
          <option value="Minnesota">Minnesota</option>
          <option value="Mississippi">Mississippi</option>
          <option value="Missouri">Missouri</option>
          <option value="Montana">Montana</option>
          <option value="Nebraska">Nebraska</option>
          <option value="Nevada">Nevada</option>
          <option value="New Hampshire">New Hampshire</option>
          <option value="New Jersey">New Jersey</option>
          <option value="New Mexico">New Mexico</option>
          <option value="New York">New York</option>
          <option value="North Carolina">North Carolina</option>
          <option value="North Dakota">North Dakota</option>
          <option value="Ohio">Ohio</option>
          <option value="Oklahoma">Oklahoma</option>
          <option value="Oregon">Oregon</option>
          <option value="Pennsylvania">Pennsylvania</option>
          <option value="Rhode Island">Rhode Island</option>
          <option value="South Carolina">South Carolina</option>
          <option value="South Dakota">South Dakota</option>
          <option value="Tennessee">Tennessee</option>
          <option value="Texas">Texas</option>
          <option value="Utah">Utah</option>
          <option value="Vermont">Vermont</option>
          <option value="Virginia">Virginia</option>
          <option value="Washington">Washington</option>
          <option value="West Virginia">West Virginia</option>
          <option value="Wisconsin">Wisconsin</option>
          <option value="Wyoming">Wyoming</option>
          <option value="District of Columbia">District of Columbia (D.C.)</option>
          </select>
        {/* <input
          className="form-control"
          type="text"
          placeholder="Enter State For Your Formation"
          value={formationState}
          onChange={(e) => setFormationState(e.target.value)}
        /> */}
        
        <button onClick={handleApplyNowClick} className="apply-button">Apply Now</button> {/* Apply Now button with click handler */}
      </div>
    </div>
  );
};

export default RegisterLLC;
