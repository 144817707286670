import React, { useState } from 'react';
import './FAQComponent.css'; // Updated CSS file reference
import questionIcon from '../../assets/images/question.png'; // Import the question.png image

const frequentlyAskedQuestions = [
  {
    query: "WHAT IS AN LLC?",
    response: "LLC stands for Limited Liability Company; it is a flexible structure of business that offers liability protection for the owners. It also allows quick pass-through taxation, coupled with the features of corporations and partnerships.",
  },
  {
    query: "HOW DO I FORM AN LLC?",
    response: "Take a step and grow your dream into reality. At BusinessOmni, we are a one-stop platform for forming your LLC. However, the basic steps are finding a suitable name for your LLC, filling out documents, receiving approval, appointing a registered agent, preparing an operating agreement, and filing articles of organization with the Secretary of the State you are forming an LLC.",
  },
  {
    query: "WHAT ARE THE BENEFITS OF FORMING AN LLC?",
    response: "Forming a Limited Liability Company (LLC) has numerous benefits for entrepreneurs and small businesses, some of the flourishing benefits are, limited liability protection, pass-through taxation, flexible management structure, very few formalities, better profit distribution, access to business loans and credit, and personal privacy.",
  },
  {
    query: "HOW IS AN LLC TAXED?",
    response: "The LLC taxes are primarily based on their structure. A single-member LLC is taxed as a sole proprietorship, while a multi-member LLC is taxed as a partnership. However, both entities are “pass-through,” meaning profits are taxed on personal returns.",
  },
];

const FAQComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQuestions = frequentlyAskedQuestions.filter(faq =>
    faq.query.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faq-section-wrapper">
      <h2 className="faq-title">Frequently Asked Questions About LLC Formation</h2>
      <div className="faq-search-wrapper">
        <input
          type="text"
          className="faq-input-field"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search FAQs..."
        />
        <img src={questionIcon} alt="Search Icon" className="faq-image-icon" /> {/* Use the question.png image */}
      </div>

      <div className="faq-list-container">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((faq, index) => (
            <div className="faq-item-wrapper" key={index}>
              <h3 className="faq-query">{faq.query}</h3>
              <p className="faq-response">{faq.response}</p>
            </div>
          ))
        ) : (
          <p>No FAQs match your search.</p>
        )}
      </div>
    </div>
  );
};

export default FAQComponent;
