import React from 'react';
import './Beneficiary.css'; // Use a different CSS for Beneficiary Ownership
import serviceImage from '../../assets/images/10258681_4384874-removebg-preview.png'; 
import PeacefulMindSection from './PeacefulMindSection';  // Correct the import path
import YearlyReportSection from './YearlyReportSection';  // Correct the import path
import HowServiceWorks from './HowServiceWorks';  // Correct the import path
import RegisterLLC from './RegisterLLC';  // Correct the import path
import FAQSection from './FAQSection';  // Correct the import path
import AnnualReportSection from './AnnualReportSection';  // Correct the import path

import { useNavigate } from 'react-router-dom';

const Beneficiary = () => {
  const navigate = useNavigate(); 

  const handleRegisterNowClick = () => {
    navigate('/business-name');
  };
  const handleReportNowClick = () => {
    navigate('/enquire-form');
  };

  return (
    <div className="servicespage">
      <section className="servicessection">
        <div className="breadcrumbs">
          <span className="whitetext">Business OMNI</span>
          <span>&nbsp;&gt;&nbsp;</span>
          <span className="blacktext">Beneficiary Ownership</span>
        </div>

        <div className="servicescontent">
          <h1>Ensure Transparency With Beneficial Ownership Information Report</h1>
          <div className="servicescontentwrapper">
            <div className="servicesdescription">
              <p className="descriptiontext">
              The beneficial ownership information reports are mandatory starting from January <strong>1, 2024.</strong> BOI will identify individuals with significant control over a business. With BusinessOmni, meet regulatory requirements and ensure transparency while protecting your business from potential compliance issues.  Stay confident; you are fulfilling new federal rules on time with our support. Let us handle how to file a beneficial ownership report for your business.
              </p>
              <button onClick={handleRegisterNowClick} className="servicebutton primary">Register My Business First</button>
              <button onClick={handleReportNowClick} className="servicebutton secondary">File my Business Ownership Information</button>
              <p className="servicesfootertext">
              File Beneficial Ownership Report for <strong>$99</strong>  with us!
              </p>
            </div>
            <img src={serviceImage} alt="Service Illustration" className="serviceimage" />
          </div>
        </div>
        <div>
          <PeacefulMindSection />
          <YearlyReportSection />
          <HowServiceWorks />
          <RegisterLLC />
          <FAQSection />
          {/* <AnnualReportSection /> */}
        </div>
      </section>
    </div>
  );
};

export default Beneficiary;
