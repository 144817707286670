import React from 'react';
import './RegisteredAgent.css'; // Use a different CSS for Registered Agent
import serviceImage from '../../assets/images/Register Agent.png'; 
import PeacefulMindSection from './PeacefulMindSection';  // Correct the import path
import YearlyReportSection from './YearlyReportSection';  // Correct the import path
import HowServiceWorks from './HowServiceWorks';  // Correct the import path
import RegisterLLC from './RegisterLLC';  // Correct the import path
import FAQSection from './FAQSection';  // Correct the import path
import AnnualReportSection from './AnnualReportSection';

import { useNavigate } from 'react-router-dom';

const RegisteredAgent = () => {
  const navigate = useNavigate(); 

  const handleRegisterNowClick = () => {
    navigate('/enquire-form');
  };

  return (
    <div className="services-pagerr">
      <section className="services-sectionrr">
        <div className="breadcrumbsrr">
          <span className="white-text">Business OMNI</span>
          <span>&nbsp;&gt;&nbsp;</span>
          <span className="black-text">Registered Agent</span>
        </div>

        <div className="services-contentrr">
          <h1>GET A REGISTERED AGENT SERVICE FOR YOUR BUSINESS</h1>
          <p className="subheadingrr text-center">Find registered agent for LLC & Work effortlessly with firm support
          </p>
          <div className="services-content-wrapperrr">
            <div className="services-descriptionrr">
              <p className="description-textrr">
              For corporation set up and LLC creation, a registered agent is required by law. BusinessOmni provides reliable and trusted services, ensuring you get all important government notifications and legal documents. Stay organized with the help of our proficient registered agents and run your business with ease.
              </p>
              <button onClick={handleRegisterNowClick} className="service-buttonrr primaryy">SECURE A REGISTERED AGENT TODAY!</button>
              <button onClick={handleRegisterNowClick} className="service-buttonrr secondaryy">CLICK HERE TO MANAGE YOUR AGENT</button>
              <p className="services-footer-textrr">
              Registered agent services cost reduced to <strong>$89/year</strong>
              </p>
            </div>
            <img src={serviceImage} alt="Service Illustration" className="service-imagerr" />
          </div>
        </div>
        <div>
          <PeacefulMindSection />
          <YearlyReportSection />
          <HowServiceWorks />
          <RegisterLLC />
          <FAQSection />
          {/* <AnnualReportSection /> */}
        </div>
      </section>
    </div>
  );
};

export default RegisteredAgent;
