import React from 'react';
import a44 from '../../assets/images/a44.png'; // Import the image for the section
import './YearlyReportSection.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const YearlyReportSection = () => {
  const navigate = useNavigate(); // Hook to navigate between pages

  const handleRegisterNowClick = () => {
    navigate('/enquire-form');
  };
  return (
    <div className="yearlyreportsection-section">
      <div className="yearlyreportsection-content-wrapper">
        <div className="yearlyreportsection-description">
          <h2 className="yearlyreportsection-heading">Ready to Simplify Your Compliance? </h2>
          <p className="yearlyreportsection-subheading">Let us handle your registered agent responsibilities so you can focus on running your business.</p>
          <button onClick={handleRegisterNowClick} className="yearlyreportsection-start-button">Secure a Registered Agent Today!</button>
          <p className="yearlyreportsection-footer-text">
          Unveil Iconic LLC registered agent service
          </p>
        </div>
        <img src={a44} alt="Yearly Report Illustration" className="yearlyreportsection-image" />
      </div>
    </div>
  );
};

export default YearlyReportSection;
