import React, { useState } from 'react';
import './FAQSectionss.css'; // Updated CSS file reference
import questionIcon from '../../assets/images/question.png'; // Import the question.png image

const frequentlyAskedQuestions = [
  {
    query: "WHAT IS LLC?",
    response: "A Limited Liability Company (LLC) is a pliant business structure that combines the liability protection of a corporation with the tax benefits of a partnership. In an LLC, the owners who are members are not personally liable for any business debts, making it an attractive choice for startups and small businesses.",
  },
  {
    query: "HOW DO I FORM AN LLC?",
    response: "These are the steps to form an LLC: choose a business name, file an article of organization with your state, appoint a registered agent, and create an operating agreement. After filing, you can obtain the necessary licenses and file an EIN with the IRS.  ",
  },
  {
    query: "WHAT IS A REGISTERED AGENT?",
    response: "A registered agent is a person or service responsible for receiving legal documents on behalf of the business. A registered agent ensures that official correspondence, such as lawsuit notices, summons are properly handled and forwarded to the company.",
  },
  {
    query: "WHAT IS THE DIFFERENCE BETWEEN S-CORP AND C-CORP?",
    response: (
      <>
        S-Corp is a tax status election, not an entity structure. It permits business owners to pay self-employment tax like social security and Medicare on their salary income but not on the remaining earnings distributed to them as a portion of their firm.
        <br />
        C-Corp is a formal business structure in which you pay corporate taxes separately. Also, the shareholders are taxed on dividends.
      </>
    ),
  },
];

const FAQComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQuestions = frequentlyAskedQuestions.filter(faq =>
    faq.query.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faq-section-wrapperr">
      <h2 className="faq-title">Frequently Asked Question
      </h2>
      <div className="faq-search-wrapper">
        <input
          type="text"
          className="faq-input-field"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search FAQs..."
        />
        <img src={questionIcon} alt="Search Icon" className="faq-image-icon" /> {/* Use the question.png image */}
      </div>

      <div className="faq-list-container">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((faq, index) => (
            <div className="faq-item-wrapper" key={index}>
              <h3 className="faq-query">{faq.query}</h3>
              <p className="faq-response">{faq.response}</p>
            </div>
          ))
        ) : (
          <p>No FAQs match your search.</p>
        )}
      </div>
    </div>
  );
};

export default FAQComponent;
