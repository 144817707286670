import React, { useState } from 'react';
import './LLC.css'; // Use the updated CSS
import serviceImage from '../../assets/images/LLC.png'; 
import PeacefulMindSection from './PeacefulMindSection';  // Correct the import path
import YearlyReportSection from './YearlyReportSection';  // Correct the import path
import HowServiceWorks from './HowServiceWorks';  // Correct the import path
import RegisterLLC from './RegisterLLC';  // Correct the import path
import FAQSection from './FAQSection';  // Correct the import path
import AnnualReportSection from './AnnualReportSection';

import { useNavigate } from 'react-router-dom';

const LLC = () => {
  const [businessStructure, setBusinessStructure] = useState("");
  const navigate = useNavigate(); 

  const handleRegisterNowClick = () => {
    navigate('/business-name', { state: { businessStructure: "LLC" } });
  };
  // const handleReportNowClick = () => {
  //   navigate('/enquire-form');
  // };


  return (
    <div className="LLC-page">
      <section className="LLC-section">
        <div className="breadcrumbs">
          <span className="white-text">Business OMNI</span>
          <span>&nbsp;&gt;&nbsp;</span>
          <span className="black-text">LLC</span>
        </div>

        <div className="LLC-content">
          <h1>LAUNCH YOUR LLC WITH BUSINESS OMNI</h1>
          <p className="subheading">Register your LLC & kickstart your entrepreneurial journey with our professional advice.</p>
          <div className="LLC-content-wrapper">
            <div className="LLC-description">
              <p className="description-text">
              With BusinessOmni, create a limited liability company (LLC) that will provide numerous benefits for your company. Small business owners can benefit from our cheapest LLC formation guide. You are at the right place. Whether you want guidance in creating an LLC or advice on your business, we are here to help you. 
              </p>
              <button onClick={handleRegisterNowClick} className="service-button primary">START YOUR NEW BUSINESS NOW</button>
              {/* <button onClick={handleReportNowClick} className="LLC-button secondary">CLICK HERE FOR LLC REPORT</button> */}
              <p className="LLC-footer-text">
                Form your LLC for <strong>$139</strong> plus state fees
              </p>
            </div>
            <img src={serviceImage} alt="Service Illustration" className="LLC-image" />
          </div>
        </div>
        <div>
          <PeacefulMindSection />
          <YearlyReportSection />
          <HowServiceWorks />
          <RegisterLLC />
          <FAQSection />
          {/* <AnnualReportSection/> */}
        </div>
      </section>
    </div>
  );
};

export default LLC;
