import React from 'react';
import A11 from '../../assets/images/Improve Investor Confidence.png'; // Import icons
import A33 from '../../assets/images/enhance transparency.png';
import A22 from '../../assets/images/regulatory-compliance (1).png';
import './UniquePeacefulMindSectionS.css'; // Updated CSS file with unique names

const UniquePeacefulMindSection = () => {
  return (
    <div className="uniquepeacefulsection">
      <h2 className="uniquepeacefulheading">Benefits of Business Ownership Information Reporting
      </h2>
      <br></br>
      <br></br>
      <div className="uniquepeacefulcontent">
        <div className="uniquepeacefulbox">
          <img src={A11} alt="Asset Protection Icon" className="uniquepeacefulicon" />
          <h3>Improve Investor Confidence 
          </h3>
          <p>
          Earning and maintaining investor’s confidence is necessary to keep the business running. Beneficial ownership filings build trust with investors. They showcase that your company operates with complete transparency, which can foster long-term partnerships. 
          </p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A33} alt="Tax Benefits Icon" className="uniquepeacefulicon rightalignedicon" />
          <h3>Enhanced Transparency
          </h3>
          <p>
          The business operations should be as clear as water so that people can better understand your company's vertical in a better way. The BOI report provides clear information about the key stakeholders, ensuring compliance and helping prevent fraud. It also protects your business integrity and complies with anti-money laundering laws.</p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A22} alt="Credibility Icon" className="uniquepeacefulicon" />
          <h3>Regulatory Compliance
          </h3>
          <p>
          BOI falls under the Corporate Transparency Act, a federal legislation to understand company ownership structures. It is always great that your business meets legal obligations, avoids uncertain fines and penalties, and positions you as a trustworthy company. Beneficial ownership filing is your way to grow. </p>
        </div>
      </div>
    </div>
  );
};

export default UniquePeacefulMindSection;
