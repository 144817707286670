import React from 'react';
import './StayOrg.css'; // Use a different CSS for Stay Organized
import serviceImage from '../../assets/images/12281499_4894123-removebg-preview.png'; 
import PeacefulMindSection from './PeacefulMindSection';  // Correct the import path
import YearlyReportSection from './YearlyReportSection';  // Correct the import path
import HowServiceWorks from './HowServiceWorks';  // Correct the import path
import RegisterLLC from './RegisterLLC';  // Correct the import path
import FAQSection from './FAQSection';  // Correct the import path
import AnnualReportSection from './AnnualReportSection';  // Correct the import path

import { useNavigate } from 'react-router-dom';

const StayOrg = () => {
  const navigate = useNavigate(); 

  const handleRegisterNowClick = () => {
    navigate('/business-name');
  };
  const handleReportNowClick = () => {
    navigate('/enquire-form');
  };

  return (
    <div className="servicespage">
      <section className="servicessection">
        <div className="abreadcrumbs">
          <span className="whitetext">Business OMNI</span>
          <span>&nbsp;&gt;&nbsp;</span>
          <span className="blacktext">Annual Report</span>
        </div>

        <div className="servicescontent">
          <h1>Stay Organized With Our Annual Report Services</h1>
          <div className="servicescontentwrapper">
            <div className="servicesdescription">
              <p className="adescriptiontext">
              To maintain the company's good standing, filling out annual reports is essential. Our annual report filing team ensures accurate and timely filing that keeps your company compliant. In most states, filing an annual report is necessary to avoid any kind of fine and penalties. This report shows the continued existence of a company. BusinessOmni has filed thousands of annual reports, and we are best at what we do. The filing consists of corporate information, cap tables, and ownership information, sometimes requiring sales information. 

              </p>
              <button onClick={handleRegisterNowClick} className="servicebutton primary">Register My Business First</button>
              <button onClick={handleReportNowClick} className="servicebutton secondary">File My Annual Report</button>
             
            </div>
            <img src={serviceImage} alt="Service Illustration" className="aserviceimage" />
          </div>
        </div>
        <div>
          <PeacefulMindSection />
          <YearlyReportSection />
          <HowServiceWorks />
          <RegisterLLC />
          <FAQSection />
          {/* <AnnualReportSection /> */}
        </div>
      </section>
    </div>
  );
};

export default StayOrg;
