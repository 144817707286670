import React, { useState } from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const videoIds = [
    "r0xOGG2C1eI",
    "uWQ_8CtvzoY",
    "PvmtnlPZYdQ"
  ];

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleNextVideo = () => {
    setCurrentVideoIndex((currentVideoIndex + 1) % videoIds.length);
    setIsPlaying(false);
  };

  const handlePrevVideo = () => {
    setCurrentVideoIndex(
      (currentVideoIndex - 1 + videoIds.length) % videoIds.length
    );
    setIsPlaying(false);
  };

  return (
    <div className="how-it-works-section">
      <div className="arrows-container">
        <button className="arrow left-arrow" onClick={handlePrevVideo}>
          &lt;
        </button>
        <button className="arrow right-arrow" onClick={handleNextVideo}>
          &gt;
        </button>
      </div>

      {!isPlaying && <div className="video-overlay" />}
      <div className="content">
        <h2 className="how-heading">How it works</h2>
        <p className="how-subheading">Be successful with our all-in-one platform and expert support.</p>
        {!isPlaying && (
          <button className="play-button" onClick={handlePlay}>
            Play Video
          </button>
        )}
      </div>

      {isPlaying ? (
        <iframe
          id="howItWorksVideo"
          className={`background-video ${isPlaying ? 'playing' : ''}`}
          src={`https://www.youtube.com/embed/${videoIds[currentVideoIndex]}?autoplay=1`}
          title="How it works video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ display: isPlaying ? 'block' : 'none' }}
        ></iframe>
      ) : (
        <img
          src={`https://img.youtube.com/vi/${videoIds[currentVideoIndex]}/maxresdefault.jpg`}
          alt="video thumbnail"
          className="background-video"
        />
      )}

      <div className="dots-container">
        {videoIds.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentVideoIndex ? 'active' : ''}`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
