import React from 'react';
import A11 from '../../assets/images/A11.png'; // Import icons
import A22 from '../../assets/images/A22.png';
import A33 from '../../assets/images/A33.png';
import './PeacefulMindSectiona.css';

const PeacefulMindSection = () => {
  return (
    <div className="peaceful-mind-section">
      <h2 className="peaceful-heading">PEACEFUL MIND REPORTS, ENSURED</h2>
      <div className="peaceful-content">
        <div className="peaceful-box">
          <img src={A11} alt="Tranquility of Mind Icon" className="peaceful-icon" />
          <h3>Tranquility of mind</h3>
          <p>
            To keep your business in good standing and your peace of mind, our staff assists in staying on top of state-mandated filings.
          </p>
        </div>
        <div className="peaceful-box">
          <img src={A33} alt="Time to Conduct Business Icon" className="peaceful-icon i" />
          <h3>More time to conduct business</h3>
          <p className='para'>
            The prerequisites for filing vary by state. We take care of all the paperwork so you can focus more on managing your company.
          </p>
        </div>
        <div className="peaceful-box">
          <img src={A22} alt="Verified Reliability Icon" className="peaceful-icon" />
          <h3>Verified reliability</h3>
          <p>
            If you miss a deadline, you risk paying fines or maybe losing your liability insurance. We always guarantee <strong style={{ fontFamily: "'Orbitron', 'Arial', sans-serif" }}>100%</strong> accuracy in our filings.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PeacefulMindSection;
