import React from 'react';
import A11 from '../../assets/images/A11.png'; // Import icons
import A22 from '../../assets/images/A22.png';
import A33 from '../../assets/images/A33.png';
import './PeacefulMindSectionn.css'; // Updated CSS file with new class names

const PeacefulMindSectionn = () => {
  return (
    // <div className="peaceful-mind-sectionn">
    //   <h2 className="peaceful-mind-sectionn-heading">
    //     How Does LLC Creation Help You?
    //   </h2>
    //   <div className="peaceful-mind-sectionn-content">
    //     <div className="peaceful-mind-sectionn-box">
    //       <img src={A11} alt="Asset Protection Icon" className="peaceful-mind-sectionn-icon" />
    //       <h3>Asset Protection</h3>
    //       <p>
    //         LLCs segregate your assets from the business, which is the main advantage of incorporating an LLC with us. LLC safeguards your savings if your company is sued or a loan default occurs. Our LLC registration process is simple and typically costs less than <strong>$300</strong>.
    //       </p>
    //     </div>
    //     <div className="peaceful-mind-sectionn-box">
    //       <img src={A33} alt="Tax Benefits Icon" className="peaceful-mind-sectionn-icon right-aligned-icon" />
    //       <h3>LLC Tax Benefits</h3>
    //       <p>
    //         Keep more savings with LLC tax benefits! Enjoy minimal taxation, flexibility in profits, and deductions on business expenses as you register your LLC. Unveil more types of LLC benefits with us.
    //       </p>
    //     </div>
    //     <div className="peaceful-mind-sectionn-box">
    //       <img src={A22} alt="Credibility Icon" className="peaceful-mind-sectionn-icon" />
    //       <h3>Enhanced Credibility & Simplified Paperwork</h3>
    //       <p>
    //         Our essential package offers the greatest compliance, growth, and protection features. We also help you increase your credibility and simplify the paperwork so that you can easily manage your administrative burden and make any decision.
    //       </p>
    //     </div>
    //   </div>
    //   <p className="peaceful-mind-sectionn-centered-cta">
    //     Get Set Go! Start Your LLC Now!
    //   </p>
    // </div>
    <div className="uniquepeacefulsection">
    <h2 className="uniquepeacefulheading"> How Does LLC Creation Help You ? </h2>
    <br></br>
    <br></br>
    <div className="uniquepeacefulcontent">
      <div className="uniquepeacefulbox">
        <img src={A11} alt="Asset Protection Icon" className="uniquepeacefulicon" />
        <h3>Asset Protection</h3>
        <p>
          LLCs segregate your assets from the business, which is the main advantage of incorporating an LLC with us. LLC safeguards your savings if your company is sued or a loan default occurs. Our LLC registration process is simple and typically costs less than <strong class="amulish-numbers">$300</strong>.
        </p>
      </div>
      <div className="uniquepeacefulbox">
        <img src={A33} alt="Tax Benefits Icon" className="uniquepeacefulicon rightalignedicon" />
        <h3>LLC Tax Benefits</h3>
        <p>
          Keep more savings with LLC tax benefits! Enjoy minimal taxation, flexibility in profits, and deductions on business expenses as you register your LLC. Unveil more types of LLC benefits with us.
        </p>
      </div>
      <div className="uniquepeacefulbox">
        <img src={A22} alt="Credibility Icon" className="uniquepeacefulicon" />
        <h3>Enhanced Credibility & Simplified Paperwork</h3>
        <p>
          Our essential package offers the greatest compliance, growth, and protection features. We also help you increase your credibility and simplify the paperwork so that you can easily manage your administrative burden and make any decision.
        </p>
      </div>
    </div>
      <p className="peaceful-mind-sectionn-centered-cta">
      Get Set Go! Start Your LLC Now!
    </p>
  </div>
  );

};

export default PeacefulMindSectionn;
