import React from 'react';
import './HowServiceWorks.css'; // Import the CSS file for styling

const HowServiceWorks = () => {
  return (
    <div className="howserviceworkssection">
      <h2 className="howserviceworksheadingg">Benefits of Choosing BusinessOmni for Corporation Set-Up?</h2>
      <br/>
      <br></br>
      <div className="howserviceworkscontent">
        <div className="howserviceworksboxx">
          <h2>Set in motion in a minute</h2>
          <p>Start a corporation online for your company by following the guidelines provided by our leading online business experts. Fill out a brief form about your business that takes a few minutes.</p>
        </div>
        
        <div className="howserviceworksboxx">
          <h2>Ensure Proper Set-Up
          </h2>
          <p>We give you detailed instructions on how to safeguard your new company. Our team expert team professionally prepares your INC formation documentation.</p>
        </div>
        
        <div className="howserviceworksboxx">
          <h2>Live <strong>24</strong>/ <strong>7</strong> Expert Assistance</h2>
          <p>Our network of seasoned experts is available to help you launch and expand. With individualized assistance from our experts, you can prevent mistakes, and we assure you of <strong>100%</strong> accuracy.</p>
        </div>
      </div>
    </div>
  );
};

export default HowServiceWorks;
