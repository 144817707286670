import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./RushPage.css";
import icon from "../../assets/images/icon7.png"; // Correct path to icon
import ProgressBar from "./ProgressBar"; // Import the ProgressBar component
const RushPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const llcstateFees = {
    Alaska: { fee: 250, name: "Alaska" },
    Alabama: { fee: 236, name: "Alabama" },
    Arkansas: { fee: 45, name: "Arkansas" },
    Arizona: { fee: 85, name: "Arizona" },
    California: { fee: 75, name: "California" },
    Colorado: { fee: 50, name: "Colorado" },
    Connecticut: { fee: 120, name: "Connecticut" },
    DistrictOfColumbia: { fee: 99, name: "District of Columbia" },
    Delaware: { fee: 140, name: "Delaware" },
    Florida: { fee: 155, name: "Florida" },
    Georgia: { fee: 100, name: "Georgia" },
    Hawaii: { fee: 51, name: "Hawaii" },
    Iowa: { fee: 50, name: "Iowa" },
    Idaho: { fee: 100, name: "Idaho" },
    Illinois: { fee: 154, name: "Illinois" },
    Indiana: { fee: 97, name: "Indiana" },
    Kansas: { fee: 166, name: "Kansas" },
    Kentucky: { fee: 40, name: "Kentucky" },
    Louisiana: { fee: 105, name: "Louisiana" },
    Massachusetts: { fee: 520, name: "Massachusetts" },
    Maryland: { fee: 156, name: "Maryland" },
    Maine: { fee: 175, name: "Maine" },
    Michigan: { fee: 50, name: "Michigan" },
    Minnesota: { fee: 155, name: "Minnesota" },
    Missouri: { fee: 50, name: "Missouri" },
    Mississippi: { fee: 53, name: "Mississippi" },
    Montana: { fee: 35, name: "Montana" },
    NorthCarolina: { fee: 128, name: "North Carolina" },
    NorthDakota: { fee: 135, name: "North Dakota" },
    Nebraska: { fee: 105, name: "Nebraska" },
    NewHampshire: { fee: 102, name: "New Hampshire" },
    NewJersey: { fee: 129, name: "New Jersey" },
    NewMexico: { fee: 50, name: "New Mexico" },
    Nevada: { fee: 436, name: "Nevada" },
    NewYork: { fee: 210, name: "New York" },
    Ohio: { fee: 99, name: "Ohio" },
    Oklahoma: { fee: 104, name: "Oklahoma" },
    Oregon: { fee: 100, name: "Oregon" },
    Pennsylvania: { fee: 125, name: "Pennsylvania" },
    RhodeIsland: { fee: 156, name: "Rhode Island" },
    SouthCarolina: { fee: 131, name: "South Carolina" },
    SouthDakota: { fee: 150, name: "South Dakota" },
    Tennessee: { fee: 307, name: "Tennessee" },
    Texas: { fee: 308, name: "Texas" },
    Utah: { fee: 76, name: "Utah" },
    Virginia: { fee: 100, name: "Virginia" },
    Vermont: { fee: 125, name: "Vermont" },
    Washington: { fee: 230, name: "Washington" },
    Wisconsin: { fee: 130, name: "Wisconsin" },
    WestVirginia: { fee: 130, name: "West Virginia" },
    Wyoming: { fee: 104, name: "Wyoming" },
  };

  const CorpstateFees = {
    Alaska: { fee: 250, name: "Alaska" },
    Alabama: { fee: 236, name: "Alabama" },
    Arkansas: { fee: 45, name: "Arkansas" },
    Arizona: { fee: 95, name: "Arizona" },
    California: { fee: 105, name: "California" },
    Colorado: { fee: 50, name: "Colorado" },
    Connecticut: { fee: 250, name: "Connecticut" },
    DistrictOfColumbia: { fee: 270, name: "District of Columbia" },
    Delaware: { fee: 139, name: "Delaware" },
    Florida: { fee: 79, name: "Florida" },
    Georgia: { fee: 140, name: "Georgia" },
    Hawaii: { fee: 50, name: "Hawaii" },
    Iowa: { fee: 50, name: "Iowa" },
    Idaho: { fee: 100, name: "Idaho" },
    Illinois: { fee: 281, name: "Illinois" },
    Indiana: { fee: 97, name: "Indiana" },
    Kansas: { fee: 90, name: "Kansas" },
    Kentucky: { fee: 50, name: "Kentucky" },
    Louisiana: { fee: 75, name: "Louisiana" },
    Massachusetts: { fee: 265, name: "Massachusetts" },
    Maryland: { fee: 217, name: "Maryland" },
    Maine: { fee: 145, name: "Maine" },
    Michigan: { fee: 60, name: "Michigan" },
    Minnesota: { fee: 180, name: "Minnesota" },
    Missouri: { fee: 58, name: "Missouri" },
    Mississippi: { fee: 50, name: "Mississippi" },
    Montana: { fee: 70, name: "Montana" },
    NorthCarolina: { fee: 125, name: "North Carolina" },
    NorthDakota: { fee: 100, name: "North Dakota" },
    Nebraska: { fee: 65, name: "Nebraska" },
    NewHampshire: { fee: 105, name: "New Hampshire" },
    NewJersey: { fee: 129, name: "New Jersey" },
    NewMexico: { fee: 100, name: "New Mexico" },
    Nevada: { fee: 744, name: "Nevada" },
    NewYork: { fee: 135, name: "New York" },
    Ohio: { fee: 100, name: "Ohio" },
    Oklahoma: { fee: 104, name: "Oklahoma" },
    Oregon: { fee: 100, name: "Oregon" },
    Pennsylvania: { fee: 125, name: "Pennsylvania" },
    RhodeIsland: { fee: 238, name: "Rhode Island" },
    SouthCarolina: { fee: 535, name: "South Carolina" },
    SouthDakota: { fee: 150, name: "South Dakota" },
    Tennessee: { fee: 100, name: "Tennessee" },
    Texas: { fee: 308, name: "Texas" },
    Utah: { fee: 76, name: "Utah" },
    Virginia: { fee: 75, name: "Virginia" },
    Vermont: { fee: 75, name: "Vermont" },
    Washington: { fee: 230, name: "Washington" },
    Wisconsin: { fee: 100, name: "Wisconsin" },
    WestVirginia: { fee: 65, name: "West Virginia" },
    Wyoming: { fee: 100, name: "Wyoming" },
  };

  // Destructure state safely with fallback values
  const selectedState = location.state?.selectedState || "CA";
  const selectedPackage =
    location.state?.selectedPackage || "No Package Selected";
  const packageValue = location.state?.packageValue ?? "Not Available";
  const { businessName } = location.state || {};
  const { businessActivity } = location.state || {};
  const { businessAddress } = location.state || {};
  const { selectedOwners } = location.state || {};
  const { fullName } = location.state || {};
  const { phone } = location.state || {};
  const { email } = location.state || {};
  const { businessStructure } = location.state || {};

  const ownersValue = selectedOwners?.replace(/<\/?[^>]+(>|$)/g, "");

  console.log(
    "Selected Package:",
    selectedPackage,
    "Package Value:",
    packageValue,
    "Owners",
    ownersValue
  );

  const selectedStateInfo =
    businessStructure === "LLC"
      ? llcstateFees[selectedState]
      : CorpstateFees[selectedState];

  const rush = "49";

  const handlePrevious = () => {
    navigate("/packages-page", {
      state: {
        fullName,
        phone,
        email,
        totalOrderValue,
        selectedPackage,
        packageValue,
        businessName,
        selectedState,
        businessActivity,
        businessAddress,
        selectedOwners,
        businessStructure,
        selectedStateInfo,
      },
    }); // Navigate to the previous page (Business Address Page)
  };

  const handleSkip = () => {
    navigate("/information-review", {
      state: {
        fullName,
        phone,
        email,
        totalOrderValue,
        selectedPackage,
        packageValue,
        businessName,
        selectedState,
        businessActivity,
        businessAddress,
        selectedOwners,
        businessStructure,
        selectedStateInfo,
      },
    }); // Pass totalOrderValue to the next page
  };

  const handleNext = () => {
    navigate("/information-review", {
      state: {
        fullName,
        phone,
        email,
        totalOrderValue,
        selectedPackage,
        packageValue,
        businessName,
        selectedState,
        businessActivity,
        businessAddress,
        selectedOwners,
        businessStructure,
        selectedStateInfo,
        rush,
      },
    }); // Pass totalOrderValue to the next page
  };

  // Calculate the total order amount by adding the government filing fee to the package value
  const totalOrderValue =
    (selectedStateInfo?.fee || 0) + (packageValue || 0) + (Number(rush) || 0);

  return (
    <div className="order-summary-container">
      <ProgressBar step={70} />

      <div className="business-heading-wrapper">
        <img src={icon} alt="Icon" className="heading-icon" />
        <h1 className="business-heading">Rush Processing</h1>
      </div>

      <div className="summary-activity-wrapper">
        <div class="rush-order-summary order-summary">
          <h2>Order Summary</h2>
          <div class="item">
            <span>{businessStructure} Service Fee</span>
            <span></span>
          </div>
          <div class="item">
            <span>Electronic Delivery</span>
            <span class="free">Free</span>
          </div>
          <div class="item">
            <span>{selectedPackage} Package</span>
            <span className="amulish-numbers">
              {packageValue === 0 ? "Free" : `$${packageValue}`}
            </span>
          </div>
          <br />
          <div class="item">
            <span>Government Filing Fee</span>
            <span></span>
          </div>
          <div class="item">
            <span>{selectedState}</span>
            <span></span>
          </div>
          <div class="item">
            <span>Government Fee</span>
            <span className="amulish-numbers">
              ${selectedStateInfo?.fee || 0}
            </span>
          </div>
          <div class="note">Fees collected by the State of {selectedState}</div>
          <div class="total">
            <div>
              Total Order:{" "}
              <span class="amount amulish-numbers">${totalOrderValue}</span>
            </div>
            <div class="savings amulish-numbers">(You Saved $0)</div>
          </div>
        </div>

        <div className="vertical-line"></div>

        <div className="ownership-section">
          <div style={{ fontSize: "14px", color: "#333" }}>
            {selectedPackage} {selectedState} Processing Time (6 - 10) Business
            Days
          </div>
          <h3 className="mt-2 mb-2">Upgrade to Rush Process</h3>
          {/* <p className="mb-2 mt-2">Register ABC in</p> */}
          {/* <h3 className="mb-3">2-4 Business Days</h3> */}

          {/* <div
            className='mb-3'
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '2px solid #00c853',
              borderRadius: '20px',
              padding: '10px 20px',
              maxWidth: '500px',
              backgroundColor: 'white',
            }}
          >
            <div style={{ fontSize: '14px', color: '#333' }}>
              <strong>Pro Tip:</strong> Skip the line and get registered fast. Fastest national registration time guaranteed.
            </div>
          </div> */}

          {/* <h5 className="mb-4">Upgrade to Rush Process</h5> */}
          <h5 className="mb-2">Only</h5>
          <h5 className="mb-2">$ 49</h5>
          <h5 className="mb-4">
            Regular price{" "}
            <span style={{ textDecoration: "line-through" }}>$99</span>
          </h5>
          <button
            className="business-address-select-button"
            onClick={handleNext}
          >
            Select →
          </button>
        </div>
      </div>

      <div className="anavigation-buttons">
        <button className="dprevious-step" onClick={handlePrevious}>
          ← Previous Step
        </button>
        <button className="skip-step" onClick={handleSkip}>
          Skip this Step →
        </button>
      </div>
    </div>
  );
};

export default RushPage;
