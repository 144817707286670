import React from 'react';
import './AnnualReportSection.css';

const AnnualReportSection = () => {
  return (
    <div className="annual-report-section">
      <h1 className="annual-heading">FILE AN ANNUAL REPORT IN YOUR STATE</h1>
      <div className="report-box"></div>
    </div>
  );
};

export default AnnualReportSection;
