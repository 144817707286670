import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './OwnershipPage.css';
import icon from '../../assets/images/icon7.png'; // Correct path to icon
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
const OwnershipPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;
  const selectedState = state?.selectedState || 'CA'; // Fallback to California if not passed
  const { businessName } = location.state || {};
  const { businessActivity } = location.state || {};
  const { businessAddress } = location.state || {};
  const { fullName } = location.state || {};
  const { phone } = location.state || {};
  const { email } = location.state || {};
  const { businessStructure } = location.state || {};


  const llcstateFees = {
    Alaska: { fee: 250, name: 'Alaska' },
    Alabama: { fee: 236, name: 'Alabama' },
    Arkansas: { fee: 45, name: 'Arkansas' },
    Arizona: { fee: 85, name: 'Arizona' },
    California: { fee: 75, name: 'California' },
    Colorado: { fee: 50, name: 'Colorado' },
    Connecticut: { fee: 120, name: 'Connecticut' },
    DistrictOfColumbia: { fee: 99, name: 'District of Columbia' },
    Delaware: { fee: 140, name: 'Delaware' },
    Florida: { fee: 155, name: 'Florida' },
    Georgia: { fee: 100, name: 'Georgia' },
    Hawaii: { fee: 51, name: 'Hawaii' },
    Iowa: { fee: 50, name: 'Iowa' },
    Idaho: { fee: 100, name: 'Idaho' },
    Illinois: { fee: 154, name: 'Illinois' },
    Indiana: { fee: 97, name: 'Indiana' },
    Kansas: { fee: 166, name: 'Kansas' },
    Kentucky: { fee: 40, name: 'Kentucky' },
    Louisiana: { fee: 105, name: 'Louisiana' },
    Massachusetts: { fee: 520, name: 'Massachusetts' },
    Maryland: { fee: 156, name: 'Maryland' },
    Maine: { fee: 175, name: 'Maine' },
    Michigan: { fee: 50, name: 'Michigan' },
    Minnesota: { fee: 155, name: 'Minnesota' },
    Missouri: { fee: 50, name: 'Missouri' },
    Mississippi: { fee: 53, name: 'Mississippi' },
    Montana: { fee: 35, name: 'Montana' },
    NorthCarolina: { fee: 128, name: 'North Carolina' },
    NorthDakota: { fee: 135, name: 'North Dakota' },
    Nebraska: { fee: 105, name: 'Nebraska' },
    NewHampshire: { fee: 102, name: 'New Hampshire' },
    NewJersey: { fee: 129, name: 'New Jersey' },
    NewMexico: { fee: 50, name: 'New Mexico' },
    Nevada: { fee: 436, name: 'Nevada' },
    NewYork: { fee: 210, name: 'New York' },
    Ohio: { fee: 99, name: 'Ohio' },
    Oklahoma: { fee: 104, name: 'Oklahoma' },
    Oregon: { fee: 100, name: 'Oregon' },
    Pennsylvania: { fee: 125, name: 'Pennsylvania' },
    RhodeIsland: { fee: 156, name: 'Rhode Island' },
    SouthCarolina: { fee: 131, name: 'South Carolina' },
    SouthDakota: { fee: 150, name: 'South Dakota' },
    Tennessee: { fee: 307, name: 'Tennessee' },
    Texas: { fee: 308, name: 'Texas' },
    Utah: { fee: 76, name: 'Utah' },
    Virginia: { fee: 100, name: 'Virginia' },
    Vermont: { fee: 125, name: 'Vermont' },
    Washington: { fee: 230, name: 'Washington' },
    Wisconsin: { fee: 130, name: 'Wisconsin' },
    WestVirginia: { fee: 130, name: 'West Virginia' },
    Wyoming: { fee: 104, name: 'Wyoming' }
};

const CorpstateFees = {
  Alaska: { fee: 250, name: 'Alaska' },
  Alabama: { fee: 236, name: 'Alabama' },
  Arkansas: { fee: 45, name: 'Arkansas' },
  Arizona: { fee: 95, name: 'Arizona' },
  California: { fee: 105, name: 'California' },
  Colorado: { fee: 50, name: 'Colorado' },
  Connecticut: { fee: 250, name: 'Connecticut' },
  DistrictOfColumbia: { fee: 270, name: 'District of Columbia' },
  Delaware: { fee: 139, name: 'Delaware' },
  Florida: { fee: 79, name: 'Florida' },
  Georgia: { fee: 140, name: 'Georgia' },
  Hawaii: { fee: 50, name: 'Hawaii' },
  Iowa: { fee: 50, name: 'Iowa' },
  Idaho: { fee: 100, name: 'Idaho' },
  Illinois: { fee: 281, name: 'Illinois' },
  Indiana: { fee: 97, name: 'Indiana' },
  Kansas: { fee: 90, name: 'Kansas' },
  Kentucky: { fee: 50, name: 'Kentucky' },
  Louisiana: { fee: 75, name: 'Louisiana' },
  Massachusetts: { fee: 265, name: 'Massachusetts' },
  Maryland: { fee: 217, name: 'Maryland' },
  Maine: { fee: 145, name: 'Maine' },
  Michigan: { fee: 60, name: 'Michigan' },
  Minnesota: { fee: 180, name: 'Minnesota' },
  Missouri: { fee: 58, name: 'Missouri' },
  Mississippi: { fee: 50, name: 'Mississippi' },
  Montana: { fee: 70, name: 'Montana' },
  NorthCarolina: { fee: 125, name: 'North Carolina' },
  NorthDakota: { fee: 100, name: 'North Dakota' },
  Nebraska: { fee: 65, name: 'Nebraska' },
  NewHampshire: { fee: 105, name: 'New Hampshire' },
  NewJersey: { fee: 129, name: 'New Jersey' },
  NewMexico: { fee: 100, name: 'New Mexico' },
  Nevada: { fee: 744, name: 'Nevada' },
  NewYork: { fee: 135, name: 'New York' },
  Ohio: { fee: 100, name: 'Ohio' },
  Oklahoma: { fee: 104, name: 'Oklahoma' },
  Oregon: { fee: 100, name: 'Oregon' },
  Pennsylvania: { fee: 125, name: 'Pennsylvania' },
  RhodeIsland: { fee: 238, name: 'Rhode Island' },
  SouthCarolina: { fee: 535, name: 'South Carolina' },
  SouthDakota: { fee: 150, name: 'South Dakota' },
  Tennessee: { fee: 100, name: 'Tennessee' },
  Texas: { fee: 308, name: 'Texas' },
  Utah: { fee: 76, name: 'Utah' },
  Virginia: { fee: 75, name: 'Virginia' },
  Vermont: { fee: 75, name: 'Vermont' },
  Washington: { fee: 230, name: 'Washington' },
  Wisconsin: { fee: 100, name: 'Wisconsin' },
  WestVirginia: { fee: 65, name: 'West Virginia' },
  Wyoming: { fee: 100, name: 'Wyoming' }
};

  
const selectedStateInfo = businessStructure === 'LLC' ? llcstateFees[selectedState] : CorpstateFees[selectedState];

  // console.log("test state", location)

  // State for dropdown visibility and selected owners
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOwners, setSelectedOwners] = useState('Number of Owners');

  const handlePrevious = () => {
    navigate('/business-address'); // Navigate to the previous page (Business Address Page)
  };

  const handleNext = () => {
    navigate('/packages-page', { state: {fullName, phone, email, businessName, selectedState, businessActivity, businessAddress, selectedOwners, businessStructure, selectedStateInfo } }) ; // Navigate to the next step for ownership details
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Handle selection from dropdown
  const selectOwners = (owners) => {
    setSelectedOwners(`Number of Owners: <span class="mulish-numbers">${owners}</span>`);
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  return (
    <div className="order-summary-container">
        {/* Progress Bar at 50% */}
       <ProgressBar step={50} />

      <div className="business-heading-wrapper">
        {/* Icon next to the heading */}
        <img src={icon} alt="Icon" className="heading-icon" />
        <h1 className="business-heading">Ownership.</h1>
      </div>

      <div className="summary-activity-wrapper">
        {/* Order Summary */}

        <div class="order-summary">
              <h2>Order Summary</h2>
              <div class="item">
                  <span>{businessStructure} Service Fee</span>
                  <span></span>
              </div>
              <div class="item">
                  <span>Electronic Delivery</span>
                  <span class="free">Free</span>
              </div>
              {/* <div class="item">
                  <span>Essentials Package</span>
                  <span className='amulish-numbers'></span>
              </div> */}
              <br/>
              <div class="item">
                  <span>Government Filing Fee</span>
                  <span></span>
              </div>
              <div class="item">
                  <span>{selectedState}</span>
                  <span></span>
              </div>
              <div class="item">
                  <span>Government Fee</span>
                  <span className='amulish-numbers'>${selectedStateInfo?.fee || 0}</span>
              </div>
              <div class="note">
                  Fees collected by the State of {selectedState}
              </div>
              <div class="total">
                  <div>Total Order: <span class="amount amulish-numbers">${selectedStateInfo?.fee || 0}</span></div>
                  <div class="savings amulish-numbers">(You Saved $0)</div>
              </div>
          </div>


        {/* Line Separator */}
        <div className="vertical-line"></div>

        {/* Ownership Section */}
        <div className="ownership-section cust-center">
          <h3 className="ownership-heading">How many owners will this company have?</h3>
          
          <button className="ownership-button" onClick={toggleDropdown} dangerouslySetInnerHTML={{__html: selectedOwners}}>
          </button>

          {isDropdownOpen && (
            <ul className="ownership-dropdown">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((owners) => (
                <li
                  key={owners}
                  className="ownership-dropdown-item"
                  onClick={() => selectOwners(owners)}
                >
                  {owners}
                </li>
              ))}
            </ul>
          )}

          <p className="ownership-description">
            Upon the completion of this form, you will enter the details regarding the ownership structure of this company. Our team is available if you have any questions.
          </p>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="anavigation-buttons">
          <button className="aprevious-step" onClick={handlePrevious}>← Previous Step</button>
          <button className="anext-button" onClick={handleNext}>Next</button>
        </div>
    </div>
  );
};

export default OwnershipPage;
