import React from 'react';
import A11 from '../../assets/images/Transparent Ownership Structure.png'; // Import icons
import A33 from '../../assets/images/Conflict Resolution.png';
import A22 from '../../assets/images/Legal Protection.png';
import './UPeacefulMindSection.css'; // Updated CSS file with unique names

const UniquePeacefulMindSection = () => {
  return (
    <div className="uniquepeacefulsection">
      <h2 className="uniquepeacefulheading">Benefits of an LLC Operating Agreement
      </h2>
      <br></br>
      <br></br>
      <div className="uniquepeacefulcontent">
        <div className="uniquepeacefulbox">
          <img src={A11} alt="Asset Protection Icon" className="uniquepeacefulicon" />
          <h3>Transparent Ownership Structure</h3>
          <p>
          An operating agreement emphasizes ownership percentages, avoiding disputes between members' roles, profit distribution, and responsibilities. What could be better, if we make these structures clear so that there is no fuss in the longer run? Get your hands on our operating agreement, so that you may understand in a better way.
          </p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A33} alt="Tax Benefits Icon" className="uniquepeacefulicon rightalignedicon" />
          <h3>Conflict Resolution</h3>
          <p>
          If the responsibilities of the member of the LLC are predefined, it can help your brand operations to run smoother. In the instance of any disputes, the references are fetched from the operating agreement ensuring smooth and seamless operations. 

          </p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A22} alt="Credibility Icon" className="uniquepeacefulicon" />
          <h3>Legal Protection</h3>
          <p>
          This management framework helps you to avoid any unwanted and uncertain legal issues. A partnership agreement for LLC acts as a shield ensuring personal liability and showcases that the business is legally separate from individual owners.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UniquePeacefulMindSection;
