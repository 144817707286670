import React from 'react';
import './ClientReviews.css';

const reviews = [
  {
    name: "Angela Grey",
    // image: "https://randomuser.me/api/portraits/women/44.jpg", // Example image URL
    review: "It was simple and quick. I was able to respond quickly. They collaborated with me to complete and file my paperwork on schedule, and I appreciate that! I would without a doubt suggest this firm to anyone I know who wishes to launch a venture.",
    rating: 4,
    date: "2 weeks ago",
  },
  {
    name: "Marcus Ben",
    // image: "https://randomuser.me/api/portraits/men/32.jpg",
    review: "Thank you Business Omni team! Your verry reliable and professional behavior led me with ease through every step. Best company helping local businesses in company formation. Thanks again for helping me create an LLC efficiently. ",
    rating: 4,
    date: "1 week ago",
  },
  {
    name: "John Andreson",
    // image: "https://randomuser.me/api/portraits/men/76.jpg",
    review: "From a time, money, and effort perspective, I felt more comfortable building brands with Business Omni. Because those are the brands that are keeping the lights on and assisting in the expansion of our firm.",
    rating: 3,
    date: "3 weeks ago",
  },
  {
    name: "Natasha Elizabeth",
    // image: "https://randomuser.me/api/portraits/women/65.jpg",
    review: "I got five-star service when I contacted you. My issue with getting my docs to print was resolved quickly and with fantastic assistance from Henry! Fantastic man. You need the Business Omni Team to advance your company!",
    rating: 5,
    date: "2 weeks ago",
  },
];

const ClientReviews = () => {
  return (
    <div>
      {/* Grey bar at the top */}
      <div className="grey-bar"></div>

      <div className="client-reviews-section">
        <h2 className="reviews-heading">Hear What Our Clients Say About Us
        </h2>
        <div className="reviews-container">
          {reviews.map((review, index) => (
            <div className="review-card" key={index}>
              {/* <img src={review.image} alt={review.name} className="review-image" /> */}
              <div className="review-content">
                <h3 className="review-name">{review.name}</h3>
                <div>
                  <span className="review-date">{review.date}</span>
                  <div className="review-rating">
                    {Array(5)
                      .fill()
                      .map((_, i) => (
                        <span key={i} style={{ color: i < review.rating ? '#FFD700' : '#C0C0C0' }}>
                          &#9733;
                        </span>
                      ))}
                  </div>
                </div>
                <p className="review-text">{review.review}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Grey bar at the bottom */}
      <div className="grey-bar"></div>
    </div>
  );
};

export default ClientReviews;
