import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import MainSection from "./components/Hompage/MainSection";
import AboutUs from "./components/AboutUs/aboutUsPage";
import WhatSetsUsApart from "./components/Hompage/WhatSetsUsApart";
import ComparePlans from "./components/Hompage/ComparePlans";
import RegisterLLC from "./components/Hompage/RegisterLLC";
import RegisterAllOverUS from "./components/Hompage/RegisterAllOverUS";
import HowItWorks from "./components/Hompage/HowItWorks";
import ClientReviews from "./components/Hompage/ClientReviews";
import FAQSection from "./components/Hompage/FAQSection";
import ServicesSection from "./components/Services/ServicesSection";
import InstructionStepsSection from "./components/WhyOmni/InstructionStepsSection"; // Keep InstructionStepsSection
import SupportPage from "./components/Support/SupportPage";
import BusinessNamePage from "./components/BusinessForm/BusinessNamePage";
import PrimaryContactPage from "./components/BusinessForm/PrimaryContactPage";
import Header from "./components/Hompage/Header";
import Footer from "./components/Hompage/Footer";
import StateSelectionPage from "./components/BusinessForm/StateSelectionPage";
import OrderSummaryPage from "./components/BusinessForm/OrderSummaryPage";
import BusinessAddressPage from "./components/BusinessForm/BusinessAddressPage";
import OwnershipPage from "./components/BusinessForm/OwnershipPage";
import PackagesPage from "./components/BusinessForm/PackagesPage";
import RushPage from "./components/BusinessForm/RushPage";
import InformationReview from "./components/BusinessForm/InformationReviewPage";
import Thankyou from "./components/BusinessForm/ThankyouPage";
import ProgressBar from "./components/BusinessForm/ProgressBar";
import LLC from "./components/footerPages/LLC"; // Import LLC component
import Cooperation from "./components/CooperationPages/Cooperation";
import RegisteredAgent from "./components/RegisteredAgentPages/RegisteredAgent";
import Beneficiary from "./components/BOI/Beneficiary";
import StayOrg from "./components/Stayfree/StayOrg";
import Opera from "./components/Operating/Opera";
import AnnualReport from "./components/footerPages/AnnualReportSection";
import FaqsPage from "./components/FAQS/faqsPage";
import LoginForm from "./components/Login/loginForm";
import EnquireForm from "./components/Forms/forms";
import SignUpForm from "./components/Forms/signUp";
import PaymentPage from "./components/BusinessForm/PaymentForm";

function App() {
  const location = useLocation();
  const hideHeaderFooterRoutes = [
    "/business-name",
    "/state-selection",
    "/primary-contact",
    "/order-summary",
    "/business-address",
    "/ownership-page",
    "/packages-page",
    "/rush-page",
    "/information-review",
    "/thank-you",
    "/payment",
  ];
  const hideHeaderFooter = hideHeaderFooterRoutes.includes(location.pathname);

  return (
    <div>
      {!hideHeaderFooter && <Header />}

      <Routes>
        <Route
          path="/"
          element={
            <>
              <MainSection />
              <WhatSetsUsApart />
              <ComparePlans />
              <RegisterLLC />
              <RegisterAllOverUS />
              <HowItWorks />
              <ClientReviews />
              <FAQSection />
            </>
          }
        />
        <Route path="services" element={<ServicesSection />} />
        <Route path="business-name" element={<BusinessNamePage />} />
        <Route path="state-selection" element={<StateSelectionPage />} />
        <Route path="order-summary" element={<OrderSummaryPage />} />
        <Route path="ownership-page" element={<OwnershipPage />} />
        <Route path="packages-page" element={<PackagesPage />} />
        <Route path="rush-page" element={<RushPage />} />
        <Route path="information-review" element={<InformationReview />} />
        <Route path="payment" element={<PaymentPage />} />
        <Route path="thank-you" element={<Thankyou />} />
        <Route path="progress-bar" element={<ProgressBar />} />
        <Route path="business-address" element={<BusinessAddressPage />} />
        <Route path="primary-contact" element={<PrimaryContactPage />} />
        <Route
          path="instruction-steps"
          element={<InstructionStepsSection />}
        />{" "}
        {/* Updated Route */}
        <Route path="support" element={<SupportPage />} />
        <Route path="llc" element={<LLC />} />
        <Route path="cooperation" element={<Cooperation />} />
        <Route path="registered-agent" element={<RegisteredAgent />} />
        <Route path="boi" element={<Beneficiary />} />
        <Route path="stay" element={<StayOrg />} />
        <Route path="opera" element={<Opera />} />
        <Route path="annual-reports" element={<AnnualReport />} />
        <Route path="faqs-page" element={<FaqsPage />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="login" element={<LoginForm />} />
        <Route path="sign-up" element={<SignUpForm />} />
        <Route path="enquire-form" element={<EnquireForm />} />
      </Routes>

      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

export default function AppWrapper() {
  return (
    /*  wrapping with context provider */
    <Router>
      <App />
    </Router>
  );
}
