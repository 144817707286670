import React, { useState } from 'react';
import './FAQComponent.css'; // Updated CSS file reference
import questionIcon from '../../assets/images/question.png'; // Import the question.png image

const frequentlyAskedQuestions = [
  {
    query: "WHAT IS AN OPERATION AGREEMENT?",
    response: "An operating agreement is a legal document that is mandatory to outline management procedures, structure of the business, and roles of the members of an LLC. It also helps govern how your business operates and protects members from personal liability. ",
  },
  {
    query: "WHAT SHOULD BE INCLUDED IN AN OPERATING AGREEMENT?",
    response: "A quality operating agreement includes details about the procedures for adding or removing members, ownership percentage, decision making process, profit distribution, and management roles, ensuring legal protection and clarity for all members involved. ",
  },
  {
    query: "CAN I CREATE MY OWN OPERATING AGREEMENT?",
    response: "Yes, you can create your own operating agreement, but it is recommended to seek legal advice from professionals like Business Omni. We ensure that your agreement meets all the necessary aspects of your business and cover all the legal requirements. ",
  },
  {
    query: "IS AN OPERATING AGREEMENT A PUBLIC DOCUMENT?",
    response: "No, an operating agreement is not a public document. It is a private document acting as an agreement between the members of the LLC, and is not filed with the state. It ensures the confidentiality of the business’s internal rules.  ",
  },
];

const FAQComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQuestions = frequentlyAskedQuestions.filter(faq =>
    faq.query.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faq-section-wrapper">
      <h2 className="faq-title">Frequently Asked Questions About Custom Operating Agreement
      </h2>
      <div className="faq-search-wrapper">
        <input
          type="text"
          className="faq-input-field"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search FAQs..."
        />
        <img src={questionIcon} alt="Search Icon" className="faq-image-icon" /> {/* Use the question.png image */}
      </div>

      <div className="faq-list-container">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((faq, index) => (
            <div className="faq-item-wrapper" key={index}>
              <h3 className="faq-query">{faq.query}</h3>
              <p className="faq-response">{faq.response}</p>
            </div>
          ))
        ) : (
          <p>No FAQs match your search.</p>
        )}
      </div>
    </div>
  );
};

export default FAQComponent;
