import React from 'react';
import './HowServiceWorks.css'; // Import the CSS file for styling

const HowServiceWorks = () => {
  return (
    <div className="howserviceworkssection">
      <h2 className="howserviceworksheading">Registering a Limited Liability Company in <strong>3</strong> Steps</h2>
      <p className="howserviceworksdescriptiony">
      Register your LLC like a pro with our oracle guidance. Establishing an LLC may seem straightforward, but It's not because the laws vary from state to state. However, we have you covered; we will simplify the process and make it smooth and efficient. </p>
      
      <div className="howserviceworkscontent">
        <div className="howserviceworksboxy">
          <h2>Find a Unique Name</h2>
          <p>Choose a unique name by yourself or choose an appealing name with our experts. After consultation, we suggest a unique name for your company. Right after that, we will start the documentation process. In this process, we will create all the necessary documents that will help you start your LLC with ease.</p>
        </div>
        
        <div className="howserviceworksboxy">
          <h2>Filing Your LLC Formation Documents</h2>
          <p>BusinessOmni will file articles of organization with the secretary of state successfully to register your LLC. We make sure that the documents are accurate and compliant to protect your business. BusinessOmni will make the process hassle-free and efficient.</p>
        </div>
        
        <div className="howserviceworksboxy">
          <h2>Obtain Necessary Documents/Licenses</h2>
          <p>After we register your limited liability company, we will receive all the necessary documents. After that, our representative will let you know if any licenses benefit you. Also, you can get our add-on service, in which we will help you with your BOI for LLC. The federal government administers the Beneficial Ownership Information necessary to file within <strong>90</strong> days of creating an LLC.</p>
        </div>
      </div>
    </div>
  );
};

export default HowServiceWorks;
