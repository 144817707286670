import React from 'react';
import A11 from '../../assets/images/Tax Savvy for Small Businesses.png'; // Import icons
import A33 from '../../assets/images/Big Growth and Expansion.png';
import A22 from '../../assets/images/Be Wise and Make the Right Choice.png';
import './UPeacefulMindSection.css'; // Updated CSS file with unique names

const UniquePeacefulMindSection = () => {
  return (
    <div className="uniquepeacefulsection">
      <h2 className="uniquepeacefulheading">Benefits of S Corporation and C Corporation</h2>
      <br></br>
      <br></br>
      <div className="uniquepeacefulcontent">
        <div className="uniquepeacefulbox">
          <img src={A11} alt="Asset Protection Icon" className="uniquepeacefulicon" />
          <h3>Tax Savvy for Small Businesses</h3>
          <p>
            S Corporations afford small businesses the advantage of all income, deductions, and credits passing through for shareholders to absorb directly. It prevents double taxation headaches and keeps things streamlined for entrepreneurs seeking to maximize tax advantages.
          </p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A33} alt="Tax Benefits Icon" className="uniquepeacefulicon rightalignedicon" />
          <h3>Big Growth and Expansion</h3>
          <p>
            It is the best option for high-growth companies since the traditional C Corporation does not limit the number of shareholders and, because of its ability to attract foreign investors, it is the favorite of large companies.
          </p>
        </div>
        <div className="uniquepeacefulbox">
          <img src={A22} alt="Credibility Icon" className="uniquepeacefulicon" />
          <h3>Be Wise and Make the Right Choice</h3>
          <p>
            Making informed decisions when choosing between an S Corporation and a C Corporation can significantly impact a business's future.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UniquePeacefulMindSection;
