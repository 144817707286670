import React from 'react';
import './Opera.css'; // Use a different CSS for Corporation
import serviceImage from '../../assets/images/Operating agreement.png'; 
import PeacefulMindSection from './PeacefulMindSection';  // Correct the import path
import YearlyReportSection from './YearlyReportSection';  // Correct the import path
import HowServiceWorks from './HowServiceWorks';  // Correct the import path
import RegisterLLC from './RegisterLLC';  // Correct the import path
import FAQSection from './FAQSection';  // Correct the import path
import AnnualReportSection from './AnnualReportSection';

import { useNavigate } from 'react-router-dom';

const Cooperation = () => {
  const navigate = useNavigate(); 

  const handleRegisterNowClick = () => {
    navigate('/business-name');
  };
  const handleReportNowClick = () => {
    navigate('/enquire-form');
  };

  return (
    <div className="services-pagerry">
      <section className="services-sectionrry">
        <div className="breadcrumbsrry">
          <span className="white-text">Business OMNI</span>
          <span>&nbsp;&gt;&nbsp;</span>
          <span className="black-text">Operating Agreement</span>
        </div>

        <div className="services-contentrry">
          <h1>Protect Your Business Structure with an LLC Operating Agreement</h1>
          <div className="services-content-wrapperrry">
            <div className="services-descriptionrry">
              <p className="description-textrry">
              An operating agreement for LLC outlines the ownership structure, rules, and roles. It ensures transparent decision-making processes, management duties, and ownership rights. The custom operating agreement protects the members from future disagreements and liability disputes. This agreement acts as a strong base for the company, on which you will build an empire soon. The base should be firm so that you do not have to worry about the management and structure of your business.  
              </p>
              <button onClick={handleRegisterNowClick} className="service-buttonrry primaryy">START YOUR NEW BUSINESS FIRST</button>
              <button onClick={handleReportNowClick} className="service-buttonrry secondaryy">CLICK HERE FOR OPERATING AGREEMENT</button>
              
            </div>
            <img src={serviceImage} alt="Service Illustration" className="service-imagerry" />
          </div>
        </div>
        <div>
        <PeacefulMindSection />
          <YearlyReportSection />
          <HowServiceWorks />
          <RegisterLLC />
          <FAQSection />
          {/* <AnnualReportSection/> */}
        </div>
      </section>
    </div>
  );
};

export default Cooperation;
