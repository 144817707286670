import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import './PrimaryContactPage.css'; // Add styles for this page
import icon from '../../assets/images/a99.png'; // Ensure the correct path to the image file
import PrimaryContactProgressBar from './PrimaryContactProgressBar'; // Import the renamed Progress Bar component
import ProgressBar from './ProgressBar'; // Import the ProgressBar component

const PrimaryContactPage = () => {
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { businessStructure } = location.state || {};

  const llcstateFees = {
    Alaska: { fee: 250, name: 'Alaska' },
    Alabama: { fee: 236, name: 'Alabama' },
    Arkansas: { fee: 45, name: 'Arkansas' },
    Arizona: { fee: 85, name: 'Arizona' },
    California: { fee: 75, name: 'California' },
    Colorado: { fee: 50, name: 'Colorado' },
    Connecticut: { fee: 120, name: 'Connecticut' },
    DistrictOfColumbia: { fee: 99, name: 'District of Columbia' },
    Delaware: { fee: 140, name: 'Delaware' },
    Florida: { fee: 155, name: 'Florida' },
    Georgia: { fee: 100, name: 'Georgia' },
    Hawaii: { fee: 51, name: 'Hawaii' },
    Iowa: { fee: 50, name: 'Iowa' },
    Idaho: { fee: 100, name: 'Idaho' },
    Illinois: { fee: 154, name: 'Illinois' },
    Indiana: { fee: 97, name: 'Indiana' },
    Kansas: { fee: 166, name: 'Kansas' },
    Kentucky: { fee: 40, name: 'Kentucky' },
    Louisiana: { fee: 105, name: 'Louisiana' },
    Massachusetts: { fee: 520, name: 'Massachusetts' },
    Maryland: { fee: 156, name: 'Maryland' },
    Maine: { fee: 175, name: 'Maine' },
    Michigan: { fee: 50, name: 'Michigan' },
    Minnesota: { fee: 155, name: 'Minnesota' },
    Missouri: { fee: 50, name: 'Missouri' },
    Mississippi: { fee: 53, name: 'Mississippi' },
    Montana: { fee: 35, name: 'Montana' },
    NorthCarolina: { fee: 128, name: 'North Carolina' },
    NorthDakota: { fee: 135, name: 'North Dakota' },
    Nebraska: { fee: 105, name: 'Nebraska' },
    NewHampshire: { fee: 102, name: 'New Hampshire' },
    NewJersey: { fee: 129, name: 'New Jersey' },
    NewMexico: { fee: 50, name: 'New Mexico' },
    Nevada: { fee: 436, name: 'Nevada' },
    NewYork: { fee: 210, name: 'New York' },
    Ohio: { fee: 99, name: 'Ohio' },
    Oklahoma: { fee: 104, name: 'Oklahoma' },
    Oregon: { fee: 100, name: 'Oregon' },
    Pennsylvania: { fee: 125, name: 'Pennsylvania' },
    RhodeIsland: { fee: 156, name: 'Rhode Island' },
    SouthCarolina: { fee: 131, name: 'South Carolina' },
    SouthDakota: { fee: 150, name: 'South Dakota' },
    Tennessee: { fee: 307, name: 'Tennessee' },
    Texas: { fee: 308, name: 'Texas' },
    Utah: { fee: 76, name: 'Utah' },
    Virginia: { fee: 100, name: 'Virginia' },
    Vermont: { fee: 125, name: 'Vermont' },
    Washington: { fee: 230, name: 'Washington' },
    Wisconsin: { fee: 130, name: 'Wisconsin' },
    WestVirginia: { fee: 130, name: 'West Virginia' },
    Wyoming: { fee: 104, name: 'Wyoming' }
};

const CorpstateFees = {
  Alaska: { fee: 250, name: 'Alaska' },
  Alabama: { fee: 236, name: 'Alabama' },
  Arkansas: { fee: 45, name: 'Arkansas' },
  Arizona: { fee: 95, name: 'Arizona' },
  California: { fee: 105, name: 'California' },
  Colorado: { fee: 50, name: 'Colorado' },
  Connecticut: { fee: 250, name: 'Connecticut' },
  DistrictOfColumbia: { fee: 270, name: 'District of Columbia' },
  Delaware: { fee: 139, name: 'Delaware' },
  Florida: { fee: 79, name: 'Florida' },
  Georgia: { fee: 140, name: 'Georgia' },
  Hawaii: { fee: 50, name: 'Hawaii' },
  Iowa: { fee: 50, name: 'Iowa' },
  Idaho: { fee: 100, name: 'Idaho' },
  Illinois: { fee: 281, name: 'Illinois' },
  Indiana: { fee: 97, name: 'Indiana' },
  Kansas: { fee: 90, name: 'Kansas' },
  Kentucky: { fee: 50, name: 'Kentucky' },
  Louisiana: { fee: 75, name: 'Louisiana' },
  Massachusetts: { fee: 265, name: 'Massachusetts' },
  Maryland: { fee: 217, name: 'Maryland' },
  Maine: { fee: 145, name: 'Maine' },
  Michigan: { fee: 60, name: 'Michigan' },
  Minnesota: { fee: 180, name: 'Minnesota' },
  Missouri: { fee: 58, name: 'Missouri' },
  Mississippi: { fee: 50, name: 'Mississippi' },
  Montana: { fee: 70, name: 'Montana' },
  NorthCarolina: { fee: 125, name: 'North Carolina' },
  NorthDakota: { fee: 100, name: 'North Dakota' },
  Nebraska: { fee: 65, name: 'Nebraska' },
  NewHampshire: { fee: 105, name: 'New Hampshire' },
  NewJersey: { fee: 129, name: 'New Jersey' },
  NewMexico: { fee: 100, name: 'New Mexico' },
  Nevada: { fee: 744, name: 'Nevada' },
  NewYork: { fee: 135, name: 'New York' },
  Ohio: { fee: 100, name: 'Ohio' },
  Oklahoma: { fee: 104, name: 'Oklahoma' },
  Oregon: { fee: 100, name: 'Oregon' },
  Pennsylvania: { fee: 125, name: 'Pennsylvania' },
  RhodeIsland: { fee: 238, name: 'Rhode Island' },
  SouthCarolina: { fee: 535, name: 'South Carolina' },
  SouthDakota: { fee: 150, name: 'South Dakota' },
  Tennessee: { fee: 100, name: 'Tennessee' },
  Texas: { fee: 308, name: 'Texas' },
  Utah: { fee: 76, name: 'Utah' },
  Virginia: { fee: 75, name: 'Virginia' },
  Vermont: { fee: 75, name: 'Vermont' },
  Washington: { fee: 230, name: 'Washington' },
  Wisconsin: { fee: 100, name: 'Wisconsin' },
  WestVirginia: { fee: 65, name: 'West Virginia' },
  Wyoming: { fee: 100, name: 'Wyoming' }
};


// Retrieve the passed state data from BusinessNamePage
const { businessName, selectedState } = location.state || {};

const selectedStateInfo = businessStructure === 'LLC' ? llcstateFees[selectedState] : CorpstateFees[selectedState];

  const handleNext = () => {
    if (!fullName || !phone || !email) {
      alert('Please fill out all fields.');
      return;
    }
    navigate('/order-summary', {
      state: { fullName, phone, email, businessName, selectedState, businessStructure, selectedStateInfo }
    });
  };

  const handlePrevious = () => {
    navigate('/business-name');
  };

  return (
    <div className="primary-contact-container">
      {/* Add Progress Bar - set progress to 20% */}
      <ProgressBar style={{justifyContent: 'center',}} step={20} /> {/* This will show the progress bar with 40% filled */}
      
      <div className="contact-form-content">
        <div className="contact-heading-wrapper">
          <img src={icon} alt="Icon" className="contact-icon" />
          <h1 className="contact-heading">Primary Contact.</h1>
        </div>

        <div className="input-field-wrapper">
          <input
            type="text"
            className="contact-input"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <input
            type="text"
            className="contact-input"
            placeholder="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <input
            type="email"
            className="contact-input"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        {/* <p className="disclaimer-text">
          By providing a telephone number and submitting this form, you are consenting to be contacted by SMS text message. Message & data rates may apply. You can reply STOP to opt-out of further messaging.
        </p> */}

        <div className="anavigation-buttons">
          <button className="aprevious-step" onClick={handlePrevious}>← Previous Step</button>
          <button className="anext-button" onClick={handleNext}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default PrimaryContactPage;
