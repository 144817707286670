import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import EmailJS
import "./forms.css";

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Define the EmailJS parameters
    const templateParams = {
      to_name: "Admin",
      from_name: formData.fullName,
      message: `
      firstName: ${formData.firstName},
      lastName: ${formData.lastName},
      email: ${formData.email},
      password: ${formData.password},
      `,
    };

    // Send the email
    emailjs
      .send(
        "Business Omni", // Service ID
        "template_7dtajvh", // Template ID
        templateParams, // Data being passed to the template
        "M5j0butrNTjwp4HeX" // Public API key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Form submitted successfully!");
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Failed to submit the form. Please try again.");
        }
      );
  };

  return (
    <div className="support-section-container">
      {/* Send Us An Email Section */}
      <div className="support-section">
        <h1 className="support-heading">SignUp</h1>
        {/* <p className="support-subheading">
          Our team strives to respond within one business day but response times may vary.
        </p> */}

        <form className="support-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="form-input"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="form-input"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-row">
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="form-input"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="form-input"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* <div className="form-row">
            <label className="form-label">Preferred Method of Contact</label>
            <select className="form-select">
              <option value="">Please Select...</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
          <div className="form-row purchase-option">
            <div
              className={`purchase-option-box ${selectedOption === 'purchased' ? 'selected' : ''}`}
              onClick={() => setSelectedOption('purchased')}
            >
              <input type="checkbox" checked={selectedOption === 'purchased'} readOnly />
              <label>I have made a purchase</label>
            </div>
            <div
              className={`purchase-option-box ${selectedOption === 'not-purchased' ? 'selected' : ''}`}
              onClick={() => setSelectedOption('not-purchased')}
            >
              <input type="checkbox" checked={selectedOption === 'not-purchased'} readOnly />
              <label>I have not made a purchase</label>
            </div>
          </div>
          </div>


          <div className="form-row">
            <input type="text" placeholder="Subject" className=" subject-input" />
          </div>

          <div className="form-row">
            <textarea placeholder="Description" className="form-textarea"></textarea>
          </div> */}
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
