import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './PackagesPage.css';
import icon from '../../assets/images/icon7.png'; // Correct path to icon
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
// import { useLocation, useNavigate } from 'react-router-dom';

const PackagesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;
  const selectedState = state?.selectedState || 'CA'; // Fallback to California if not passed
  const { businessName } = location.state || {};
  const { businessActivity } = location.state || {};
  const { businessAddress } = location.state || {};
  const { selectedOwners } = location?.state || {};
  const { fullName } = location.state || {};
  const { phone } = location.state || {};
  const { email } = location.state || {};
  const { businessStructure } = location.state || {};


  const llcstateFees = {
    Alaska: { fee: 250, name: 'Alaska' },
    Alabama: { fee: 236, name: 'Alabama' },
    Arkansas: { fee: 45, name: 'Arkansas' },
    Arizona: { fee: 85, name: 'Arizona' },
    California: { fee: 75, name: 'California' },
    Colorado: { fee: 50, name: 'Colorado' },
    Connecticut: { fee: 120, name: 'Connecticut' },
    DistrictOfColumbia: { fee: 99, name: 'District of Columbia' },
    Delaware: { fee: 140, name: 'Delaware' },
    Florida: { fee: 155, name: 'Florida' },
    Georgia: { fee: 100, name: 'Georgia' },
    Hawaii: { fee: 51, name: 'Hawaii' },
    Iowa: { fee: 50, name: 'Iowa' },
    Idaho: { fee: 100, name: 'Idaho' },
    Illinois: { fee: 154, name: 'Illinois' },
    Indiana: { fee: 97, name: 'Indiana' },
    Kansas: { fee: 166, name: 'Kansas' },
    Kentucky: { fee: 40, name: 'Kentucky' },
    Louisiana: { fee: 105, name: 'Louisiana' },
    Massachusetts: { fee: 520, name: 'Massachusetts' },
    Maryland: { fee: 156, name: 'Maryland' },
    Maine: { fee: 175, name: 'Maine' },
    Michigan: { fee: 50, name: 'Michigan' },
    Minnesota: { fee: 155, name: 'Minnesota' },
    Missouri: { fee: 50, name: 'Missouri' },
    Mississippi: { fee: 53, name: 'Mississippi' },
    Montana: { fee: 35, name: 'Montana' },
    NorthCarolina: { fee: 128, name: 'North Carolina' },
    NorthDakota: { fee: 135, name: 'North Dakota' },
    Nebraska: { fee: 105, name: 'Nebraska' },
    NewHampshire: { fee: 102, name: 'New Hampshire' },
    NewJersey: { fee: 129, name: 'New Jersey' },
    NewMexico: { fee: 50, name: 'New Mexico' },
    Nevada: { fee: 436, name: 'Nevada' },
    NewYork: { fee: 210, name: 'New York' },
    Ohio: { fee: 99, name: 'Ohio' },
    Oklahoma: { fee: 104, name: 'Oklahoma' },
    Oregon: { fee: 100, name: 'Oregon' },
    Pennsylvania: { fee: 125, name: 'Pennsylvania' },
    RhodeIsland: { fee: 156, name: 'Rhode Island' },
    SouthCarolina: { fee: 131, name: 'South Carolina' },
    SouthDakota: { fee: 150, name: 'South Dakota' },
    Tennessee: { fee: 307, name: 'Tennessee' },
    Texas: { fee: 308, name: 'Texas' },
    Utah: { fee: 76, name: 'Utah' },
    Virginia: { fee: 100, name: 'Virginia' },
    Vermont: { fee: 125, name: 'Vermont' },
    Washington: { fee: 230, name: 'Washington' },
    Wisconsin: { fee: 130, name: 'Wisconsin' },
    WestVirginia: { fee: 130, name: 'West Virginia' },
    Wyoming: { fee: 104, name: 'Wyoming' }
};

const CorpstateFees = {
  Alaska: { fee: 250, name: 'Alaska' },
  Alabama: { fee: 236, name: 'Alabama' },
  Arkansas: { fee: 45, name: 'Arkansas' },
  Arizona: { fee: 95, name: 'Arizona' },
  California: { fee: 105, name: 'California' },
  Colorado: { fee: 50, name: 'Colorado' },
  Connecticut: { fee: 250, name: 'Connecticut' },
  DistrictOfColumbia: { fee: 270, name: 'District of Columbia' },
  Delaware: { fee: 139, name: 'Delaware' },
  Florida: { fee: 79, name: 'Florida' },
  Georgia: { fee: 140, name: 'Georgia' },
  Hawaii: { fee: 50, name: 'Hawaii' },
  Iowa: { fee: 50, name: 'Iowa' },
  Idaho: { fee: 100, name: 'Idaho' },
  Illinois: { fee: 281, name: 'Illinois' },
  Indiana: { fee: 97, name: 'Indiana' },
  Kansas: { fee: 90, name: 'Kansas' },
  Kentucky: { fee: 50, name: 'Kentucky' },
  Louisiana: { fee: 75, name: 'Louisiana' },
  Massachusetts: { fee: 265, name: 'Massachusetts' },
  Maryland: { fee: 217, name: 'Maryland' },
  Maine: { fee: 145, name: 'Maine' },
  Michigan: { fee: 60, name: 'Michigan' },
  Minnesota: { fee: 180, name: 'Minnesota' },
  Missouri: { fee: 58, name: 'Missouri' },
  Mississippi: { fee: 50, name: 'Mississippi' },
  Montana: { fee: 70, name: 'Montana' },
  NorthCarolina: { fee: 125, name: 'North Carolina' },
  NorthDakota: { fee: 100, name: 'North Dakota' },
  Nebraska: { fee: 65, name: 'Nebraska' },
  NewHampshire: { fee: 105, name: 'New Hampshire' },
  NewJersey: { fee: 129, name: 'New Jersey' },
  NewMexico: { fee: 100, name: 'New Mexico' },
  Nevada: { fee: 744, name: 'Nevada' },
  NewYork: { fee: 135, name: 'New York' },
  Ohio: { fee: 100, name: 'Ohio' },
  Oklahoma: { fee: 104, name: 'Oklahoma' },
  Oregon: { fee: 100, name: 'Oregon' },
  Pennsylvania: { fee: 125, name: 'Pennsylvania' },
  RhodeIsland: { fee: 238, name: 'Rhode Island' },
  SouthCarolina: { fee: 535, name: 'South Carolina' },
  SouthDakota: { fee: 150, name: 'South Dakota' },
  Tennessee: { fee: 100, name: 'Tennessee' },
  Texas: { fee: 308, name: 'Texas' },
  Utah: { fee: 76, name: 'Utah' },
  Virginia: { fee: 75, name: 'Virginia' },
  Vermont: { fee: 75, name: 'Vermont' },
  Washington: { fee: 230, name: 'Washington' },
  Wisconsin: { fee: 100, name: 'Wisconsin' },
  WestVirginia: { fee: 65, name: 'West Virginia' },
  Wyoming: { fee: 100, name: 'Wyoming' }
};

  
const selectedStateInfo = businessStructure === 'LLC' ? llcstateFees[selectedState] : CorpstateFees[selectedState];




  const ownersValue = selectedOwners?.replace(/<\/?[^>]+(>|$)/g, "");
  console.log("Owners", ownersValue)


  const handlePrevious = () => {
    navigate('/ownership-page'); // Navigate to the previous page
  };

  const handleNext = (selectedPackage) => {
    let packageValue = 0;
  
    // Set packageValue based on selectedPackage
    if (selectedPackage === "BASIC") {
      packageValue = 0;
    } else if (selectedPackage === "ESSENTIAL") {
      packageValue = 139;
    } else if (selectedPackage === "PREMIUM") {
      packageValue = 229;
    }
  
    navigate('/rush-page', { state: {fullName, phone, email, selectedPackage, packageValue, businessName, selectedState, businessActivity, businessAddress, selectedOwners, selectedStateInfo, businessStructure } });
    console.log(":::::::: STATE ::::::::", state)

  };

  return (
    <div className="package-page-container">
      {/* Progress Bar at 60% */}
      <ProgressBar step={60} />

      <div className="business-heading-wrapper">
        {/* Icon next to the heading */}
        <img src={icon} alt="Icon" className="heading-icon" />
        <h1 className="business-heading">Packages Page</h1>
      </div>

      <div className="summary-activity-wrapper">
        <div className="pricing-container">
          <div className="package starter">
            <h2>BASIC</h2>
            {/* <p className="description">For professionals able to obtain and create an IRS Tax Registration number and Operating Agreement on their own, which are necessary to open a bank account and start transacting business.</p> */}
            <div className="price">
              <span className="cost">$0</span>
              <span className="fee"> + ${selectedStateInfo?.fee || 0} State Fee</span>
            </div>
            <p className="type">One-Time Fee</p>
            <ul className="features">
              <li>Register a limited liability company with the state.</li>
              <li>excludes company agreements and IRS tax registration.</li>
            </ul>
            <button className="choose-button" onClick={() => handleNext("BASIC")}>Choose Package</button>
          </div>

          <div className="package essentials">
            <div className="popular">MOST POPULAR</div>
            <h2>ESSENTIAL</h2>
            {/* <p className="description">Comprehensive package containing IRS Tax Registration number and internal documents required for a business bank account and vendor contracts.</p> */}
            <div className="price">
              <span className="original">$147</span>
              <span className="discounted">$139</span>
              <span className="fee"> + ${selectedStateInfo?.fee || 0} State Fee</span>
            </div>
            <p className="type">One-Time Fee</p>
            <ul className="features">
              <li>Contains the IRS Tax Registration number</li>
              <li>Other necessary internal records.</li>
            </ul>
            <button className="choose-button mt-4" onClick={() => handleNext("ESSENTIAL")}>Choose Package</button>
          </div>

          <div className="package supreme">
            <h2>PREMIUM</h2>
            {/* <p className="description">Ideal for customers that need fast registration and hassle-free yearly maintenance.</p> */}
            <div className="price">
              <span className="original">$249</span>
              <span className="discounted">$229</span>
              <span className="fee">+ ${selectedStateInfo?.fee || 0} State Fee</span>
            </div>
            <p className="type">One-Time Fee</p>
            {/* <h5 className="annual-fee">$ 99/yr thereafter</h5> */}
            <ul className="features">
              <li>Fastest Processing in the Nation</li>
              <li></li>
              <br></br>
              <br></br>
              {/* <li>We will file your annual reports - Hassle Free!</li> */}
            </ul>
            <button className="choose-button" onClick={() => handleNext("PREMIUM")}>Choose Package</button>
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="dnavigation-buttons">
        <button className="dprevious-step" onClick={handlePrevious}>← Previous Step</button>
      </div>
    </div>
  );
};

export default PackagesPage;